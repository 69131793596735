import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { flatten } from 'lodash'
import {
  getAllBrands,
  getDropdownData,
} from '../../store/searchInventory/actionCreator'
import { makePopUpTable } from '../../util/Table'
import AddPropsTab from './AddPropsTab'

const PropsTabPage = ({
  sampleData = {},
  markdownTabDataKeyLabels = [],
  updateSamplePageCallback = () => {},
  pageName = 'VIEW',
  type = '',
  selectedTab = '',
}) => {
  const {
    propsMetadata = {},
    originalPropsMetadata = {},
    brandsList = [],
  } = useSelector((state) => state.searchInventory)
  // const itemCopy = Object.assign({}, sampleData)
  const markdownTabDataKeyLabelsCopy = [...markdownTabDataKeyLabels]
  const [itemCopy, setItemCopy] = useState({})
  const [finalItemCopy, setFinalItemCopy] = useState(sampleData)
  const [finalPropsMetadata, setPropsMetadata] = useState({})
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getDropdownData(selectedTab))
    dispatch(getAllBrands())
    return function cleanup() {
      setFinalItemCopy({})
      setPropsMetadata({})
      // setItemCopy({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (Object.entries(itemCopy).length > 0) {
      setFinalItemCopy(itemCopy)
      setItemCopy({})
    }
  }, [itemCopy])

  useEffect(() => {
    // if (Object.entries(sampleData).length > 2) {
    setItemCopy(sampleData)
    // }
  }, [sampleData])

  useEffect(() => {
    if (Object.entries(propsMetadata).length) {
      sucessCallBack(propsMetadata)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsMetadata])

  const sucessCallBack = async (propsMetadataRes = {}) => {
    const {
      colorFamily: propColorFamily = [],
      materials: propMaterial = [],
      sources: propSource = [],
      categories: propCategory = [],
    } = propsMetadataRes || {}

    const { categories: itemCategories = [] } = originalPropsMetadata

    const itemCatSubCatArr = (itemCategories || []).map(
      (mObj) => mObj?.subCategories || mObj?.scenic_sub_categories
    )

    const itemCatSubCatList = flatten(itemCatSubCatArr || [])?.map((sObj) => {
      const {
        category_id: categoryId = '',
        category_name: categoryName = '',
        name: subCategoryName = '',
        sub_category_id: subCategoryId = '',
      } = sObj
      return sObj
        ? {
            label: subCategoryName,
            id: subCategoryId,
            value: subCategoryId,
            displayName: subCategoryId,
            categoryId: categoryId || '',
            categoryName: categoryName || '',
          }
        : true
    })
    const propsMetaDataNew = {
      propColorFamily,
      propMaterial,
      propSource,
      propCategory,
      propSubcategory: itemCatSubCatList,
    }
    await setPropsMetadata(propsMetaDataNew)
    // await setFinalItemCopy(itemCopy)
  }
  return (
    <div>
      {['ADDPROP', 'ADDPROPVIEW', 'ADDSCENIC', 'ADDSCENICVIEW'].indexOf(
        pageName
      ) !== -1 && Object.entries(finalItemCopy).length > 0 ? (
        <AddPropsTab 
          item={finalItemCopy}
          dataLabels={markdownTabDataKeyLabelsCopy}
          updateSamplePageCallback={updateSamplePageCallback}
          propsMetaData={finalPropsMetadata}
          brandsList={brandsList}
          pageName={pageName}
          type={type}
          selectedTab={selectedTab}
        />
      ) : (
        makePopUpTable(
          finalItemCopy,
          markdownTabDataKeyLabelsCopy,
          updateSamplePageCallback,
          [],
          finalPropsMetadata
        )
      )}
    </div>
  )
}
export default PropsTabPage
