import React from 'react'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import Paper from '@material-ui/core/Paper'
import { Button, Grid } from '@mui/material'
import { Print } from '@mui/icons-material'
import { findIndex, isEmpty } from 'lodash'
import Typography from '@material-ui/core/Typography'
import * as propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import CustomTabs from '../../components/Tabs/Tabs'
import AllShipments from './AllShipmentsPage'
import withHOCs from 'util/withHocs'
import {
  fetchShipments,
  getSamplesByVendor,
  updateShipmentDetails,
  removeShipmentWithIncompleteTracking,
  setPage,
  setRowsPerPage,
  showPopup,
  closePopup,
  setCheckBox,
  setCheckBoxHeaderChange,
  setTabvalue,
} from '../../store/shipmentSummary/actionCreator'
import { getBarcodes } from '../../store/shipmentBarcode/actionCreator'
import TabbedPopUp from '../../components/PopUp/TabbedPopUp'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import { dataTabLabelsShippedVendor } from '../../enums/tableTabs'
import { makeTabTables, getTitle } from '../../util/Table'
import {
  SHIPMENTSUMMARY_SELECTABLE_FILTERS,
  paramsFromFilters,
} from 'pages/AddFilters/constants'
import { selectPageFilters } from 'store/filters/selectors'
import AddFilters from 'pages/AddFilters/AddFilters'
import { setFilters } from 'store/filters/actionCreator'
import { SampleStatus } from 'enums/sampleStatus'

const styles = (theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  paper: {
    width: '99%',
    marginTop: theme.spacing(5),
    align: 'center',
    paddingLeft: '1%',
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
})

class ShipmentSummaryPage extends React.Component {
  constructor(props) {
    super(props)
    const { filters = {} } = this.props
    this.state = {
      filters: filters,
      selectableFilters: SHIPMENTSUMMARY_SELECTABLE_FILTERS,
      isFilterUpdated: false,
      retainedFilters: {},
    }
    this.defaultParams = {
      status: SampleStatus.SHIPPED,
    }
  }
  componentDidMount = () => {
    const {
      fetchShipments,
      setHeaderTitleArray,
      setFilters = () => {},
      tabValue = 'Shipped Samples',
      pageName = '',
      filters = {},
    } = this.props
    setHeaderTitleArray([{ title: 'Sample History', link: '/shipmentSummary' }])
    if (tabValue === 'Shipped Samples') {
      this.setState(
        {
          retainedFilters: filters,
        },
        () => {
          setFilters({}, pageName)
        }
      )
    } else {
      fetchShipments()
    }
  }

  componentWillUnmount() {
    const { setFilters, pageName = '', tabValue = '' } = this.props
    const { filters = {} } = this.state
    const filterCopy = Object.assign({}, filters)
    if (!isEmpty(filterCopy)) {
      setFilters(filterCopy, pageName)
    }
    setTabvalue(tabValue)
  }

  componentDidUpdate(prevProps = {}, prevState = {}) {
    const { page: propsPage, rowsPerPage: propsRowsPerPage } = this.props
    const { page: prevPropsPage, rowsPerPage: prevPropsRowsPerPage } = prevProps
    const {
      filters: stateFilters = {},
      isFilterUpdated: stateIsFilterUpdated,
      retainedFilters = {},
    } = this.state
    const { isFilterUpdated: prevStateIsFilterUpdated } = prevState
    const curStateFilters =
      Object.entries(retainedFilters)?.length > 0
        ? retainedFilters
        : stateFilters
    const filtersCopy = Object.assign({}, curStateFilters)

    const doesFiltersUpdated =
      stateIsFilterUpdated && stateIsFilterUpdated !== prevStateIsFilterUpdated

    const isFiltersApplied =
      !Object.entries(retainedFilters)?.length && doesFiltersUpdated

    const doesRowsPerPageUpdated =
      propsPage !== prevPropsPage || propsRowsPerPage !== prevPropsRowsPerPage

    if (doesRowsPerPageUpdated || doesFiltersUpdated) {
      this.refreshTableWithNewData(filtersCopy, doesFiltersUpdated)
    }

    if (doesFiltersUpdated) {
      this.setState({
        isFilterUpdated: false,
      })
    }

    if (isFiltersApplied && Object.entries(retainedFilters)?.length > 0) {
      this.setState({
        retainedFilters: {},
      })
    }

    if (
      !Object.entries(retainedFilters)?.length &&
      !this.props.sampleData.length &&
      this.props.page !== 1
    ) {
      this.props.setPage(1)
    }
  }

  popSamplesWithNoTracking(samples) {
    let returnSamples = []
    for (let sampleIndex in samples) {
      if (
        !this.props.shipmentsWithIncompleteTracking
          .map((shipment) => (shipment.shipmentId || '').toString())
          .includes((samples[sampleIndex].shipmentId || '').toString())
      ) {
        returnSamples.push(samples[sampleIndex])
      }
    }
    return returnSamples
  }

  updateShipment = (updatedShipment) => {
    const { updateShipmentDetails } = this.props
    if (Object.entries(updatedShipment)?.length > 0) {
      updateShipmentDetails(updatedShipment)
    }
  }

  refreshTableWithNewData = (filters = {}, doesFiltersUpdated = false) => {
    const { page, rowsPerPage, getSamplesByVendor = () => {} } = this.props
    const filtersCopy = Object.assign({}, filters)
    this.setState({
      filters: filtersCopy,
    })
    const filtersFromParams = paramsFromFilters(
      filtersCopy,
      page,
      rowsPerPage,
      this.userProfilename,
      this.defaultParams,
      'comments',
      'desc'
    )
    const {
      requestPayload: { include_filters = {} } = {},
      requestParams = {},
    } = filtersFromParams
    Object.keys(include_filters).forEach((key) => {
      requestParams.append(key, include_filters[key])
    })
    getSamplesByVendor(requestParams, doesFiltersUpdated)
  }

  onSelected = (selectedData, selected) => {
    const { shipmentId = '' } = selected
    return (
      findIndex(selectedData, function (obj) {
        return obj?.shipmentId === shipmentId
      }) !== -1
    )
  }

  getSelectedShipmentSamples = (selected = []) => {
    return selected
      ?.map((obj) => {
        const ids = obj?.pegasusIds
        return ids?.map((idObj, index) => {
          const pgId =
            idObj?.toString()?.length === 7 ? 'WH' + idObj : 'PG' + idObj
          return { pegasusId: pgId }
        })
      })
      ?.flat()
  }

  getBarcodesActionCB = (barcodes = []) => {
    const file = new Blob([barcodes], { type: 'application/pdf' })
    let fileURL = URL.createObjectURL(file)
    window.open(fileURL).print()
  }
  handleGenerateBarcode = () => {
    const { getBarcodesAction = () => {}, selectedData } = this.props
    const selShipSamples = this.getSelectedShipmentSamples(selectedData)
    getBarcodesAction(selShipSamples, this.getBarcodesActionCB)
  }
  handleTabChange = (newValue) => {
    const { setTabvalue = () => {}, fetchShipments = () => {} } = this.props
    const { filters = {} } = this.state
    this.setState(
      {
        retainedFilters: filters,
      },
      () => {
        setTabvalue(newValue)
        if (newValue === 'Need Shipping Details') {
          fetchShipments()
        }
      }
    )
  }

  setFiltersUpdated = (isFilterUpdated = false, filters = {}) => {
    const filtersCopy = Object.assign({}, filters)
    this.setState({
      isFilterUpdated: isFilterUpdated,
      filters: filtersCopy,
    })
  }

  renderTabsContent = () => {
    const {
      classes = {},
      columnData,
      editItems,
      sampleColumnData,
      sampleData,
      shipmentsWithIncompleteTracking,
      allVendorShipments,
      page,
      rowsPerPage,
      setPage,
      setRowsPerPage,
      totalRowsCount,
      getSamplesByVendorPending = false,
      fetchShipmentsPending = false,
      clickableColumn = [],
      showPopup,
      onCheckBoxChange = () => {},
      onCheckBoxHeaderChange = () => {},
      fetchAllShipmentsPending = false,
      selectedData = [],
      checkBoxEnabled,
      tabValue = 'Shipped Samples',
      pageName = '',
      filterOptionsVendor = {},
    } = this.props
    const { selectableFilters = [], filters: stateFilters = {} } = this.state
    const dataFieldKeys = columnData.map((column) => column.id)
    switch (tabValue) {
      case 'Need Shipping Details':
        return shipmentsWithIncompleteTracking.length > 0 ? (
          <Paper className={classes.paper}>
            <FlexibleTable
              cascadeFieldKeys={[]}
              columnData={columnData}
              customRowCount="Shipments"
              data={shipmentsWithIncompleteTracking}
              dataFieldKeys={dataFieldKeys}
              disableTablePagination
              uniqueIdentifierInData={'shipmentId'}
              editEnabled
              editItems={editItems}
              enableSearch
              showPopup={() => {}}
              totalRowsCount={totalRowsCount}
              updateData={this.updateShipment}
              isLoading={fetchShipmentsPending}
              checkBoxEnabled={checkBoxEnabled}
              onSelected={this.onSelected}
              selected={selectedData}
              onCheckBoxChange={onCheckBoxChange}
              onCheckBoxHeaderChange={onCheckBoxHeaderChange}
            />
          </Paper>
        ) : null
      case 'Shipped Samples':
        return (
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <AddFilters
                  selectableFilters={selectableFilters}
                  refreshTableWithNewData={this.refreshTableWithNewData}
                  setFiltersUpdated={this.setFiltersUpdated}
                  filterCompType={'include_filters'}
                  mergedFilters={stateFilters}
                  pageName={pageName}
                />
              </Grid>
              <Grid item xs={12}>
                <FlexibleTable
                  cascadeFieldKeys={[]}
                  columnData={sampleColumnData}
                  data={sampleData}
                  enableSearch
                  paginationAtApiLevel={true}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  totalRowsCount={totalRowsCount}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                  isLoading={getSamplesByVendorPending}
                  clickableColumn={clickableColumn}
                  showPopup={showPopup}
                  enableMultiSearch={true}
                />
              </Grid>
            </Grid>
          </Paper>
        )
      case 'All Shipments':
        return (
          <AllShipments
            columnData={columnData}
            allVendorShipments={allVendorShipments}
            fetchAllShipmentsPending={fetchAllShipmentsPending}
            filterOptions={filterOptionsVendor}
          />
        )
    }
  }

  getTabs = (tabsList = []) => {
    const { tabValue = 'Shipped Samples' } = this.props
    return (
      <CustomTabs
        tabLabels={tabsList}
        renderTabsContent={this.renderTabsContent}
        setCurrentTabValue={this.handleTabChange}
        defaultTab={tabValue}
        showTabsPanel={false}
      />
    )
  }

  render() {
    const {
      classes = {},
      closePopup,
      popupIsVisible = false,
      popupItem = {},
      tabsList = [],
      auth = {},
      tabValue = 'Shipped Samples',
    } = this.props
    return (
      <div className={classes.root}>
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item>
            <Typography variant="h4" gutterBottom>
              Sample History
            </Typography>
          </Grid>
          {tabValue === 'Need Shipping Details' ? (
            <Grid item>
              <Button
                className={classes.backButton}
                color="primary"
                onClick={this.handleGenerateBarcode}
                variant="outlined"
              >
                <Print className={classes.iconMargin} /> Print New Labels
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <Grid>{this.getTabs(tabsList)}</Grid>
        {/*
         */}
        <TabbedPopUp
          dataTabLabels={dataTabLabelsShippedVendor}
          isVisible={popupIsVisible}
          data={makeTabTables(popupItem, classes, dataTabLabelsShippedVendor)}
          onClose={closePopup}
          title={getTitle(popupItem)}
          fullWidth={true}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    columnData: state?.shipmentSummary?.columnData,
    data: state?.shipmentSummary?.data,
    editItems: state?.shipmentSummary?.editItems,
    orderBy: state?.shipmentSummary?.orderBy,
    sampleColumnData: state?.shipmentSummary?.sampleColumnData,
    sampleData: state?.shipmentSummary?.sampleData,
    sampleTableTitle: state?.shipmentSummary?.sampleTableTitle,
    tableTitle: state?.shipmentSummary?.tableTitle,
    vendorId: state?.shipmentSummary?.vendorId,
    allVendorShipments: state?.shipmentSummary?.allVendorShipments,
    filterOptionsVendor: state?.shipmentSummary?.filterOptionsVendor,
    fetchAllShipmentsPending: state?.shipmentSummary?.fetchAllShipmentsPending,
    shipmentsWithIncompleteTracking:
      state?.shipmentSummary?.shipmentsWithIncompleteTracking,
    page: state?.shipmentSummary?.page,
    rowsPerPage: state?.shipmentSummary?.rowsPerPage,
    totalRowsCount: state?.shipmentSummary?.totalRowsCount,
    getSamplesByVendorPending:
      state?.shipmentSummary?.getSamplesByVendorPending,
    fetchShipmentsPending: state?.shipmentSummary?.fetchShipmentsPending,
    updateShipmentDetailsRequestPending:
      state?.shipmentSummary?.updateShipmentDetailsRequestPending,
    clickableColumn: state?.shipmentSummary?.clickableColumn,
    showPopup: state?.shipmentSummary?.showPopup,
    popupIsVisible: state?.shipmentSummary?.popupIsVisible,
    popupItem: state?.shipmentSummary?.item,
    checkBoxEnabled: state?.shipmentSummary?.checkBoxEnabled,
    selectedData: state?.shipmentSummary?.selectedData,
    tabValue: state?.shipmentSummary?.tabValue,
    tabsList: state?.shipmentSummary?.tabsList,
    filters: selectPageFilters(state.shipmentSummary.pageName)(state),
    pageName: state.shipmentSummary.pageName,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchShipments: () => dispatch(fetchShipments()),
    getSamplesByVendor: (page, rowsPerPage) =>
      dispatch(getSamplesByVendor(page, rowsPerPage)),
    updateShipmentDetails: (shipmentDetail) =>
      dispatch(updateShipmentDetails(shipmentDetail)),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    removeShipmentWithIncompleteTracking: (shipmentId) =>
      dispatch(removeShipmentWithIncompleteTracking(shipmentId)),
    showPopup: (item) => {
      dispatch(showPopup(item))
    },
    closePopup: () => {
      dispatch(closePopup())
    },
    onCheckBoxChange: (selected) => dispatch(setCheckBox(selected)),
    onCheckBoxHeaderChange: (selectedList = []) =>
      dispatch(setCheckBoxHeaderChange(selectedList)),
    getBarcodesAction: (samples, showBarcodesCallback) => {
      dispatch(getBarcodes(samples, showBarcodesCallback))
    },
    setTabvalue: (tabValue) => dispatch(setTabvalue(tabValue)),
    setFilters: (filters, pageName) => dispatch(setFilters(filters, pageName)),
  }
}

ShipmentSummaryPage.propTypes = {
  columnData: propTypes.array,
  data: propTypes.array,
  editItems: propTypes.array,
  orderBy: propTypes.string,
  sampleColumnData: propTypes.array,
  sampleData: propTypes.array,
  sampleTableTitle: propTypes.string,
  tableTitle: propTypes.string,
  vendorId: propTypes.number,
  clickableColumn: propTypes.array,
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(ShipmentSummaryPage)
