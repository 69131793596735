import * as types from './actionType'
import { pull, findIndex } from 'lodash'
import { getDisplayImage } from '../../util/CommonUtils'

const finalColumnData = (() => {
  const urlParams = new URLSearchParams(window.location.search)
  const vendorCommentsFlag = urlParams.get('vendorcomments')
  const columnData = [
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: false,
      label: 'Pegasus ID',
    },
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      align: 'center',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'subClassName',
      numeric: false,
      disablePadding: false,
      label: 'Sub Class',
      textAlign: 'right',
    },
    {
      id: 'subClassId',
      numeric: false,
      disablePadding: false,
      label: 'Sub Class ID',
      textAlign: 'right',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Item Description',
    },
    {
      id: 'shipTo',
      numeric: false,
      disablePadding: false,
      label: 'Ship To',
    },
    { id: 'carrier', numeric: false, disablePadding: false, label: 'Carrier' },
    {
      id: 'trackingNum',
      numeric: false,
      disablePadding: false,
      label: 'Tracking Number',
    },
    {
      id: 'shippedDate',
      numeric: true,
      disablePadding: false,
      label: 'Shipped Date',
      type: 'date',
    },
    {
      id: 'receivedDate',
      numeric: true,
      disablePadding: false,
      label: 'Received Date',
      type: 'date',
    },
    {
      id: 'manufacturerStyle',
      numeric: false,
      disablePadding: false,
      label: 'MFG Style',
    },
  ]
  return vendorCommentsFlag === 'true'
    ? [
        ...columnData,
        {
          id: 'latestComment',
          numeric: false,
          disablePadding: false,
          label: 'Latest Comment',
        },
      ]
    : columnData
})()

export const initialState = {
  clickableColumn: ['description', 'shipTo'],
  pageName: 'shipmentSummary',
  checkBoxEnabled: true,
  numSelected: 0,
  selectedData: [],
  tabValue: 'Shipped Samples',
  fetchAllShipmentsPending: false,
  allVendorShipments: [],
  filterOptionsVendor: {},
  tabsList: [
    {
      label: 'Shipped Samples',
      value: 'Shipped Samples',
    },
    {
      label: 'Need Shipping Details',
      value: 'Need Shipping Details',
    },
    {
      label: 'All Shipments',
      value: 'All Shipments',
    },
  ],
  columnData: [
    {
      id: 'shipmentId',
      numeric: false,
      disablePadding: false,
      label: 'Shipment #',
    },
    {
      id: 'shipDate',
      numeric: false,
      disablePadding: false,
      label: 'Creation Date',
      type: 'date',
    },
    {
      id: 'shipToLoc',
      numeric: false,
      disablePadding: false,
      label: 'Ship To Address',
    },
    { id: 'carrier', numeric: false, disablePadding: false, label: 'Carrier' },
    {
      id: 'trackingNum',
      numeric: false,
      disablePadding: false,
      label: 'Tracking Number',
    },
  ],
  data: [],
  editItems: ['carrier', 'trackingNum'],
  sampleColumnData: finalColumnData,
  sampleData: [],
  popupIsVisible: false,
  enableActions: true,
  onClick: () => {},
  sampleTableTitle: 'Shipped Samples',
  shipmentData: new Map(),
  shipmentTrackingEditInfo: new Map(),
  tableTitle: 'Need Shipping Details',
  fetchShipmentsFailure: false,
  fetchShipmentsPending: false,
  getSamplesByVendorFailure: false,
  getSamplesByVendorPending: false,
  getShipmentItemsFailure: false,
  getShipmentItemsPending: false,
  updateShipmentDetailsRequestFailure: false,
  updateShipmentDetailsRequestPending: false,
  visible: false,
  vendorId: 1975329,
  shipmentsWithIncompleteTracking: [],
  page: 1,
  rowsPerPage: 10,
  totalRowsCount: 0,
  item: {},
}

export default function shipmentSummary(state = initialState, action = {}) {
  switch (action.type) {
    case types.SHIPMENT_SUMMARY_FETCH_SHIPMENTS_SUCCESS: {
      return Object.assign({}, state, {
        fetchShipmentsFailure: false,
        data: action.payload.data,
      })
    }
    case types.SHIPMENT_SUMMARY_FETCH_SHIPMENTS_FAILURE: {
      return Object.assign({}, state, {
        fetchShipmentsFailure: true,
      })
    }
    case types.SHIPMENT_SUMMARY_GET_SHIPMENTS_PENDING: {
      return Object.assign({}, state, {
        fetchAllShipmentsPending: action.payload.pending,
      })
    }
    case types.SHIPMENT_SUMMARY_FETCH_SHIPMENTS_PENDING: {
      return Object.assign({}, state, {
        fetchShipmentsPending: action.payload.pending,
      })
    }
    case types.SHIPMENT_SUMMARY_GET_SAMPLES_BY_VENDOR_SUCCESS: {
      const payloadData = action.payload.sampleData?.map((item) => ({
        ...item,
        imagePopupUrl: item?.imageUrl,
        imageUrl:
          item?.imageUrl !== undefined &&
          item?.imageUrl !== null &&
          item?.imageUrl !== ''
            ? getDisplayImage(item.imageUrl)
            : 'Image Not Found',
      }))
      return Object.assign({}, state, {
        getSamplesByVendorFailure: false,
        sampleData: payloadData,
      })
    }
    case types.SHIPMENT_SUMMARY_GET_SAMPLES_BY_VENDOR_FAILURE: {
      return Object.assign({}, state, {
        getSamplesByVendorFailure: true,
      })
    }
    case types.SHIPMENT_SUMMARY_GET_SAMPLES_BY_VENDOR_PENDING: {
      return Object.assign({}, state, {
        getSamplesByVendorPending: action.payload.pending,
      })
    }
    case types.SHIPMENT_SUMMARY_UPDATE_SHIPMENT_DETAILS_REQUEST_SUCCESS: {
      const updatedShipment = action.payload.data
      return Object.assign({}, state, {
        ...state,
        data: state.data.map((shipment) =>
          shipment.shipmentId === updatedShipment.shipmentId
            ? updatedShipment
            : shipment
        ),
        shipmentsWithIncompleteTracking: state.data.map((shipment) =>
          shipment.shipmentId === updatedShipment.shipmentId
            ? updatedShipment
            : shipment
        ),
        updateShipmentDetailsRequestFailure: false,
      })
    }
    case types.SHIPMENT_SUMMARY_UPDATE_SHIPMENT_DETAILS_REQUEST_FAILURE: {
      return Object.assign({}, state, {
        updateShipmentDetailsRequestFailure: true,
      })
    }
    case types.SHIPMENT_SUMMARY_UPDATE_SHIPMENT_DETAILS_REQUEST_PENDING: {
      return Object.assign({}, state, {
        updateShipmentDetailsRequestPending: action.payload.pending,
      })
    }
    case types.SHIPMENT_SUMMARY_UPDATE_SHIPMENT_TRACKING: {
      let shipmentId = action.payload.shipmentId.toString()
      let trackingInfo = state.shipmentTrackingEditInfo.get(shipmentId)

      let newShipmentTrackingEditInfo = state.shipmentTrackingEditInfo
      newShipmentTrackingEditInfo.set(shipmentId, {
        trackingNum: action.payload.trackingNum,
        carrier: trackingInfo.carrier,
      })

      return Object.assign({}, state, {
        shipmentTrackingEditInfo: newShipmentTrackingEditInfo,
      })
    }
    case types.SHIPMENT_SUMMARY_UPDATE_SHIPMENT_CARRIER: {
      let shipmentId = action.payload.shipmentId.toString()
      let trackingInfo = state.shipmentTrackingEditInfo.get(shipmentId)

      let newShipmentTrackingEditInfo = state.shipmentTrackingEditInfo
      newShipmentTrackingEditInfo.set(shipmentId, {
        trackingNum: trackingInfo.trackingNum,
        carrier: action.payload.carrier,
      })

      return Object.assign({}, state, {
        shipmentTrackingEditInfo: newShipmentTrackingEditInfo,
      })
    }
    case types.SHIPMENT_SUMMARY_RESET_SHIPMENT_TRACKING_EDITS: {
      let newShipmentTrackingEditInfo = state?.shipmentTrackingEditInfo
      newShipmentTrackingEditInfo?.set(
        action?.payload?.shipment?.shipmentId?.toString(),
        {
          trackingNum: action?.payload?.shipment?.trackingNum,
          carrier: action?.payload?.shipment?.carrier,
        }
      )

      return Object.assign({}, state, {
        shipmentTrackingEditInfo: newShipmentTrackingEditInfo,
      })
    }
    case types.SHIPMENT_SUMMARY_SET_SHIPMENTS_WITH_INCOMPLETE_TRACKING: {
      return Object.assign({}, state, {
        shipmentsWithIncompleteTracking: action?.payload?.data,
      })
    }
    case types.SHIPMENT_SUMMARY_SET_ALL_SHIPMENTS: {
      return Object.assign({}, state, {
        allVendorShipments: action?.payload?.data,
      })
    }
    case types.SHIPMENT_SUMMARY_REMOVE_SHIPMENT_WITH_INCOMPLETE_TRACKING: {
      const currentShipmentsWithIncompleteTracking = Object.assign(
        [],
        state.shipmentsWithIncompleteTracking
      )
      return Object.assign({}, state, {
        shipmentsWithIncompleteTracking: pull(
          currentShipmentsWithIncompleteTracking,
          action?.payload?.data
        ),
      })
    }
    case types.SHIPMENT_SUMMARY_SET_PAGE: {
      return Object.assign({}, state, {
        page: action.payload.page,
      })
    }
    case types.SHIPMENT_SUMMARY_SET_ROWS_PER_PAGE: {
      return Object.assign({}, state, {
        rowsPerPage: action.payload.rowsPerPage,
      })
    }
    case types.SET_VENDOR_FILTER_OPTIONS: {
      return Object.assign({}, state, {
        filterOptionsVendor: action.payload.filterOptions,
      })
    }

    case types.SHIPMENT_SUMMARY_SET_TOTAL_ROWS_COUNT: {
      return Object.assign({}, state, {
        totalRowsCount: action.payload.totalRowsCount,
      })
    }
    case types.SHOW_SHIPMENT_SUMMARY_POPUP:
      return Object.assign({}, state, {
        ...state,
        popupIsVisible: true,
        item: action.payload.item,
      })
    case types.CLOSE_SHIPMENT_SUMMARY_POPUP:
      return Object.assign({}, state, {
        ...state,
        popupIsVisible: false,
      })
    case types.SET_TAB_VALUE:
      return Object.assign({}, state, {
        ...state,
        tabValue: action.payload.tabValue,
      })
    case types.SHIPMENT_SUMMARY_SET_CHECKBOX: {
      const { selectedData = {} } = state
      const {
        payload: { selected = {} },
      } = action
      const { shipmentId: selectedShipId = '' } = selected
      const selectedIndex = findIndex(selectedData, function (obj = {}) {
        return obj?.shipmentId === selectedShipId
      })

      let newSelected = []
      if (selectedIndex === -1) {
        newSelected = newSelected?.concat(
          state?.selectedData,
          action?.payload?.selected
        )
      } else if (selectedIndex === 0) {
        newSelected = newSelected?.concat(state?.selectedData?.slice(1))
      } else if (selectedIndex === state?.selectedData?.length - 1) {
        newSelected = newSelected?.concat(state?.selectedData?.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected?.concat(
          state?.selectedData?.slice(0, selectedIndex),
          state?.selectedData?.slice(selectedIndex + 1)
        )
      }
      return Object.assign({}, state, {
        ...state,
        selectedData: newSelected,
        numSelected: newSelected?.length,
      })
    }
    case types.SHIPMENT_SUMMARY_SET_CHECKBOX_HEADER: {
      const { selectedData = [] } = state
      const {
        payload: { selected: selectedIds = {} },
      } = action
      const selectedDiffCount = selectedData?.filter(
        (i) =>
          findIndex(selectedIds, function (obj) {
            return obj?.shipmentId === i?.shipmentId
          }) !== -1
      ).length

      var newDataObj = [].concat.apply(selectedData, selectedIds)

      let newSelected = []
      if (selectedDiffCount === Number(0)) {
        newSelected = newDataObj
      } else {
        newSelected = selectedData?.filter(
          (i) =>
            findIndex(selectedIds, function (obj) {
              return obj?.shipmentId === i?.shipmentId
            }) === -1
        )
      }

      return Object.assign({}, state, {
        ...state,
        selectedData: newSelected,
        numSelected: newSelected?.length,
      })
    }
    default:
      return state
  }
}
