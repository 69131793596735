import * as types from './actionType'
import * as commentsApi from '../../services/commentsService'
import { showNotification } from '../notification/actionCreator'
import { commentsResponseToSampleData } from 'mappers/inventoryMapper'

export const getAllComments =
  ({ pegasusId = '', page = 1, rowsPerPage = 10 }) =>
  async (dispatch, getState) => {
    const promise = commentsApi.getAllComments(pegasusId, page, rowsPerPage)
    return promise
      .then((response = {}) => {
        const { data = {} } = response
        const { commentResponses =[], totalPages=0, totalResults=1} = data
        const formattedData = commentsResponseToSampleData(commentResponses)
        dispatch(saveViewComments(formattedData))
      })
      .catch((error) => {
        dispatch(saveViewCommentsFail())
      })
  }
export const updateComment =
  ({ commentId = '', updateRequest = {}, callback = () => {} }) =>
  async (dispatch, getState) => {
    const promise = commentsApi.updateComment(commentId, updateRequest)
    return promise
      .then((response = {}) => {
        callback()
      })
      .catch((error) => {
        dispatch(
          showNotification(true, 'Failed to update comment. Please try again.')
        )
      })
  }
export const deleteComment =
  ({ commentId = '', callback = () => {} }) =>
  async (dispatch, getState) => {
    const promise = commentsApi.deleteComment(commentId)
    return promise
      .then((response = {}) => {
        callback()
      })
      .catch((error) => {
        dispatch(
          showNotification(true, 'Failed to update comment. Please try again.')
        )
      })
  }
export const addComment =
  (params = {}, callback = () => {}) =>
  async (dispatch, getState) => {
    return commentsApi
      .addComment(params)
      .then((response = {}) => {
        callback()
      })
      .catch((error) => {
        dispatch(
          showNotification(true, 'Failed to add comment. Please try again.')
        )
      })
  }
export function saveViewComments(data = []) {
  return {
    type: types.SAVE_VIEW_COMMENTS,
    payload: [...data],
  }
}
export function saveViewCommentsFail(data = []) {
  return {
    type: types.SAVE_VIEW_COMMENTS_FAILED,
  }
}
