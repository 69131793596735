import { DATE_FORMAT, convertTableDateFormat } from '../util/CommonUtils'

/**
 * Maps Shipment API response containing multiple shipments to internal model
 * @param shipments a JSON struct comprised of a list of Shipment JSON models
 * @returns a mapped list of shipments
 */
export const shipmentsResponseToShipments = (shipments) => {
  return shipments.map((shipment) => shipmentResponseToShipment(shipment))
}

export const shipmentsFilteredResponseToShipments = (shipmentsResponse) => {
  const shipments = shipmentsResponse.data.shipments
  let shipmentsWithoutTracking = []
  let shipmentsWithTracking = []
  let allshipments = []

  for (let shipmentIndex in shipments) {
    const curObj = shipments[shipmentIndex] || {}
    if (curObj?.carrier === null || curObj?.tracking_num === null) {
      shipmentsWithoutTracking.push(shipmentResponseToShipment(curObj))
    } else {
      shipmentsWithTracking?.push(shipmentResponseToShipment(curObj))
    }
    allshipments?.push(shipmentResponseToShipment(curObj))
  }
  return {
    shipmentsWithoutTracking: shipmentsWithoutTracking,
    shipmentsWithTracking: shipmentsWithTracking,
    allshipments: allshipments,
  }
}

export const getFilterOptionsVendorShipments = (shipments = []) => {
  let filterValues = {
    shipmentId: [],
    trackingNum: [],
    pegasusId:[]
  }
  shipments.map((shipment) => {
    filterValues['shipmentId'].push({
      value: shipment.shipmentId,
      label: shipment.shipmentId.toString(),
    })
    shipment.trackingNum &&
      filterValues['trackingNum'].push({
        value: shipment?.trackingNum,
        label: shipment?.trackingNum,
      })
    filterValues['pegasusId'] = []
  })
  return filterValues
}

/**
 * Maps Shipment API JSON response to internal model
 * @param shipmentResponse Json representation of a shipment
 * @returns mapped internal entity
 */
export const shipmentResponseToShipment = (shipmentResponse = {}) => {
  return {
    shipmentId: shipmentResponse?.shipment_id,
    pegasusIds: shipmentResponse?.pegasus_ids || [],
    sampleIds: sampleIdsWithPrefix(shipmentResponse?.pegasus_ids || []) || [],
    shipToLoc: shipmentResponse?.ship_to_loc,
    trackingNum: shipmentResponse?.tracking_num,
    carrier: shipmentResponse?.carrier,
    shipmentStatus: shipmentResponse?.shipment_status,
    vendorId: shipmentResponse?.vendor_id,
    shipDate: convertTableDateFormat(shipmentResponse?.create_ts, DATE_FORMAT),
    updateDate: convertTableDateFormat(
      shipmentResponse?.update_ts,
      DATE_FORMAT
    ),
  }
}

export const sampleIdsWithPrefix = (pegasusIds = []) => {
  return pegasusIds?.map((pgId, index) => {
    const pgIdWithPrefix = pgId?.toString()?.length === 7 ? 'WH' + pgId : 'PG' + pgId
    return pgIdWithPrefix
  })
}

/**
 *
 * @param data List of shipment contents to pull relevant information from
 * @returns {{carrier: (*|string), tracking_num: (*|string), vendor_id: (*|number|module:prop-types.number), pegasus_ids: *, ship_to_loc: *}[]}
 */
export const shipmentToShipmentRequest = (data) => {
  let pegasusIds = data.map((i) => {
    return {
      pegasus_id: (i.pegasusId || 'PG' || 'WH').substring(
        2,
        i.pegasusId.length
      ),
      vendor_id: i.vendorId,
      vendor_name: i.vendorName,
    }
  })
  return {
    pegasus_ids: pegasusIds,
    ship_to_loc: data[0]?.shipToLoc || null,
    carrier: data[0]?.carrier || null,
    tracking_num: data[0]?.trackingNumber || null,
    shipment_status: data[0]?.shipmentStatus,
    brand_id: data[0]?.brandId || null,
    vendor_created: true,
  }
}

/**
 * Maps internal order samples to shipment details
 * @param samples a list of samples to be converted to shipment items
 * @returns list of shipment contents
 */
export const orderDataToShipmentData = (samples) => {
  return samples.map((sample) => ({
    pegasusId: sample.pegasusId,
    tcin: sample.tcin,
    description: sample.description,
    vendorId: sample.vendorId,
    vendorName: sample.vendorName,
    vendorNotes: sample.vendorNotes,
    shipToDestination: sample.shipToDestination,
    shipToLoc: sample.shipTo,
    // popup item details
    imageUrl:
      sample.imageUrl !== undefined ? (
        <img alt={sample.tcin} height={80} src={sample.imageUrl} />
      ) : null,
    className: sample.className,
    departmentId: sample.departmentId,
    departmentName: sample.departmentName,
    classId: sample.className,
    dueDate: sample.dueDate,
    manufacturerStyle: sample.manufacturerStyle,
    color: sample.color,
    size: sample.size,
    manufacturerBrand: sample.manufacturerBrand,
    brandId: sample.brandId,
    brand: sample.brand || '',
    dpci: sample.dpci || '',
    launchDate: sample.launchDate || '',
  }))
}

export const shipmentToUpdateRequest = (shipmentDetail) => {
  return {
    shipment_id: shipmentDetail.shipmentId,
    shipment_status: shipmentDetail.shipmentStatus,
    ship_to_loc: shipmentDetail.shipToLoc,
    pegasus_ids: shipmentDetail.pegasusIds,
    tracking_num: shipmentDetail.trackingNum,
    carrier: shipmentDetail.carrier,
  }
}

export const sampleToShipmentRequest = (sample = {}, vendorCreated) => {
  return {
    pegasus_ids: [
      {
        pegasus_id: sample.pegasusId.slice(2, sample.pegasusId.length),
        vendor_id: sample.vendorId,
        vendor_name: null,
      },
    ],
    ship_to_loc: sample.shipTo,
    brand_id: sample.brandId,
    vendor_created: vendorCreated,
  }
}
