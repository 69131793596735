import axios from 'axios'
import apiConfig from '../apiConfig'

const {
  managements: { url = '' },
  apiKey = '',
} = apiConfig

export const submitShipment = (shipment) => {
  return axios.post(url + 'shipments', shipment, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

// [{shipmentid, trackingnum, carrier }, .... ]
export const updateShipmentDetails = (shipmentDetails) => {
  const { shipment_id } = shipmentDetails
  return axios.put(url + 'shipments/' + shipment_id, shipmentDetails, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export const fetchShipmentsByVendor = () => {
  return axios.post(url + 'shipments/vendor_shipments', {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export const fetchShipmentsBySample = (sampleId) => {
  return axios.get(url + 'shipments/samples/' + sampleId, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export const fetchShipments = (shipmentIds) => {
  return axios.post(
    url + 'shipments/multi_shipments',
    {
      shipment_ids: shipmentIds,
    },
    {
      params: {
        key: apiKey,
      },
    }
  )
}
