import * as types from './actionType'
import { getFormattedOptions } from '../../util/CommonUtils'
import { findIndex, filter, sortBy, isEmpty } from 'lodash'
import { updateMappedImage } from 'mappers/inventoryMapper.js'

export const initialState = (window.Cypress && window.initialState) || {
  alertIsVisible: false,
  alertMessage: '',
  availableFilters: [],
  checkBoxEnabled: true,
  filterNFListProp: {},
  downloadLoadingStatus: false,
  clickableColumn: [
    'description',
    'location',
    'sampleStatus',
    'shootDate',
    'launchDate',
    'propDescription',
    'propCategoryName',
    'propSubcategoryName',
    'scenicDescription',
    'scenicCategoryName',
    'scenicSubcategoryName',
  ],
  locationFilterData: [],
  numSelectedTab: {
    PROPS: 0,
    SAMPLES: 0,
    SCENIC: 0,
  },
  editColumnData: [
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: true,
      label: 'Pegasus ID',
      align: 'center',
    },
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      align: 'center',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'disposition_date',
      numeric: false,
      disablePadding: true,
      label: 'Disposition Date',
      type: 'date',
    },
    {
      id: 'disposition_override_date',
      numeric: false,
      disablePadding: true,
      label: 'Disposition Override Date',
      type: 'date',
    },
    {
      id: 'dispositionNotes',
      numeric: true,
      disablePadding: false,
      label: 'Disposition Notes',
      textAlign: 'right',
    },
    {
      id: 'warehouseNotes',
      numeric: true,
      disablePadding: false,
      label: 'Warehouse Notes',
      textAlign: 'right',
    },
  ],
  columnData: [
    {
      id: 'description',
      numeric: true,
      disablePadding: false,
      label: 'Description',
      align: 'center',
    },
    {
      id: 'imageUrl',
      numeric: false,
      disablePadding: false,
      label: 'Image',
      align: 'center',
    },
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      align: 'center',
    },
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: true,
      label: 'Pegasus ID',
      align: 'center',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'sampleStatus',
      numeric: false,
      disablePadding: true,
      label: 'Status',
    },
    { id: 'location', numeric: false, disablePadding: true, label: 'Location' },
    {
      id: 'projectsDisplayString',
      numeric: false,
      disablePadding: true,
      label: 'Associated Projects',
    },
    {
      id: 'projectName',
      numeric: false,
      disablePadding: true,
      label: 'Project Name',
    },
    {
      id: 'projectCreator',
      numeric: false,
      disablePadding: true,
      label: 'Project Creator',
    },
    {
      id: 'projectStartDate',
      numeric: false,
      disablePadding: true,
      label: 'Shoot Date',
      type: 'date',
    },
    {
      id: 'projectEndDate',
      numeric: false,
      disablePadding: true,
      label: 'End Date',
      type: 'date',
    },
    {
      id: 'launchDate',
      numeric: false,
      disablePadding: true,
      label: 'Launch Date',
      type: 'date',
    },
  ],
  data: [],
  dialogTitle: '',
  fetchDataError: '',
  fetchDataRequestFailure: false,
  fetchDataRequestPending: false,
  fetchDataRequestSuccess: false,
  filters: {},
  invalidData: [],
  isVisible: false,
  item: [],
  numSelected: 0,
  onCheckBoxChange: () => {},
  onCheckBoxHeaderChange: () => {},
  onClick: () => {},
  page: 1,
  popUpReprintLabels: [
    { key: 'tcin', label: 'TCIN' },
    { key: 'dpci', label: 'DPCI' },
    { key: 'description', label: 'Description' },
  ],
  reprintLabelPopupIsVisible: false,
  popupIsVisible: false,
  searchField: '',
  duplicateSamplesPending: false,
  duplicateSamplesFailure: false,
  saveFiltersFailure: false,
  saveFiltersPending: false,
  getFiltersFailure: false,
  getFiltersPending: false,
  selectedData: [],
  rowsPerPage: 100,
  response: false,
  totalRowsCount: 100,
  filterNFList: {},
  updateData: [],
  userId: ' ',
  pageName: 'searchInventory',
  setColumnSettingsPending: false,
  searchedColumns: [],
  isSearchedColumns: false,
  locations: [],
  searchInventorySamplesRequestPending: false,
  searchInventorySamplesRequestFailure: false,
  autoRefreshData: false,
  duplicateSamples: [],
  searchInventoryCancelSampleRequestFailure: false,
  searchInventoryCancelSamplePending: false,
  propsMetadata: {},
  originalPropsMetadata: {},
  scenicMetadata: {},
  originalScenicMetadata: {},
  brandsList: [],
  addAPropAPIStatus: '',
  addPropData: [],
}

export default function search(state = initialState, action = {}) {
  switch (action.type) {
    case types.CALL_EDIT_FIELDS_API: {
      const { data: stateData = [] } = state
      const {
        payload: { data: payloadData, sample_id },
      } = action
      const newDataObj = (stateData || []).map((obj) => {
        if (obj.pegasusId.indexOf(sample_id) !== -1) {
          obj = Object.assign({}, obj, payloadData)
        }
        return obj
      })
      return Object.assign({}, state, {
        ...state,
        data: newDataObj,
      })
    }
    case types.FETCH_INVENTORY_DATA_REQUEST_PENDING: // Request has been initiated
      return Object.assign({}, state, {
        ...state,
        fetchDataRequestPending: action.payload.pending,
      })
    case types.SET_COLUMN_SETTINGS_PENDING: // Request has been initiated
      return Object.assign({}, state, {
        ...state,
        setColumnSettingsPending: action.payload.pending,
      })
    case types.CALL_SEARCH_FIELDS: {
      const {
        payload: { searchedColumns: payloadSearchedColumns = [] },
      } = action
      return Object.assign({}, state, {
        ...state,
        searchedColumns: payloadSearchedColumns,
        isSearchedColumns: payloadSearchedColumns.length > 0,
      })
    }
    case types.RESET_IS_CALL_SEARCH_FIELDS:
      return Object.assign({}, state, {
        ...state,
        isSearchedColumns: false,
      })
    case types.FETCH_INVENTORY_DATA_SUCCESS: // Request has succeeded and we can set the data state
      return Object.assign({}, state, {
        ...state,
        data: action.payload.data.map((item) => {
          return {
            ...item,
            ...updateMappedImage(item),
          }
        }),
        fetchDataRequestSuccess: true,
      })
    case types.FETCH_INVENTORY_DATA_FAILURE: // request has failed with an error message to display
      return Object.assign({}, state, {
        ...state,
        fetchDataError: action.payload.error,
        fetchDataRequestFailure: true,
      })
    case types.DOWNLOAD_LOADING:
      switch(action.downloadType){
        case "CSV":
          return Object.assign({}, state, {
            ...state,
            downloadLoadingStatus: action.data,
          })
        case "PDF":
          return Object.assign({}, state, {
            ...state,
            downloadLoadingStatusPdf: action.data,
          })
        case 'XLSX':
          return Object.assign({}, state, {
            ...state,
            downloadLoadingStatusXlsx: action.data,
          })
        default:
          return Object.assign({}, state, {
            ...state,
            downloadLoadingStatusPdf: false,
            downloadLoadingStatus: false,
            downloadLoadingStatusXlsx: false
          })
      }
  

    case types.SEARCH_INVENTORY_CLEAR_DATA:
      return Object.assign({}, state, {
        ...state,
        data: [],
        invalidData: [],
        searchField: '',
      })
    case types.SEARCH_INVENTORY_SET_INVALID_DATA:
      return Object.assign({}, state, {
        ...state,
        invalidData: action.payload.data,
      })
    case types.SHOW_SEARCH_INVENTORY_PAGE_POPUP: {
      const newState = {
        ...state,
        popupIsVisible: true,
        item: action.payload.item,
      }
      return newState
    }
    case types.CLOSE_SEARCH_INVENTORY_PAGE_POPUP: {
      const newState = {
        ...state,
        popupIsVisible: false,
      }
      return newState
    }
    case types.LOCATION_FILTER_DATA: {
      return Object.assign({}, state, {
        ...state,
        locationFilterData: [...action.data],
      })
    }
    case types.SEARCH_INVENTORY_SET_CHECKBOX: {
      const { selectedData = {} } = state
      const {
        payload: { selected = {} },
      } = action
      const { pegasusId: selectedPegId = '' } = selected
      const selectedIndex = findIndex(selectedData, function (obj = {}) {
        return obj.pegasusId === selectedPegId
      })

      let newSelected = []
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(
          state.selectedData,
          action.payload.selected
        )
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(state.selectedData.slice(1))
      } else if (selectedIndex === state.selectedData.length - 1) {
        newSelected = newSelected.concat(state.selectedData.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          state.selectedData.slice(0, selectedIndex),
          state.selectedData.slice(selectedIndex + 1)
        )
      }
      let numSelectedLocal = {}
      numSelectedLocal['PROPS'] = filter(newSelected, function (o) {
        return o.itemType === 'PROPS'
      }).length
      numSelectedLocal['SAMPLES'] = filter(newSelected, function (o) {
        return o.itemType === 'SAMPLE'
      }).length
      numSelectedLocal['SCENIC'] = filter(newSelected, function (o) {
        return o.itemType === 'SCENIC'
      }).length
      return Object.assign({}, state, {
        ...state,
        selectedData: newSelected,
        numSelected: newSelected.length,
        numSelectedTab: numSelectedLocal,
      })
    }
    case types.SEARCH_INVENTORY_SET_CHECKBOX_HEADER: {
      const { selectedData = [] } = state
      const {
        payload: { selected: selectedIds = {} },
      } = action
      const selectedDiffCount = selectedData.filter(
        (i) =>
          findIndex(selectedIds, function (obj) {
            return obj.pegasusId === i.pegasusId
          }) !== -1
      ).length

      var newDataObj = [].concat.apply(selectedData, selectedIds)

      let newSelected = []
      if (selectedDiffCount === 0) {
        newSelected = newDataObj
      } else {
        newSelected = selectedData.filter(
          (i) =>
            findIndex(selectedIds, function (obj) {
              return obj.pegasusId === i.pegasusId
            }) === -1
        )
      }
      let numSelectedLocal = {}
      numSelectedLocal['PROPS'] = filter(newSelected, function (o) {
        return o.itemType === 'PROPS'
      }).length
      numSelectedLocal['SAMPLES'] = filter(newSelected, function (o) {
        return o.itemType === 'SAMPLE'
      }).length
      numSelectedLocal['SCENIC'] = filter(newSelected, function (o) {
        return o.itemType === 'SCENIC'
      }).length
      return Object.assign({}, state, {
        ...state,
        selectedData: newSelected,
        numSelected: newSelected.length,
        numSelectedTab: numSelectedLocal,
      })
    }
    case types.EMPTY_SELECTED_DATA:
      return Object.assign({}, state, {
        ...state,
        selectedData: [],
      })
    case types.SEARCH_INVENTORY_SET_FILTERS: {
      return Object.assign({}, state, {
        ...state,
        filters: action.payload.data,
      })
    }
    case types.SEARCH_INVENTORY_SET_PAGE: {
      return Object.assign({}, state, {
        ...state,
        page: action.payload.page,
      })
    }
    case types.SEARCH_INVENTORY_SET_ROWS_PER_PAGE: {
      return Object.assign({}, state, {
        ...state,
        rowsPerPage: action.payload.rowsPerPage,
      })
    }
    case types.SHOW_SELECTED_REPRINT_LABELS_POPUP:
      return Object.assign({}, state, {
        ...state,
        reprintLabelPopupIsVisible: true,
        selectedData: [],
      })
    case types.SEARCH_INVENTORY_SET_TOTAL_ROWS_COUNT: {
      return Object.assign({}, state, {
        ...state,
        totalRowsCount: action.payload.totalRowsCount,
      })
    }
    case types.SEARCH_INVENTORY_SET_FILTER_NOTFOUND_LIST: {
      return Object.assign({}, state, {
        ...state,
        filterNFList: action.payload.filterNFList,
      })
    }
    case types.SEARCH_INVENTORY_SET_FILTER_NOTFOUND_LIST_PROP: {
      return Object.assign({}, state, {
        ...state,
        filterNFListProp: action.payload.filterNFListProp,
      })
    }
    case types.SEARCH_INVENTORY_SAVE_FILTERS_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        filters: action.payload.filters,
      })
    }
    case types.SEARCH_INVENTORY_SAVE_FILTERS_FAILURE: {
      return Object.assign({}, state, {
        saveFiltersFailure: true,
      })
    }
    case types.SEARCH_INVENTORY_SAVE_FILTERS_PENDING: {
      return Object.assign({}, state, {
        saveFiltersPending: action.payload.pending,
      })
    }
    case types.SEARCH_INVENTORY_GET_FILTERS_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        availableFilters: action.payload.availableFilters,
      })
    }
    case types.SEARCH_INVENTORY_GET_FILTERS_FAILURE: {
      return Object.assign({}, state, {
        getFiltersFailure: true,
      })
    }
    case types.SEARCH_INVENTORY_GET_FILTERS_PENDING: {
      return Object.assign({}, state, {
        getFiltersPending: action.payload.pending,
      })
    }
    case types.SEARCH_INVENTORY_DUPLICATE_SAMPLES_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        duplicateSamplesFailure: true,
      })
    }
    case types.SEARCH_INVENTORY_DUPLICATE_SAMPLES_PENDING: {
      return Object.assign({}, state, {
        ...state,
        duplicateSamplesPending: action.payload.pending,
      })
    }
    case types.SEARCH_INVENTORY_DUPLICATE_SAMPLES_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        duplicateSamples: action?.payload?.duplicateSamples,
      })
    }
    case types.FETCH_LOCATION_SUCCESS: {
      const {
        payload: { location = [] },
      } = action
      return Object.assign({}, state, {
        ...state,
        locations: location,
      })
    }
    case types.SEARCH_INVENTORY_SAMPLES_REQUEST_PENDING: {
      return Object.assign({}, state, {
        ...state,
        searchInventorySamplesRequestPending: action.payload.pending,
      })
    }
    case types.SEARCH_INVENTORY_UPDATE_SAMPLES_REQUEST_FAILURE: {
      return Object.assign({}, state, {
        searchInventorySamplesRequestFailure: true,
      })
    }
    case types.AUTO_REFRESH_SEARCH: {
      const {
        payload: { autoRefreshData = false },
      } = action
      return Object.assign({}, state, {
        autoRefreshData: autoRefreshData,
      })
    }
    case types.SEARCH_INVENTORY_PAGE_ALERT: {
      const {
        payload: { message = '', response, dialogTitle = '' },
      } = action
      return Object.assign({}, state, {
        ...state,
        alertIsVisible: true,
        alertMessage: message,
        response,
        dialogTitle,
      })
    }
    case types.SEARCH_INVENTORY_CANCEL_SAMPLES_REQUEST_PENDING: {
      const {
        payload: { pending = false },
      } = action
      return Object.assign({}, state, {
        searchInventoryCancelSamplePending: pending,
      })
    }
    case types.SEARCH_INVENTORY_CANCEL_SAMPLES_REQUEST_SUCCESS: {
      const {
        payload: { data: payloadData = [] },
      } = action
      const editableStatuses = ['SAMPLE_ORDERED', 'SAMPLE_SHIPPED']
      const { data: statedata = [] } = state
      const cancelledSamples = payloadData.filter(
        (item) => editableStatuses.indexOf(item.sample_status) !== -1
      )
      const idSet = new Set(cancelledSamples.map((item) => item.pegasusId))
      return Object.assign({}, state, {
        ...state,
        data: statedata.filter((sample) => !idSet.has(sample.pegasusId)),
        alertIsVisable: false,
        selectedData: [],
        numSelected: 0,
      })
    }
    case types.CLOSE_SEARCH_INVENTORY_PAGE_ALERT: {
      return Object.assign({}, state, {
        ...state,
        alertIsVisible: false,
      })
    }
    case types.SEARCH_INVENTORY_CANCEL_SAMPLES_REQUEST_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        searchInventoryCancelSampleRequestFailure: true,
      })
    }
    case types.SET_PROPS_METADATA_SUBCAT: {
      const {
        payload: { data = [] },
      } = action

      const { propsMetadata = [] } = state

      return Object.assign({}, state, {
        ...state,
        propsMetadata: Object.assign({}, propsMetadata, {
          subCategories: data,
        }),
      })
    }
    case types.SET_PROPS_METADATA_SUBCAT_FAILED:
      return Object.assign({}, state, {
        ...state,
        propsMetadata: {},
        originalPropsMetadata: {},
      })
    case types.SET_PROPS_METADATA: {
      const {
        payload: {
          data: {
            categories = [],
            colorFamily = [],
            materials = [],
            sources = [],
            colorFamilies = [],
          },
        },
      } = action
      const scenicCategories = sortBy(categories, ['name'])
      const scenicColorFamily = isEmpty(colorFamily)
        ? sortBy(colorFamilies, ['name'])
        : sortBy(colorFamily, ['name'])
      const scenicMaterials = sortBy(materials, ['material_name'])
      const scenicSources = sortBy(sources, ['source_name'])
      const scenicCategoriesList = getFormattedOptions(
        scenicCategories,
        {
          value: 'category_id',
          label: 'name',
        },
        true
      )

      const scenicColorFamilyList = getFormattedOptions(
        scenicColorFamily,
        {
          value: 'color_id',
          label: 'name',
        },
        true
      )

      const scenicMaterialsList = getFormattedOptions(
        scenicMaterials,
        {
          value: 'material_id',
          label: 'material_name',
        },
        true
      )

      const scenicSourcesList = getFormattedOptions(
        scenicSources,
        {
          value: 'source_id',
          label: 'source_name',
        },
        true
      )

      const propsMetadata = {
        categories: scenicCategoriesList,
        colorFamily: scenicColorFamilyList,
        materials: scenicMaterialsList,
        sources: scenicSourcesList,
      }

      return Object.assign({}, state, {
        ...state,
        propsMetadata: propsMetadata,
        originalPropsMetadata: action.payload.data || {},
      })
    }
    case types.SET_SCENIC_METADATA: {
      const {
        payload: {
          data: {
            categories = [],
            colorFamily = [],
            materials = [],
            sources = [],
          },
        },
      } = action
      const categoriesNew = sortBy(categories, ['name'])
      const colorFamilyNew = sortBy(colorFamily, ['name'])
      const materialsNew = sortBy(materials, ['material_name'])
      const sourcesNew = sortBy(sources, ['material_name'])
      const categoriesList = getFormattedOptions(
        categoriesNew,
        {
          value: 'category_id',
          label: 'name',
        },
        true
      )

      const colorFamilyList = getFormattedOptions(
        colorFamilyNew,
        {
          value: 'color_id',
          label: 'name',
        },
        true
      )

      const materialsList = getFormattedOptions(
        materialsNew,
        {
          value: 'material_id',
          label: 'material_name',
        },
        true
      )

      const sourcesList = getFormattedOptions(
        sourcesNew,
        {
          value: 'source_id',
          label: 'source_name',
        },
        true
      )

      const scenicMetadata = {
        categories: categoriesList,
        colorFamily: colorFamilyList,
        materials: materialsList,
        sources: sourcesList,
      }

      return Object.assign({}, state, {
        ...state,
        scenicMetadata: scenicMetadata,
        originalScenicMetadata: action.payload.data || {},
      })
    }
    case types.SET_PROPS_METADATA_FAILED:
      return Object.assign({}, state, {
        ...state,
        propsMetadata: {},
        originalPropsMetadata: {},
      })
    case types.SET_SCENIC_METADATA_FAILED:
      return Object.assign({}, state, {
        ...state,
        scenicMetadata: {},
        originalScenicMetadata: {},
      })
    case types.SET_ALL_BRANDS_FAILED:
      return Object.assign({}, state, {
        ...state,
        propsMetadata: {},
        originalPropsMetadata: {},
      })
    case types.SET_ALL_BRANDS: {
      const {
        payload: { data = [] },
      } = action
      return Object.assign({}, state, {
        ...state,
        brandsList: data || [],
      })
    }
    case types.ADD_A_PROP_API_STATUS: {
      return Object.assign({}, state, {
        ...state,
        addAPropAPIStatus: action?.payload?.addAPropAPIStatus,
      })
    }
    case types.SET_ADD_PROP_DATA: {
      return Object.assign({}, state, {
        ...state,
        addPropData: action?.payload?.samples,
      })
    }
    default:
      return state
  }
}
