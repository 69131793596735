import React, { PureComponent } from 'react'
import * as propTypes from 'prop-types'
import { isEqual, isEmpty, difference } from 'lodash'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import FlexibleTableHead from './FlexibleTableHead'
import FlexibleTableBody from './FlexibleTableBody'
import FlexibleTableToolbar from './FlexibleTableToolbar'
import { withStyles } from '@material-ui/core/styles'
import { EnhancedTableSortComparator } from './SortComparator'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import { hasFields } from '../../util/Filter'

const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'grid',
    marginBottom: '45px',
  },
  table: {
    minWidth: 500,
  },
  tableWithScrolls: {
    minWidth: 500,
    // minHeight: 500,
    // '& tr:not(:nth-child(1))': {
    '& td:nth-child(1)': {
      position: 'sticky',
      left: 0,
      backgroundColor: 'white',
      padding: '0 4px',
      zIndex: 4,
    },
    '& th:nth-child(1)': {
      position: 'sticky',
      left: 0,
      backgroundColor: '#F7F7F7',
      zIndex: 14,
    },
    '& td:nth-child(2)': {
      position: 'sticky',
      left: 56,
      backgroundColor: 'white',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',

      zIndex: 4,
      // zIndex: 7,
    },
    '& th:nth-child(2)': {
      // '& td:first-child,th:first-child': {
      position: 'sticky',
      left: 65,
      backgroundColor: '#F7F7F7',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      zIndex: 14,
    },
    '& td:nth-child(3)': {
      position: 'sticky',
      left: 102,
      backgroundColor: 'white',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      // zIndex: 7,
      zIndex: 4,
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
    '& th:nth-child(3)': {
      position: 'sticky',
      left: 102,
      // backgroundColor: 'white',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      backgroundColor: '#F7F7F7',
      zIndex: 14,
    },
    '& td:last-child': {
      position: 'sticky',
      right: 0,
      backgroundColor: 'white',
      // zIndex: 3,
    },
    '& th:last-child': {
      position: 'sticky',
      right: 0,
      backgroundColor: 'white',
      zIndex: 4,
    },
  },
  tableWrapper: {
    overflow: 'auto',
  },
  container: {
    maxHeight: 1000,
  },
  tableBody: {
    height: 'auto',
  },
  paginationAction: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
  spacer: {
    height: 40,
  },
  counter: {
    // fontSize: 'large',
    textAlign: 'left',
    color: '#333333',
    fontWeight: 'bold',
    padding: '5px',
  },
  /* do not remove these classes, there is some dependency */
  checkBoxElem: {},
  tableRow: {},
  header: {
    top: 0,
    left: 0,
    zIndex: 2,
    position: 'sticky',
    backgroundColor: '#F7F7F7',
  },
  errorBlock: { padding: '0px 0px 0px 15px !important' },
  errorIcon: {
    display: 'flex',
    color: '#B85300',
    alignItems: 'center',
  },
  progressBarDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressBar: {
    padding: '7px',
    color: '#333333',
  },
  align: {
    display: 'flex',
    alignItems: 'end',
    alignContent: 'right',
    justifyContent: 'flex-start',
    position: 'fixed',
    bottom: '3%',
    right: '3%',
  },
  tableKeyStyle: {
    wordWrap: 'break-word  !important',
  },
})

const scrollStep = 200

class FlexibleTable extends PureComponent {
  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
    this.tableWrapRef = React.createRef()
    const { data, rowsPerPage = 10 } = this.props
    this.state = {
      filteredData: data,
      filterValue: '',
      order: 'asc',
      orderBy: '',
      orderType: '',
      page: 0,
      rowsPerPage,
      enableScrollButtons: false,
      tempObj: {},
      sortChanged: false,
    }
  }

  clearData = () => {
    this.setState({
      filteredData: this.props.data,
      filterValue: '',
    })
  }

  componentDidMount() {
    const {
      loadMore = () => {},
      loadItemsOnScroll,
      savedSearchValue = '',
    } = this.props
    // Detect when scrolled to bottom.
    if (loadItemsOnScroll) {
      this.tableRef.current.addEventListener('scroll', () => {
        if (
          this.tableRef.current.scrollTop +
            this.tableRef.current.clientHeight >=
          this.tableRef.current.scrollHeight
        ) {
          loadMore()
        }
      })
    }
    if (!isEmpty(savedSearchValue)) {
      this.filterData([savedSearchValue])
    }
    this.enableScrollButtonFunction()
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterValue = '' } = this.state
    const { data: propsData = [] } = this.props
    const { data: prevPropsData = [] } = prevProps
    if (!isEqual(prevPropsData, propsData)) {
      this.filterData(filterValue)
    }
    this.enableScrollButtonFunction(prevProps)
  }

  filterData = (searchQuery) => {
    const { data, enableMultiSearch = false } = this.props
    let result = [...data]
    if (searchQuery) {
      if (
        enableMultiSearch &&
        Array.isArray(searchQuery) &&
        searchQuery.length > 0
      ) {
        // If list of search query
        result = data.filter((item) =>
          searchQuery.some((value) => hasFields(item, value))
        )
      } else if (!enableMultiSearch) {
        // If single search query
        result =
          data.filter((i) => {
            return hasFields(i, searchQuery)
          }) || []
      }
    }
    this.setState(
      {
        filteredData: result,
        filterValue: searchQuery,
      },
      () => {
        if (this.state.sortChanged) {
          this.onSortSelectedChange(
            this.state.order,
            this.state.orderBy,
            this.state.orderType
          )
        }
      }
    )
  }

  handleChangePage = (event, page) => {
    if (this.props.paginationAtApiLevel) {
      this.props.setPage(page + 1)
    } else {
      this.setState({
        page: page,
      })
    }
  }

  handleChangeRowsPerPage = async (event) => {
    if (this.props.paginationAtApiLevel) {
      await this.props.setRowsPerPage(event.target.value)
      await this.props.setPage(1)
    } else {
      this.setState({
        rowsPerPage: event.target.value,
      })
    }
  }
  enableScrollButtonFunction = (prevProps = {}) => {
    const { enableScrollButtons: stateEnableScrollButtons } = this.state
    const {
      enableSettings = false,
      enableScrolls = false,
      columnData = [],
    } = this.props
    const { columnData: prevColumnData = [] } = prevProps
    if (enableSettings || enableScrolls) {
      if (
        (this.tableRef.current.scrollWidth ===
          this.tableWrapRef.current.scrollWidth ||
          this.tableRef.current.scrollWidth - 162 <=
            this.tableWrapRef.current.scrollWidth) &&
        // stateEnableScrollButtons &&
        !isEqual(columnData, prevColumnData)
      ) {
        this.setState((state, props) => {
          return {
            enableScrollButtons: false,
          }
        })
      } else if (
        this.tableRef.current.scrollWidth >
          this.tableWrapRef.current.scrollWidth + 10 &&
        !stateEnableScrollButtons
      ) {
        this.setState((state, props) => {
          return {
            enableScrollButtons: true,
          }
        })
      }
    }
  }
  onSortSelectedChange = (order, orderBy, orderType = '') => {
    const dataComparator = EnhancedTableSortComparator(
      order,
      orderBy,
      orderType
    )
    let newData = [...this.state.filteredData]
    newData.sort(dataComparator)
    this.setState({
      filteredData: newData,
      order: order,
      orderBy: orderBy,
      orderType: orderType,
      sortChanged: true,
    })
  }

  listenScrollEvent = () => {
    // console.log('Scroll event detected!')
    // console.log(this.tableRef.current)
    // const { loadMore = () => {}, loadItemsOnScroll } = this.props
    // if (
    //   loadItemsOnScroll &&
    //   this.tableRef.current.scrollTop + this.tableRef.current.clientHeight >=
    //     this.tableRef.current.scrollHeight
    // ) {
    //   loadMore()
    // }
  }

  scrollRightFun = () => {
    let sl = this.tableRef.current.scrollLeft,
      cw = this.tableRef.current.scrollWidth

    if (sl + scrollStep >= cw) {
      this.tableRef.current.scrollTo(cw, 0)
    } else {
      this.tableRef.current.scrollTo(cw, 0)
    }
  }

  scrollLeftFun = () => {
    let sl = this.tableRef.current.scrollLeft
    if (sl - scrollStep <= 0) {
      this.tableRef.current.scrollTo(0, 0)
    } else {
      this.tableRef.current.scrollTo(0, 0)
    }
  }

  getRowsPerPageOptions = () => {
    const {
      showAllRows = false,
      totalRowsCount = 0,
      isAllRowsRequired = false,
    } = this.props
    switch (true) {
      case showAllRows && totalRowsCount > 1000:
        return [10, 25, 50, 100, 1000, totalRowsCount]
      case showAllRows && totalRowsCount > 100:
        return [10, 25, 50, 100, totalRowsCount]
      case showAllRows && isAllRowsRequired:
        return [10, 25, 50, 100, { value: totalRowsCount, label: 'All' }]
      default:
        return [10, 25, 50, 100]
    }
  }

  render() {
    const {
      cascadeFieldKeys,
      cascadeFieldLogo,
      checkBoxEnabled,
      classes,
      clickableRowButton,
      clickableRowButtonColor,
      customRowCount,
      data,
      disableItems,
      disableTablePagination,
      disableTableSorting,
      downloadFunction,
      downloadCSFunction,
      downloadCSFunctionPDF,
      editEnabled,
      actionControlsType,
      deleteEnabled,
      editDataColumn,
      editDataPrimaryButtonText,
      isUpdateDone,
      editItems,
      enableSearch,
      enableAPISearch,
      callSearchAPI = () => {},
      enableDownload,
      enableContactSheetDownload,
      enableMenu,
      enableSettings,
      menuClickFunction,
      settingsClickFunction,
      onCheckBoxChange,
      onCheckBoxHeaderChange,
      onEditChange,
      onSelected,
      paginationAtApiLevel,
      numSelected,
      clickableColumn,
      columnData,
      onCascadeChange,
      valueToCheckAgainst,
      showPopup,
      selected,
      tableTitle,
      totalRowsCount,
      updateData,
      deleteData,
      uniqueIdentifierInData,
      disableItemCount,
      showAllRows,
      enableToolbar = true,
      enableSpacer = true,
      stickyHeader = true,
      tableRowHover = true,
      cellTheme = {},
      tableTitleVariant = 'h6',
      isLoading = false,
      additionalActions = false,
      onSortSelectedChange,
      enableDisclaimer = false,
      enableColumnEditKeys = [],
      authId='',
      additionalFiltersContent = () => {
        return <div></div>
      },
      tableMessage = <div />,
      saveFilterValue,
      savedSearchValue,
      launchInNewTabColumns = [],
      launchInNewTabClick = null,
      rowsPerPage = 10,
      page = 0,
      pageName = '',
      enableMultiSearch = false,
      onSelectColumn = false,
      selectedColumnName = '',
      selectedColumnData = [],
      cascadeIcon,
      onIconClick = () => {},
      moreOptions,
      downloadLoadingStatus = false,
      downloadLoadingStatusPdf = false,
      downloadLoadingStatusXlsx = false,
      errorRowTooltipMessage = '',
      openOptions=false,
      openButtonName='',
      additionalIconInfoContent={}
    } = this.props
    const {
      enableScrollButtons,
      page: statePage = 0,
      order = 'asc',
      orderBy = '',
      rowsPerPage: stateRowsPerPage = 10,
    } = this.state
    let { filteredData = [] } = this.state
    filteredData = filteredData || this.props.data

    const dataFieldKeys = columnData.map((column) => {
      const { id = '', textRestriction = false } = column
      return { id: id, textRestriction: textRestriction }
    })
    const rowsOption = this.getRowsPerPageOptions()
    let itemCount = !disableItemCount ? (
      paginationAtApiLevel ? (
        <p className={classes.counter} padding="none">
          {totalRowsCount
            ? (totalRowsCount < rowsPerPage || (filteredData || []).length) <
              rowsPerPage
              ? (filteredData || []).length
              : totalRowsCount
            : '0'}{' '}
          {customRowCount ? customRowCount : 'Items'}
        </p>
      ) : (
        <p className={classes.counter} padding="none">
          {data.length} {customRowCount ? customRowCount : 'Items'}
        </p>
      )
    ) : null

    const tableContainerClass = enableScrollButtons
      ? classes.tableWithScrolls
      : classes.table

    return (
      <Paper className={classes.root}>
        <div>{tableMessage}</div>
        {enableToolbar && (
          <FlexibleTableToolbar
            clearData={this.clearData}
            enableSearch={enableSearch}
            enableAPISearch={enableAPISearch}
            callSearchAPI={callSearchAPI}
            enableDownload={enableDownload}
            enableContactSheetDownload={enableContactSheetDownload}
            enableMenu={enableMenu}
            enableSettings={enableSettings}
            enableMultiSearch={enableMultiSearch}
            filterData={this.filterData}
            tableTitle={tableTitle}
            tableTitleVariant={tableTitleVariant}
            downloadFunction={downloadFunction}
            downloadCSFunction={downloadCSFunction}
            downloadCSFunctionPDF={downloadCSFunctionPDF}
            itemCount={itemCount}
            disableItemCount={disableItemCount}
            menuClickFunction={menuClickFunction}
            settingsClickFunction={settingsClickFunction}
            additionalActions={additionalActions}
            additionalFiltersContent={additionalFiltersContent}
            showAllRows={showAllRows}
            saveFilterValue={saveFilterValue}
            savedSearchValue={savedSearchValue}
            onSelectColumn={onSelectColumn}
            selectedColumnData={selectedColumnData}
            selectedColumnName={selectedColumnName}
            enableDisclaimer={enableDisclaimer}
            downloadLoadingStatus={downloadLoadingStatus}
            downloadLoadingStatusPdf = {downloadLoadingStatusPdf}
                downloadLoadingStatusXlsx = {downloadLoadingStatusXlsx}
          />
        )}
        <div>
          {!disableTablePagination ? (
            paginationAtApiLevel ? (
              <div>
                <span style={{ marginLeft: '16px', float: 'left' }}>
                  {itemCount}
                </span>
                <TablePagination
                  style={{ float: 'right', marginTop: '7px' }}
                  component="div"
                  count={totalRowsCount}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  rowsPerPageOptions={rowsOption}
                />
              </div>
            ) : (
              <div>
                <span style={{ marginLeft: '16px', float: 'left' }}>
                  {itemCount}
                </span>
                <TablePagination
                  style={{ float: 'right', marginTop: '7px' }}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={stateRowsPerPage}
                  page={statePage}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  rowsPerPageOptions={rowsOption}
                />
              </div>
            )
          ) : totalRowsCount && itemCount ? (
            <div>
              <span style={{ marginLeft: '16px', float: 'left' }}>
                {itemCount}
              </span>
            </div>
          ) : (
            enableSpacer && <div className={classes.spacer} />
          )}
        </div>
        <div className={classes.tableWrapper} ref={this.tableWrapRef}>
          <TableContainer
            className={classes.container}
            // onScroll={this.listenScrollEvent}
            ref={this.tableRef}
          >
            <Table
              stickyHeader={stickyHeader}
              className={tableContainerClass}
              padding="none"
              size={'small'}
            >
              <FlexibleTableHead
                columnData={columnData}
                data={filteredData}
                disableTableSorting={disableTableSorting}
                editEnabled={editEnabled}
                deleteEnabled={deleteEnabled}
                editDataColumn={editDataColumn}
                editDataPrimaryButtonText={editDataPrimaryButtonText}
                rowCount={filteredData.length}
                checkBoxEnabled={checkBoxEnabled}
                clickableRowButton={clickableRowButton}
                onCheckBoxHeaderChange={onCheckBoxHeaderChange}
                order={order}
                orderBy={orderBy}
                numSelected={numSelected}
                onSortSelectedChange={
                  onSortSelectedChange
                    ? onSortSelectedChange
                    : this.onSortSelectedChange
                }
                valueToCheckAgainst={valueToCheckAgainst}
                selected={selected}
                classes={classes}
                enableScrollButtons={enableScrollButtons}
                scrollLeftFun={this.scrollLeftFun}
                scrollRightFun={this.scrollRightFun}
                uniqueIdentifierInData={uniqueIdentifierInData}
                pageName={pageName}
                moreOptions={moreOptions}
              />
              <FlexibleTableBody
                className={classes.tableBody}
                data={filteredData}
                dataFieldKeys={dataFieldKeys}
                disableItems={disableItems}
                cascadeFieldKeys={cascadeFieldKeys}
                cascadeFieldLogo={cascadeFieldLogo}
                clickableColumn={clickableColumn}
                clickableRowButton={clickableRowButton}
                clickableRowButtonColor={clickableRowButtonColor}
                editEnabled={editEnabled}
                actionControlsType={actionControlsType}
                deleteEnabled={deleteEnabled}
                editDataColumn={editDataColumn}
                editDataPrimaryButtonText={editDataPrimaryButtonText}
                isUpdateDone={isUpdateDone}
                editItems={editItems}
                onCheckBoxChange={onCheckBoxChange}
                onCascadeChange={onCascadeChange}
                authId={authId}
                onEditChange={onEditChange}
                onSelected={onSelected}
                rowCount={stateRowsPerPage}
                rowsPerPage={stateRowsPerPage}
                page={statePage}
                paginationAtApiLevel={paginationAtApiLevel}
                checkBoxEnabled={checkBoxEnabled}
                valueToCheckAgainst={valueToCheckAgainst}
                showPopup={showPopup}
                additionalIconInfoContent={additionalIconInfoContent}
                openButtonName={openButtonName}
                selected={selected}
                tableTitle={tableTitle}
                updateData={updateData}
                deleteData={deleteData}
                uniqueIdentifierInData={uniqueIdentifierInData}
                disableTablePagination={disableTablePagination}
                classes={classes}
                tableRowHover={tableRowHover}
                cellTheme={cellTheme}
                columnData={columnData}
                isLoading={isLoading}
                enableScrollButtons={enableScrollButtons}
                launchInNewTabColumns={launchInNewTabColumns}
                launchInNewTabClick={launchInNewTabClick}
                onSelectColumn={onSelectColumn}
                selectedColumnData={selectedColumnData}
                selectedColumnName={selectedColumnName}
                onIconClick={onIconClick}
                cascadeIcon={cascadeIcon}
                moreOptions={moreOptions}
                enableColumnEditKeys={enableColumnEditKeys}
                errorRowTooltipMessage={errorRowTooltipMessage}
                openOptions={openOptions}
              />
            </Table>
          </TableContainer>
        </div>
        {!disableTablePagination ? (
          paginationAtApiLevel ? (
            <TablePagination
              component="div"
              count={totalRowsCount}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              rowsPerPageOptions={rowsOption}
            />
          ) : (
            <TablePagination
              component="div"
              count={filteredData.length}
              rowsPerPage={stateRowsPerPage}
              page={statePage}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              rowsPerPageOptions={rowsOption}
            />
          )
        ) : (
          <div className={classes.spacer} />
        )}
      </Paper>
    )
  }
}
FlexibleTable.contextTypes = {
  router: propTypes.object,
}

FlexibleTable.propTypes = {
  data: propTypes.array,
  disableItems: propTypes.bool,
  disableTablePagination: propTypes.bool,
  disableTableSorting: propTypes.bool,
  checkBoxEnabled: propTypes.bool,
  clickableRowButton: propTypes.string,
  clickableRowButtonColor: propTypes.string,
  enableSearch: propTypes.bool,
  enableAPISearch: propTypes.bool,
  callSearchAPI: propTypes.func,
  showAllRows: propTypes.bool,
  enableDownload: propTypes.bool,
  enableContactSheetDownload: propTypes.bool,
  enableMenu: propTypes.bool,
  enableSettings: propTypes.bool,
  enableScrolls: propTypes.bool,
  editItems: propTypes.array,
  onCheckBoxChange: propTypes.func,
  onCheckBoxHeaderChange: propTypes.func,
  onCascadeChange: propTypes.func,
  onClick: propTypes.func,
  onEditChange: propTypes.func,
  onSelected: propTypes.func,
  paginationAtApiLevel: propTypes.bool,
  numSelected: propTypes.number,
  columnData: propTypes.array,
  enableCheckbox: propTypes.bool,
  onSortSelectedChange: propTypes.func,
  orderBy: propTypes.string,
  valueToCheckAgainst: propTypes.array,
  showPopup: propTypes.func,
  selected: propTypes.array,
  tableTitle: propTypes.string,
  tableTitleVariant: propTypes.string,
  totalRowsCount: propTypes.number,
  updateData: propTypes.func,
  deleteData: propTypes.func,
  uniqueIdentifierInData: propTypes.string,
  enableToolbar: propTypes.bool,
  enableSpacer: propTypes.bool,
  stickyHeader: propTypes.bool,
  tableRowHover: propTypes.bool,
  cellTheme: propTypes.object,
  isLoading: propTypes.bool,
  additionalActions: propTypes.bool,
  saveFilterValue: propTypes.func,
  savedSearchValue: propTypes.string,
  launchInNewTabColumns: propTypes.array,
  loadMore: propTypes.func,
  loadItemsOnScroll: propTypes.bool,
  enableMultiSearch: propTypes.bool,
  enableMoreOption: propTypes.bool,
  enableDisclaimer: propTypes.bool,
  moreOptions: propTypes.array,
  cascadeFieldLogo: propTypes.array,
  autoCompleteOptions: propTypes.array,
  autoCompleteChange: propTypes.func,
  renderAutoComplete: propTypes.bool,
  enableColumnEditKeys: propTypes.array,
  authId: propTypes.string
}

export default withStyles(styles)(FlexibleTable)
