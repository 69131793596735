import React, { useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import { useDispatch } from 'react-redux'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import { Header, SideNavigation } from 'item-component'
import Grid from '@material-ui/core/Grid'
import '@enterprise-ui/canvas-ui-css'
import withHOCs from 'util/withHocs'
import LayoutRouter from './LayoutRouters'
import '../../../src/styles/layout.css'
import { saveUser } from '../../store/user/actionCreator'
import { isSessionActive, sideNavMenuList } from 'util/CommonUtils'

const styles = (theme) => ({
  contentContainer: {
    gridColumn: '1 / span 4',
    padding: theme.spacing(1), // Default <Grid container> spacing is 16. Use 8px padding for bug in material-ui Grid. See https://github.com/callemall/material-ui/issues/7466#issuecomment-316356427
    marginTop: 50,
    marginLeft: 50,
  },
})

const LayoutItemHub = (props) => {
  const dispatch = useDispatch()
  const { classes, auth } = props
  const { isAuthenticated = () => {}, isAuthorized = () => {} } = auth
  const finalSideNavMenuList = (sideNavMenuList || [])?.filter((obj) =>
    isAuthorized(obj?.adgroups || [])
  )

  useEffect(() => {
    const { isAuthenticated = () => {}, hasExpired, login = () => {} } = auth
    if (hasExpired || !isAuthenticated()) {
      login({})
    } else {
      authChecks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const authChecks = () => {
    const { login = () => {} } = auth
    const isSessionActiveProp = isSessionActive({ auth })
    if (!isSessionActiveProp) {
      login({})
    } else {
      dispatch(saveUser())
    }
  }

  if (isAuthenticated()) {
    return (
      <Layout
        theme="dark"
        darkMode="false"
        data-testid="layout_itemhub_pegasus"
      >
        <Header userRole={'user role'} useAuth={useAuth} />
        <SideNavigation
          className="side-navigation-panel"
          data-testid="item-app-sidenav-panel"
          startExpanded={false}
          appMenus={finalSideNavMenuList}
          defaultmenurequired={true}
        />
        <Layout.Body includeRail={false} className="">
          <div className={classes.contentContainer}>
            <Grid container>
              <Grid item xs={12}>
                <LayoutRouter />
              </Grid>
            </Grid>
          </div>
        </Layout.Body>
      </Layout>
    )
  } else {
    return ''
  }
}
export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {},
})(LayoutItemHub)
