import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@mui/icons-material/Clear'
import { Grid, Button } from '@material-ui/core'
import {
  getAllComments,
  updateComment,
  addComment,
  saveViewCommentsFail,
  deleteComment,
} from 'store/comments/actionCreator'
import FlexibleTable from 'components/EnhancedTable/FlexibleTable'

const useStyles = makeStyles((theme) => ({
  strickyHeader: {
    top: '0px',
    left: '0px',
    position: 'sticky',
    background: 'white',
    zIndex: 2,
  },
  helperText: {
    textAlign: 'right !important',
  },
  optionViews: {},
}))

const FlexibleTableNew = withStyles((theme) => ({
  root: {
    width: '100%',
    display: 'grid',
    marginBottom: '0px',
  },
  container: {
    padding: '0px 8px 8px 8px',
    overflowY: 'auto',
    maxHeight: '216px',
    '&::-webkit-scrollbar': {
      width: 9,
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#8d8d8d',
      border: '4px solid transparent',
      backgroundClip: 'content-box',
    },
    // /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#8d8d8d',
      border: '1px solid #8d8d8d',
    },
  },
}))(FlexibleTable)

export default function CommentsPage({
  auth = {},
  sampleData = {},
  pageName = '',
}) {
  const { session = {} } = auth
  const { userInfo = {} } = session
  const { lanId: userLanID } = userInfo
  const itemCopy = Object.assign({}, sampleData)
  const classes = useStyles()
  const inputRef = useRef(null)
  const { sampleId = '' } = itemCopy
  const dispatch = useDispatch()
  const [commentsDataList, setCommentsDataList] = useState([])
  const [totalCount, setTotalCount] = useState(Number(0))
  const [newComment, setNewComment] = useState('')
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(350)
  const { commentsData = [], columnData = [] } = useSelector(
    ({ comments: { commentsData = [], columnData = [] } }) => {
      return {
        commentsData,
        columnData,
      }
    }
  )
  const dataFieldKeys = (columnData || [])?.map((column) => column?.id ?? '')

  useEffect(() => {
    return function cleanup() {
      setCommentsDataList([])
      setTotalCount(Number(0))
      dispatch(saveViewCommentsFail())
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(String(sampleId))) {
      dispatch(
        getAllComments({
          pegasusId: sampleId,
          page: page,
          rowsPerPage: 350,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sampleId, dispatch])
  useEffect(() => {
    if (!isEmpty(String(sampleId))) {
      dispatch(
        getAllComments({
          pegasusId: sampleId,
          page: page,
          rowsPerPage: 350,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, dispatch])

  useEffect(() => {
    if (commentsData.length > 0) {
      const sortedComments = [...commentsData]?.sort((a, b) =>
        a.commentId > b.commentId ? -1 : 1
      )
      setCommentsDataList(sortedComments)
      setTotalCount(commentsData?.length || 0)
    }
  }, [commentsData])

  const updateCommentCallback = () => {
    getAllCommentsCall()
  }

  const updateCommentCall = (updatedRow) => {
    const { commentId = '', commentText = '' } = updatedRow || {}
    if (commentId && commentText) {
      dispatch(
        updateComment({
          commentId,
          updateRequest: { comment: commentText },
          callback: updateCommentCallback,
        })
      )
    }
  }

  const deleteCommentCall = (rowData) => {
    const { commentId = '' } = rowData || {}
    if (commentId) {
      dispatch(deleteComment({ commentId, callback: getAllCommentsCall }))
    }
  }

  const onValueChange = (event) => {
    setNewComment(event.target.value || '')
  }

  const onClearComment = () => {
    setNewComment('')
  }

  const addCommentCallback = () => {
    setNewComment('')
    getAllCommentsCall()
  }

  const getAllCommentsCall = () => {
    dispatch(
      getAllComments({
        pegasusId: sampleId,
        page: page,
        rowsPerPage: rowsPerPage,
      })
    )
  }

  const onAddComment = () => {
    if (newComment) {
      dispatch(
        addComment(
          { pg_ids: [sampleId], comment: newComment },
          addCommentCallback
        )
      )
    }
  }

  return (
    <Grid container direction={'column'} spacing={2}>
      <Grid item className={classes.strickyHeader}>
        <Grid container spacing={2} justify={'space-between'}>
          <Grid item>
            <TextField
              label="Add a comment"
              defaultValue="Default Value"
              className={classes.optionViews}
              variant="outlined"
              onChange={onValueChange}
              multiline
              style={{ minWidth: '540px' }}
              value={newComment}
              inputProps={{
                maxLength: 250,
              }}
              inputRef={inputRef}
              helperText={`${250 - (newComment || '')?.length + ' / 250'}`}
              FormHelperTextProps={{
                classes: { root: classes.helperText },
              }}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} justify={'flex-end'}>
            <Grid item>
              <Button
                color="primary"
                size="small"
                id={'clear-comment'}
                onClick={onClearComment}
                className={classes.buttonClass}
                startIcon={<ClearIcon />}
                variant={'outlined'}
                data-actionid={'clear-comment'}
              >
                {'Clear'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                size="small"
                id={'add-comment'}
                onClick={onAddComment}
                className={classes.buttonClass}
                startIcon={<AddIcon />}
                variant={'contained'}
                data-actionid={'add-comment'}
                disabled={!newComment?.length}
              >
                {'Add'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
          style={{ fontWeight: 'bold' }}
        >
          {totalCount} comments
        </Typography>
        <FlexibleTableNew
          cascadeFieldKeys={[]}
          checkBoxEnabled={false}
          columnData={columnData}
          clickableColumn={[]}
          data={commentsDataList}
          dataFieldKeys={dataFieldKeys}
          numSelected={Number(0)}
          onCheckBoxChange={() => {}}
          onCheckBoxHeaderChange={() => {}}
          onClick={() => {}}
          onSelected={() => {}}
          selected={[]}
          showPopup={() => {}}
          editEnabled={true}
          deleteEnabled={true}
          actionControlsType={'icon'}
          editItems={['commentText']}
          updateData={updateCommentCall}
          deleteData={deleteCommentCall}
          uniqueIdentifierInData={'commentId'}
          disableTablePagination={true}
          enableToolbar={false}
          authId={userLanID}
        />
      </Grid>
    </Grid>
  )
}
