import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import copy from 'copy-to-clipboard'
import { first, isNull } from 'lodash'
import withHOCs from 'util/withHocs'
import { useDropzone } from 'react-dropzone'
import InputAdornment from '@mui/material/InputAdornment'
import Grid from '@material-ui/core/Grid'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import DownloadIcon from '@mui/icons-material/Download'
import CancelIcon from '@mui/icons-material/Cancel'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ClearIcon from '@mui/icons-material/Clear'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Tooltip from '@mui/material/Tooltip'

import {
  requestSamplesWithCSV,
  requestSamplesWithoutCSV,
  setRequestSamplesStatus,
} from '../../store/projects/actionCreator'

const CHUNK_SIZE = Number(5242880)

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    minWidth: '1200px',
    width: 'auto',
  },
  chipFilterTextField: {
    width: '100%',
  },
  defaultButton: {
    maxWidth: '400px',
    width: 'auto',
    margin: theme.spacing(1),
  },
  rootDiv: {
    width: '750px',
    display: 'flex',
    flexFlow: 'column wrap',
  },
  appBarToolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    // zIndex: 3200,
  },
  appBar: {
    zIndex: '3200 !important',
  },
  errorIcon: {
    color: '#B85300',
  },
  successIcon: {
    color: 'green',
  },
  errorChip: {
    margin: theme.spacing(1 / 2),
    color: '#B85300 !important',
    border: '1px solid #B85300 !important',
    fontWeight: 700,
  },
})

const RequestSamplesFlyout = ({
  classes = {},
  toggleDrawer = () => {},
  projectID = '',
}) => {
  const [sampleidsCount, setSampleidsCount] = useState('')
  const [searchList, setSearchList] = useState('')
  const [searchListPayload, setSearchListPayload] = useState([])
  const [validSearchList, setValidSearchList] = useState([])
  const [invalidSearchList, setInvalidSearchList] = useState([])
  const [errorData, setErrorData] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [successData, setSuccessData] = useState([])
  const [currentFile, setCurrentFile] = useState(null)
  const dispatch = useDispatch()

  const { requestSamplesStatus: requestSamplesAPIStatus = '' } = useSelector(
    ({ projects: { requestSamplesStatus = '' } = {} }) => {
      return {
        requestSamplesStatus,
      }
    }
  )

  // const FILE_CONVERSION_CONST = 1000
  // const UPLOAD_FILE_SIZE_LIMIT =
  //   2 * FILE_CONVERSION_CONST * FILE_CONVERSION_CONST

  useEffect(() => {
    return function cleanup() {
      setSearchList('')
      setSearchListPayload([])
      setValidSearchList('')
      setInvalidSearchList('')
      setSampleidsCount('')
      dispatch(setRequestSamplesStatus(''))
      setSuccessData([])
      setErrorData([])
      setCurrentFile(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      requestSamplesAPIStatus === 'success' ||
      requestSamplesAPIStatus === 'partialsuccess'
    ) {
      setSearchList('')
      setSearchListPayload([])
      setValidSearchList('')
      setInvalidSearchList('')
      setSampleidsCount('')
      setCurrentFile(null)
      dispatch(setRequestSamplesStatus(''))
    }
  }, [dispatch, requestSamplesAPIStatus])

  const onSearchListChange = (event) => {
    const currentVal = event.target.value
    const currentValArr = (currentVal.trim(), currentVal.split(/[,\r\n\s]+/g))
    const sampleCount = (currentValArr || []).length
    let samplesCountHText = []
    if (sampleCount > 0) {
      const invalidItems = currentValArr.filter(function (item = '') {
        return ![/^[0-9]{3}-[0-9]{2}-[0-9]{4}$/, /^[0-9]{8,10}$/].some((rx) =>
          rx.test(item)
        )
      })
      const validItems = currentValArr
        .filter(function (item = '') {
          return [/^[0-9]{3}-[0-9]{2}-[0-9]{4}$/, /^[0-9]{8,10}$/].some((rx) =>
            rx.test(item)
          )
        })
        .map((curVal) => {
          const isDPCI = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/.test(curVal)
          const keyLabel = isDPCI ? 'dpci' : 'tcin'
          return {
            [keyLabel]: isDPCI ? curVal : Number(curVal || 0),
            quantity: 1,
          }
        })
      const payloadList = currentValArr.map((curVal) => {
        const isDPCI = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/.test(curVal)
        const keyLabel = isDPCI ? 'dpci' : 'tcin'
        return {
          [keyLabel]: curVal || 0,
          quantity: 1,
        }
      })
      const validItemsCount = (validItems || []).length
      samplesCountHText.push(`${validItemsCount} Valid`)
      if (invalidItems.length > 0) {
        samplesCountHText.push(`${invalidItems} Invalid`)
      }
      setSampleidsCount(samplesCountHText?.join(' & '))
      setSearchList(currentVal)
      setValidSearchList(validItems)
      setSearchListPayload(payloadList)
      setInvalidSearchList(invalidItems)
    }
  }

  const onClearClick = (event) => {
    toggleDrawer(event)('right', false)
  }

  const onCloseClick = (event) => {
    toggleDrawer(event)('right', false)
  }

  const onCopytoClipboard = (copyDataType = '') => {
    switch (copyDataType) {
      case 'errordata': {
        const { errorData: errorObjData = [] } = errorData
        copy(errorObjData?.map((item) => item.tcin).join('\r\n'), {
          format: 'text/plain',
        })
        break
      }
      case 'invalidlist':
        copy(invalidSearchList?.map((item) => item).join('\r\n'), {
          format: 'text/plain',
        })
        break
      default:
        break
    }
  }

  const requestSamplesCallback = (type = '', data = []) => {
    const { errors = [], success = [] } = data
    switch (type) {
      case 'error': {
        setErrorData(data)
        setSuccessData([])
        break
      }
      case 'partialsuccess': {
        setErrorData(errors)
        setSuccessData(success)
        break
      }
      default: {
        setErrorData([])
        setSuccessData(data)
        break
      }
    }
  }

  const onRequestSamples = (event) => {
    if (!isNull(currentFile)) {
      dispatch(
        requestSamplesWithCSV(currentFile, projectID, requestSamplesCallback)
      )
    } else {
      dispatch(
        requestSamplesWithoutCSV(
          searchListPayload,
          projectID,
          requestSamplesCallback
        )
      )
    }
    if (requestSamplesAPIStatus === 'success') {
      toggleDrawer(event)('right', false)
    }
  }

  const onDrop = (acceptedFiles = [], rejectedFiles = []) => {
    if (acceptedFiles.length > Number(1)) {
      setErrorMessage('Only 1 files allowed at a time')
    } else {
      if (rejectedFiles.length < 1) {
        const currentFile = first(acceptedFiles)
        if (currentFile.size > parseInt(CHUNK_SIZE)) {
          setErrorMessage('File size exceeds 5MB')
        } else {
          setCurrentFile(currentFile)
          setErrorMessage('')
        }
      }
    }
  }

  const onDropRejected = (rejectedFiles = []) => {
    // showNotification(true, 'One or more files are not a valid', 'error')
    setErrorMessage('One or more files are not a valid')
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
    },
    onDropRejected: onDropRejected,
  })

  const renderAppbar = (content = '') => {
    return (
      <AppBar
        position="sticky"
        color="transparent"
        elevation={1}
        sx={{
          bottom: 0,
          fontWeight: 500,
          backgroundColor: '#333333',
          color: '#ffffff',
        }}
      >
        <Toolbar className={classes.appBarToolBar}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }} component="div">
            {content}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseClick}
            sx={{ color: '#ffffff' }}
          >
            <CancelIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  }

  // const onItemPaste = (event) => {
  //   console.log('onItemPaste')
  //   const itemList = event && event.target && event.target.value

  //   const sampleCount = !isEmpty(itemList)
  //     ? (itemList.trim(), itemList.split(/[,\r\n\s]+/g).length)
  //     : ''
  //   setSampleidsCount(sampleCount)
  //   setSearchList(itemList)
  // }
  const renderChipList = (itemList = '') => {
    const itemsPerRow = 10
    return (
      <Grid container spacing={1}>
        {itemList.map((item) => {
          return (
            <Grid item xs={12 / itemsPerRow} key={item}>
              <Chip
                id={`${item}chipLabel`}
                className={classes.errorChip}
                onClick={() => {}}
                label={item}
                key={item}
                onDelete={undefined}
                variant="outlined"
              />
            </Grid>
          )
        })}
      </Grid>
    )
  }
  const renderSuccessData = () => {
    return (
      <Grid container spacing={2}>
        <Grid item>
          <CheckCircleOutlineIcon className={classes.successIcon} />
        </Grid>
        <Grid item>
          <Typography component={'subtitle2'} sx={{ fontWeight: 'bold' }}>
            {`You have requested sample(s) for ${
              successData.length || Number(0)
            } items`}
          </Typography>
        </Grid>
      </Grid>
    )
  }
  const renderErrorMessage = () => {
    return (
      <Grid container spacing={2}>
        <Grid item>
          <ErrorOutlineIcon className={classes.errorIcon} />
        </Grid>
        <Grid item>
          <Typography component={'subtitle2'} sx={{ fontWeight: 'bold' }}>
            <Typography
              color="error"
              component={'subtitle2'}
              sx={{ fontWeight: 'bold', color: '#B85300' }}
            >
              {errorMessage}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    )
  }
  const onCopytoClipboardErrorData = (data = []) => {
    copy(data.map((item) => item || '').join('\r\n'), {
      format: 'text/plain',
    })
  }
  const nullCheck = (data = '') => {
    return !isNull(data) && data !== 'null'
  }
  const renderErrorData = () => {
    return (
      <Grid container spacing={2} direction={'column'}>
        {errorData.map((errorObj) => {
          const { reason = '', errorData: errorObjData = [] } = errorObj

          const errorListValid = errorObjData.filter(
            (error) =>
              (error.tcin || error.dpci) &&
              nullCheck(error.tcin) &&
              nullCheck(error.dpci)
          )
          const errorTcinList = errorListValid.map((error) => {
            return error.tcin && error.tcin !== '0' && nullCheck(error.tcin)
              ? error.tcin
              : error.dpci &&
                  error.dpci !== '0' &&
                  nullCheck(error.dpci) &&
                  error.dpci
          })
          return (
            <>
              <Grid container spacing={2}>
                <Grid item>
                  <ErrorOutlineIcon className={classes.errorIcon} />
                </Grid>
                <Grid item>
                  <Typography
                    color="error"
                    component={'subtitle2'}
                    sx={{ fontWeight: 'bold', color: '#B85300' }}
                  >
                    {reason}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {errorTcinList.length > 0 && renderChipList(errorTcinList)}
                {/* {errorTcinList.length > 0 && (
                  <span>
                    <Tooltip title="copy to clipboard">
                      <IconButton
                        aria-label="copy to clipboard"
                        // onClick={() => onCopytoClipboard('errordata')}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </span>
                )} */}
              </Grid>
              <Grid item>
                <Button
                  id="editSelectedItems"
                  data-id="Edit Samples"
                  className={classes.defaultButton}
                  startIcon={<ContentCopyIcon />}
                  onClick={() => onCopytoClipboardErrorData(errorTcinList)}
                >
                  Copy All
                </Button>
              </Grid>
            </>
          )
        })}
      </Grid>
    )
  }

  const onSearchClear = () => {
    setSampleidsCount('')
    setSearchList('')
    setValidSearchList([])
    setInvalidSearchList([])
    setSearchListPayload([])
    setErrorMessage('')
    setErrorData([])
    setSuccessData([])
  }

  const getHelperText = () => {
    return (
      <>
        {sampleidsCount && <span>{`${sampleidsCount} Item(s)`}</span>}
        {invalidSearchList.length > 0 && (
          <span>
            <Tooltip title="copy to clipboard">
              <IconButton
                aria-label="copy to clipboard"
                onClick={() => onCopytoClipboard('invalidlist')}
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </span>
        )}
      </>
    )
  }
  return (
    <div>
      {renderAppbar('Request Samples')}
      <Grid container className={classes.rootDiv}>
        <Grid item xs={12} style={{ margin: '15px 25px' }}>
          {errorData.length > 0 && renderErrorData()}
          {successData.length > 0 && renderSuccessData()}
          {errorMessage && renderErrorMessage()}
          {(errorData.length > 0 || successData.length > 0 || errorMessage) && (
            <Divider
              style={{ margin: '20px 0px', height: '1px' }}
              variant="inset"
            />
          )}
          <Grid container spacing={2} direction={'column'}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Request for new samples to be ordered
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="RSF_AddTCINDPCI"
                label={'ADD TCIN or DPCI'}
                className={classes.chipFilterTextField}
                value={searchList}
                onChange={onSearchListChange}
                //   onPaste={onItemPaste}
                variant="outlined"
                multiline
                InputLabelProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ visibility: searchList ? 'visible' : 'hidden' }}
                    >
                      <ClearIcon
                        style={{ cursor: 'pointer' }}
                        onClick={onSearchClear}
                      />
                    </InputAdornment>
                  ),
                }}
                helperText={sampleidsCount ? getHelperText() : ''}
                disabled={!isNull(currentFile)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ margin: '0px 25px' }}>
          <Grid container spacing={2} direction={'column'}>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 'bold', margin: '16px 0px' }}
              >
                OR
              </Typography>
              <Typography variant="body2">
                Upload a CSV file with TCIN/DPCI, quantity and ship-to-location
                required. Please download and use the template provided.
                Quantity values can also be edited in the project view later.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                id="rsf_downloadCSVTemplate"
                color="primary"
                onClick={() => {}}
                className={classes.defaultButton}
                startIcon={<DownloadIcon />}
                href="/pegasusdocs/RequestSamplesTemplate.csv"
              >
                Download CSV Template
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                id="rsf_downloadshiptoloc"
                color="primary"
                onClick={() => {}}
                className={classes.defaultButton}
                startIcon={<DownloadIcon />}
                href="/pegasusdocs/ShipToLocations.xls"
              >
                Download Ship To Locations
              </Button>
            </Grid>
            <Grid item xs={12}>
              <div {...getRootProps()} className={classes.containerStyle}>
                <Button
                  id="rsf_uploadcsv"
                  color="primary"
                  onClick={() => {}}
                  className={classes.defaultButton}
                  startIcon={<FileUploadIcon />}
                  variant="outlined"
                  disabled={!isNull(currentFile)}
                >
                  UPLOAD CSV
                </Button>
                <p>
                  <em>(Only *.csv file will be accepted)</em> <br />
                </p>

                <input {...getInputProps()} />
              </div>
            </Grid>
            <Grid item xs={12}>
              {!isNull(currentFile) && (
                <Chip
                  id={`${currentFile?.name}chipLabel`}
                  onClick={() => {}}
                  label={currentFile?.name}
                  key={currentFile?.name}
                  onDelete={(event) => {
                    event.preventDefault()
                    setCurrentFile(null)
                    setErrorMessage('')
                    setErrorData([])
                    setSuccessData([])
                  }}
                  style={{
                    fontSize: '16px',
                    color: '#1976d2',
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.footDiv}
          style={{ margin: '15px 25px' }}
        >
          <Grid container justify="flex-end" alignItems="center" spacing={4}>
            <Grid item>
              <Button
                id="clear"
                className={classes.applyButtonMargin}
                variant="outlined"
                color="primary"
                onClick={onClearClick}
              >
                CANCEL
              </Button>
            </Grid>
            <Grid item>
              <Button
                id="apply"
                className={classes.applyButtonMargin}
                variant="contained"
                color="primary"
                disabled={isNull(currentFile) && !validSearchList.length}
                onClick={(event) => {
                  // this.onRequestSamples(event)('applysave')
                  onRequestSamples(event)
                }}
              >
                {'Request Samples'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default withHOCs({ auth: true, nav: true, styles })(RequestSamplesFlyout)
