import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { shape, bool, string, func } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { showNotification } from '../../store/notification/actionCreator'
import { SnackbarContent } from '@material-ui/core'

const styles = () => ({
  anchorOriginTopRight: {
    top: '70px',
    border: '1px solid #FFFFFF',
  },
})

const backgroundColorFromType = (errorType) => {
  switch (errorType) {
    case 'success':
      return '#4A8453'
    case 'error':
      return '#A35C25'
    case 'info':
      return '#3395D6'
    default:
      return '#A35C25'
  }
}

export class Notifications extends React.Component {
  static propTypes = {
    notificationActions: shape({
      showNotification: func,
    }),
    notificationIsShown: bool,
    notificationMessage: string,
  }

  render() {
    const {
      notificationIsShown,
      notificationType = 'error',
      notificationMessage = '',
      classes,
    } = this.props
    if (!notificationMessage) {
      return <></>
    }
    return (
      <Snackbar
        id="notificationSnackbar"
        open={notificationIsShown}
        onClose={this.handleRequestClose}
        autoHideDuration={7000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{
          anchorOriginTopRight: classes.anchorOriginTopRight,
        }}
      >
        <SnackbarContent
          style={{
            backgroundColor: backgroundColorFromType(notificationType),
          }}
          message={
            <React.Fragment>
              <span
                style={{
                  marginRight: '24px',
                }}
              >
                {notificationMessage}
              </span>
              <IconButton
                id="closeIconButton"
                size="small"
                aria-label="close"
                color="inherit"
                onClick={this.handleRequestClose}
                style={{
                  position: 'absolute',
                  right: '8px',
                  padding: '0 8px',
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
    )
  }

  handleRequestClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.props.showNotification(false)
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showNotification }, dispatch)

export const mapStateToProps = (state) => {
  const { notification } = state
  const { isShown, message, type } = notification
  return {
    notificationIsShown: isShown,
    notificationMessage: message,
    notificationType: type,
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Notifications)
)
