import { SAMPLES, PROPS, SCENIC } from '../../enums/Tabs'

/** List of categories to display in Column setting POPUP.
 * Based on this list columns will populate on the POPUP */
export const settingsColumnCategories = {
  items: 'Items',
  sample: 'Sample',
  markdown: 'Markdown',
}

export const propConstants = ['ADDPROP', 'ADDPROPVIEW']

export const scenicConstants = ['ADDSCENIC']

export const settingsColumnCategoriesWithProps = {
  prop: 'Prop',
  ...settingsColumnCategories,
}

export const settingsColumnCategoriesWithScenic = {
  scenic: 'Scenic',
}

/** Order of this list implements display order of the search page columns and the order of default columns on the POPUP.
 * Please be cautious before editing this List
 */
const finalColumnData = (() => {
  const urlParams = new URLSearchParams(window.location.search)
  const vendorCommentsFlag = urlParams.get('vendorcomments')
  const columnData = [
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: true,
      label: 'Pegasus ID',
      align: 'center',
      category: ['sample', 'scenic'],
      tabs: [SAMPLES, PROPS, SCENIC],
      default: true,
    },
    {
      id: 'propId',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Filemaker ID',
      category: 'prop',
      tabs: [PROPS],
      default: true,
    },
    {
      id: 'scenicId',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Filemaker Id',
      category: ['scenic'],
      tabs: [SCENIC],
      default: true,
    },
    {
      id: 'sampleImageDPURL',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Images',
      category: ['scenic', 'prop', 'sample'],
      tabs: [PROPS, SAMPLES, SCENIC],
      default: true,
    },
    {
      id: 'propDescription',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Prop Description',
      category: 'prop',
      tabs: [PROPS],
      default: true,
    },
    {
      id: 'scenicCategoryName',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Category',
      category: ['scenic'],
      tabs: [SCENIC],
      default: true,
    },
    {
      id: 'scenicSubcategoryName',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Subcategory',
      category: ['scenic'],
      tabs: [SCENIC],
      default: true,
    },
    {
      id: 'scenicDescription',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Description',
      category: ['scenic'],
      tabs: [SCENIC],
      default: true,
    },
    {
      id: 'propCategoryName',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Category',
      category: ['prop'],
      tabs: [PROPS],
      default: true,
    },
    {
      id: 'propSubcategoryName',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Subcategory',
      category: ['prop'],
      tabs: [PROPS],
      default: true,
    },
    {
      id: 'sampleStatus',
      numeric: false,
      disablePadding: true,
      label: 'Sample Status',
      category: 'sample',
      tabs: [PROPS],
      default: true,
    },
    {
      id: 'location',
      numeric: false,
      disablePadding: true,
      label: 'Sample Current Location',
      category: 'sample',
      tabs: [PROPS],
      default: true,
    },
    {
      id: 'propNotes',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Notes',
      category: 'prop',
      tabs: [PROPS],
      default: false,
    },
    {
      id: 'propMaterialName',
      numeric: true,
      disablePadding: false,
      label: 'Material Name',
      align: 'center',
      category: ['prop'],
      tabs: [PROPS],
      default: true,
    },
    {
      id: 'propSizeView',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Size',
      category: ['prop'],
      tabs: [PROPS],
      default: true,
    },
    {
      id: 'propSourceName',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Source Name',
      category: ['prop'],
      tabs: [PROPS],
      default: true,
    },
    {
      id: 'targetBrand',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Target Brand',
      category: 'prop',
      tabs: [PROPS],
      default: true,
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
      category: 'items',
      tabs: [PROPS],
      default: true,
    },
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      align: 'center',
      category: 'items',
      tabs: [PROPS],
      default: true,
    },
    {
      id: 'launchDate',
      numeric: false,
      disablePadding: true,
      label: 'Launch Date',
      type: 'date',
      category: 'items',
      tabs: [PROPS],
      default: true,
    },
    {
      id: 'propCreatedDate',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Created Date',
      category: ['prop'],
      tabs: [PROPS],
      default: true,
    },
    {
      id: 'scenicColorFamilyName',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Color Family',
      category: ['scenic'],
      tabs: [SCENIC],
      default: false,
    },
    {
      id: 'propColorFamilyName',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Color Family',
      category: ['prop'],
      tabs: [PROPS],
      default: false,
    },
    {
      id: 'scenicNotes',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Notes',
      category: ['scenic'],
      tabs: [SCENIC],
      default: false,
    },

    {
      id: 'propSpecialist',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Prop Specialist',
      category: 'prop',
      tabs: [PROPS],
      default: false,
    },
    {
      id: 'scenicSpecialist',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Created By',
      category: ['scenic'],
      tabs: [SCENIC],
      default: false,
    },

    {
      id: 'scenicCreatedDate',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Created Date',
      category: ['scenic'],
      tabs: [SCENIC],
      default: true,
    },
    {
      id: 'scenicRoomNames',
      numeric: true,
      disablePadding: true,
      label: 'Scenic Room Name',
      align: 'center',
      category: ['scenic'],
      tabs: [SCENIC],
      default: false,
    },
    {
      id: 'scenicMaterialName',
      numeric: true,
      disablePadding: false,
      label: 'Material Name',
      align: 'center',
      category: ['scenic'],
      tabs: [SCENIC],
      default: true,
    },
    {
      id: 'targetBrand',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Target Brand',
      category: ['scenic'],
      tabs: [SCENIC],
      default: false,
    },
    {
      id: 'scenicSourceName',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Source Name',
      category: ['scenic'],
      tabs: [SCENIC],
      default: false,
    },
    {
      id: 'scenicCost',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Price($)',
      category: ['scenic'],
      tabs: [SCENIC],
      default: false,
    },
    {
      id: 'propCost',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Price($)',
      category: ['prop'],
      tabs: [PROPS],
      default: false,
    },
    {
      id: 'scenicSizeView',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Size',
      category: ['scenic'],
      tabs: [SCENIC],
      default: true,
    },
    {
      id: 'scenicWidth',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Width',
      category: ['scenic'],
      tabs: [SCENIC],
      default: false,
    },
    {
      id: 'propWidth',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Width',
      category: ['prop'],
      tabs: [PROPS],
      default: false,
    },
    {
      id: 'scenicHeight',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Height',
      category: ['scenic'],
      tabs: [SCENIC],
      default: false,
    },
    {
      id: 'propHeight',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Height',
      category: ['prop'],
      tabs: [PROPS],
      default: false,
    },
    {
      id: 'scenicDepth',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Depth',
      category: ['scenic'],
      tabs: [SCENIC],
      default: false,
    },
    {
      id: 'propDepth',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Depth',
      category: ['prop'],
      tabs: [PROPS],
      default: false,
    },
    {
      id: 'scenicBundledView',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Bundled',
      category: ['scenic'],
      tabs: [SCENIC],
      default: false,
    },
    {
      id: 'propBundledView',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Bundled',
      category: ['prop'],
      tabs: [PROPS],
      default: false,
    },
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      align: 'center',
      category: 'items',
      tabs: [SAMPLES],
      default: true,
    },
    {
      id: 'description',
      numeric: true,
      disablePadding: false,
      label: 'Description',
      align: 'center',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: true,
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
      category: 'items',
      tabs: [SAMPLES],
      default: true,
    },
    {
      id: 'sampleStatus',
      numeric: false,
      disablePadding: true,
      label: 'Sample Status',
      category: 'sample',
      tabs: [SAMPLES],
      default: true,
    },
    {
      id: 'location',
      numeric: false,
      disablePadding: true,
      label: 'Sample Current Location',
      category: 'sample',
      tabs: [SAMPLES],
      default: true,
    },
    {
      id: 'location',
      numeric: false,
      disablePadding: true,
      label: 'Asset Current Location',
      category: 'scenic',
      tabs: [SCENIC],
      default: true,
    },
    {
      id: 'projectsDisplayString',
      numeric: false,
      disablePadding: true,
      label: 'Associated Projects',
      category: 'sample',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'projectName',
      numeric: false,
      disablePadding: true,
      label: 'Projects',
      category: 'sample',
      tabs: [SAMPLES, PROPS],
      default: true,
    },
    {
      id: 'projectCreator',
      numeric: false,
      disablePadding: true,
      label: 'Project Creators',
      category: 'sample',
      tabs: [SAMPLES, PROPS],
      default: true,
    },
    {
      id: 'projectStartDate',
      numeric: false,
      disablePadding: true,
      label: 'Shoot Date',
      type: 'date',
      category: 'sample',
      tabs: [SAMPLES, PROPS],
      default: true,
    },
    {
      id: 'projectEndDate',
      numeric: false,
      disablePadding: true,
      label: 'End Date',
      type: 'date',
      category: 'sample',
      tabs: [SAMPLES, PROPS],
      default: true,
    },
    {
      id: 'launchDate',
      numeric: false,
      disablePadding: true,
      label: 'Launch Date',
      type: 'date',
      category: 'items',
      tabs: [SAMPLES],
      default: true,
    },
    {
      id: 'vendorName',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Vendor Name',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'parentTcins',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Parent TCIN',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'color',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Color',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'size',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Size',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'departmentName',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Department Name',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'departmentId',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Department ID',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'className',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Class Name',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'classId',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Class ID',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'subClassName',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Sub Class',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'subClassId',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Sub Class ID',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'lifecycleState',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Lifecycle Status',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'vendorId',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Vendor ID',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'manufacturerStyle',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Manufacturer Style',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'relationshipType',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Relationship Type',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'samplePreviousLaunchDate',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Previous Launch Date',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
      type: 'date',
    },
    {
      id: 'barcodeNumber',
      numeric: true,
      disablePadding: true,
      align: 'center',
      label: 'Barcode Number',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'countryOfOrigin',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Country of Origin',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'scenicOther',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Size: Other',
      category: 'scenic',
      tabs: [SCENIC],
      default: false,
    },
    {
      id: 'sampleStatus',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Asset Status',
      category: 'scenic',
      tabs: [SCENIC],
      default: false,
    },
    {
      id: 'scenicWeight',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Weight',
      category: 'scenic',
      tabs: [SCENIC],
      default: false,
    },
    {
      id: 'manufacturerBrand',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Manufacturer Brand',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'dueDate',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Due Date',
      category: 'items',
      tabs: [SAMPLES, PROPS],
      default: false,
      type: 'date',
    },
    {
      id: 'createDate',
      numeric: false,
      disablePadding: true,
      label: 'Sample Ordered Date',
      type: 'date',
      category: 'sample',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'orderNum',
      numeric: true,
      disablePadding: true,
      label: 'Order #',
      category: 'sample',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'userId',
      numeric: false,
      disablePadding: true,
      label: 'Ordered By',
      category: 'sample',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'studioNotes',
      numeric: false,
      disablePadding: true,
      label: 'Studio Notes',
      category: 'sample',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'warehouseNotes',
      numeric: false,
      disablePadding: true,
      label: 'Warehouse Notes',
      category: 'sample',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'trackingNum',
      numeric: false,
      disablePadding: true,
      label: 'Vendor Shipment Tracking #',
      category: 'sample',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'carrier',
      numeric: false,
      disablePadding: true,
      label: 'Vendor Shipment Carrier',
      category: 'sample',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'vendorEmail',
      numeric: false,
      disablePadding: true,
      label: 'Vendor Email Addresses',
      category: 'sample',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'shipmentId',
      numeric: false,
      disablePadding: true,
      label: 'Vendor Shipment #',
      category: 'sample',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'shipDate',
      numeric: false,
      disablePadding: true,
      label: 'Vendor Ship Date',
      type: 'date',
      category: 'sample',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'shipToDestination',
      numeric: false,
      disablePadding: true,
      label: 'Vendor Shipment Destination',
      category: 'sample',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'shipmentCount',
      numeric: false,
      disablePadding: true,
      label: 'Shipment Count',
      category: 'prop',
      tabs: [PROPS],
      default: false,
    },
    {
      id: 'shipmentCount',
      numeric: false,
      disablePadding: true,
      label: 'Shipment Count',
      category: ['scenic'],
      tabs: [SCENIC],
      default: false,
    },
    {
      id: 'digital_markdown_date',
      numeric: false,
      disablePadding: true,
      label: 'Digital Clearance Start Date',
      type: 'date',
      category: 'markdown',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'store_markdown_date',
      numeric: false,
      disablePadding: true,
      label: 'Store Clearance Start Date',
      type: 'date',
      category: 'markdown',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'receivedDate',
      numeric: false,
      disablePadding: true,
      label: 'Sample Received Date',
      type: 'date',
      category: 'markdown',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'disposition_date',
      numeric: false,
      disablePadding: true,
      label: 'Disposition Date',
      type: 'date',
      category: 'markdown',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'dispositionNotes',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Disposition Notes',
      category: 'markdown',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'disposition_override_date',
      numeric: false,
      disablePadding: true,
      label: 'Disposition Override Date',
      type: 'date',
      category: 'markdown',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
    {
      id: 'intendedSellingChannels',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Intended Selling Channel',
      category: 'markdown',
      tabs: [SAMPLES, PROPS],
      default: false,
    },
  ]
  return vendorCommentsFlag === 'true'
    ? [
        ...columnData,
        {
          id: 'latestComment',
          numeric: false,
          disablePadding: true,
          label: 'Latest Comment',
          category: 'sample',
          tabs: [SAMPLES],
          default: true,
        },
      ]
    : columnData
})()
export const settingsColumnList = finalColumnData
export const propFields = [
  {
    label: 'Category',
    type: 'AutoComplete',
  },
  {
    label: 'Subcategory',
    type: 'AutoComplete',
  },
  {
    label: 'Color Family',
    type: 'AutoComplete',
  },
  {
    label: 'Prop Description',
    type: 'Text',
  },
  {
    label: 'Notes',
    type: 'Text',
  },
  {
    label: 'Prop Specialist',
    type: 'AutoComplete',
  },
  {
    label: 'Retailer',
    type: 'AutoComplete',
  },
  {
    label: 'Target Brand',
    type: 'AutoComplete',
  },
  {
    label: 'Price',
    type: 'Text',
  },
]

export const getSettingsColumnListByPage = (
  page = SAMPLES,
  checkForDefault = false
) => {
  return settingsColumnList
    .map((colObj = {}) => {
      const { id = '' } = colObj
      if (id === 'pegasusId' && page !== SAMPLES) {
        colObj['label'] = 'Pegasus ID'
      }
      return colObj
    })
    .filter((columnInstance) =>
      shouldShowColumn(page, columnInstance, checkForDefault)
    )
}

/**
 * Function to check weather a column should be visible in the active tab.
 * @example: Props image should not be visible in Samples tab, even if it's somehow preselected in column settings
 * @param {String} currentTabValue
 * @param {Object} columnInstance
 * @returns boolean
 */
export const shouldShowColumn = (
  currentTabValue = SAMPLES,
  columnInstance,
  checkForDefault = true
) => {
  const isAllowedColumnForActiveTab =
    columnInstance.tabs.includes(currentTabValue)
  const result = checkForDefault
    ? !!columnInstance.default && isAllowedColumnForActiveTab
    : isAllowedColumnForActiveTab
  return result
}

export const archSettingsColumnList = settingsColumnList
  .filter((columnInstance) => shouldShowColumn(SAMPLES, columnInstance, false))
  .concat([
    {
      id: 'archivedTs',
      numeric: false,
      disablePadding: true,
      label: 'Sample Archived Date',
      type: 'date',
      category: 'sample',
      default: true,
    },
  ])
  .filter(
    (obj) =>
      !['projectCreator', 'projectStartDate', 'projectEndDate'].includes(obj.id)
  )

export const launchInNewTabColumns = ['tcin', 'dpci']
