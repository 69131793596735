import React from 'react'
import * as propTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import withHOCs from 'util/withHocs'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button/Button'
import { Tooltip } from '@mui/material'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import DialogBox from '../../components/DialogBox/DialogBox'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import { Grid, Typography } from '@material-ui/core'
import TabbedPopUp from '../../components/PopUp/TabbedPopUp'
import CircularPageLoader from '../../components/Loader/CircularPageLoader'
import { showNotification } from '../../store/notification/actionCreator'
import { makeTabTables, getTitle } from '../../util/Table'
import { dataTabLabelsShippedVendor } from '../../enums/tableTabs'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import {
  closeAlert,
  closePopup,
  downloadSamples,
  getVendorOrders,
  getVendorOrdersNew,
  locationChange,
  setCheckBox,
  setCheckBoxHeaderChange,
  showAlert,
  showPopup,
  setPage,
  setRowsPerPage,
  setSortedData,
} from '../../store/orderSummary/actionCreator'
import { setShipmentData } from '../../store/shipmentBarcode/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import AddFilters from '../AddFilters/AddFilters'
import copy from 'copy-to-clipboard'
import {
  paramsFromFilters,
  ORDERSUMMARY_SELECTABLE_FILTERS,
  VENDOR_PAGE_SORT_FILTERS,
} from '../AddFilters/constants'
import { getNotFoundBlock } from '../AddFilters/FilterUtils'
import { SampleStatus } from '../../enums/sampleStatus'
import { selectPageFilters } from '../../store/filters/selectors'
import { setFilters } from '../../store/filters/actionCreator'
import { isEmpty, uniq, findIndex } from 'lodash'
import QuickFilters from './QuickFilters'
import { toVendorPageSortRequest } from '../../mappers/OrderMapper'
import { EnhancedTableSortComparator } from '../../components/EnhancedTable/SortComparator'

const styles = (theme) => ({
  root: {
    margin: theme.spacing(3),
    overflowX: 'visible',
  },
  orderSummaryPageHeader: {
    fontSize: 'xx-large',
    float: 'left',
    padding: '0px',
    margin: '0px 0px 0px 24px',
  },
  orderSummaryPagePagination: {
    overflowX: 'auto',
  },
  textField: {
    width: '50%',
  },
  shipSamples: {
    float: 'right',
    margin: '4px 24px 0px 0px',
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
})

class OrderSummaryPage extends React.Component {
  constructor(props) {
    super(props)
    const { filters = {} } = this.props
    this.state = {
      checkAll: true,
      filters: filters,
      selectableFilters: ORDERSUMMARY_SELECTABLE_FILTERS,
      isFilterUpdated: false,
      isQuickFilterUpdated: false,
      orderBy: '',
      order: 'asc',
    }
    this.defaultParams = {
      status: SampleStatus.ORDERED,
    }
  }

  componentDidMount = () => {
    const {
      locationChange,
      selectedData,
      setHeaderTitleArray,
      setFilters = () => {},
      pageName = '',
    } = this.props
    setHeaderTitleArray([{ title: 'Requested Samples', link: `/${pageName}` }])
    locationChange(selectedData)
    setFilters({}, pageName)
  }

  componentWillUnmount() {
    const { setFilters, pageName = '' } = this.props
    const { filters = {} } = this.state
    const filterCopy = Object.assign({}, filters)
    if (!isEmpty(filterCopy)) {
      setFilters(filterCopy, pageName)
    }
  }

  componentDidUpdate(prevProps = {}, prevState = {}) {
    const {
      page: propsPage,
      rowsPerPage: propsRowsPerPage,
      data: propsData = [],
      setPage = () => {},
    } = this.props
    const {
      filters: stateFilters = {},
      isFilterUpdated: stateIsFilterUpdated,
    } = this.state
    const filtersCopy = Object.assign({}, stateFilters)
    const { page: prevPropsPage, rowsPerPage: prevPropsRowsPerPage } = prevProps
    const { isFilterUpdated: prevStateIsFilterUpdated } = prevState
    const doesRowsPerPageUpdated =
      propsPage !== prevPropsPage || propsRowsPerPage !== prevPropsRowsPerPage
    const doesFiltersUpdated =
      !!stateIsFilterUpdated &&
      stateIsFilterUpdated !== prevStateIsFilterUpdated

    if (doesRowsPerPageUpdated || doesFiltersUpdated) {
      this.refreshTableWithNewData(filtersCopy, doesFiltersUpdated)
    }

    if (doesFiltersUpdated) {
      this.setState({
        isFilterUpdated: false,
      })
    }

    if (!propsData.length && propsPage !== 1) {
      setPage(1)
    }
  }

  setFiltersUpdated = (isFilterUpdated = false, filters = {}) => {
    const filtersCopy = Object.assign({}, filters)
    this.setState({
      isFilterUpdated: isFilterUpdated,
      filters: filtersCopy,
    })
  }

  setQuickFiltersUpdated = (isQuickFilterUpdated = false, filters = {}) => {
    const filtersCopy = Object.assign({}, filters)
    const formattedFilters = Object.assign({}, { include_filters: filtersCopy })
    this.setState({
      isQuickFilterUpdated: isQuickFilterUpdated,
      filters: formattedFilters,
    })
  }

  onSortSelectedChange = (order, property, orderType = '') => {
    if (VENDOR_PAGE_SORT_FILTERS.includes(property)) {
      let { orderBy: sortBy, order: sortOrder } = this.state
      let orderBy = toVendorPageSortRequest(property)
      let order = 'desc'
      if (sortBy === orderBy && sortOrder === 'desc') {
        order = 'asc'
      }
      this.setState(
        {
          orderBy: orderBy,
          order: order,
        },
        () => {
          const {
            filters: stateFilters = {},
            isFilterUpdated: stateIsFilterUpdated,
          } = this.state
          this.refreshTableWithNewData(stateFilters, stateIsFilterUpdated)
        }
      )
    } else {
      const { data = [], setSortedData = () => {} } = this.props
      const dataComparator = EnhancedTableSortComparator(order, property)
      let dataCopy = [...data]
      let sortedData = dataCopy.sort(dataComparator)
      setSortedData(sortedData)
    }
  }

  refreshTableWithNewData = (filters = {}, doesFiltersUpdated = false) => {
    const { page, rowsPerPage, getVendorOrdersNew = () => {} } = this.props
    const { orderBy: sortBy, order: sortOrder } = this.state
    const filtersCopy = Object.assign({}, filters)
    this.setState({
      filters: filtersCopy,
    })
    const filtersFromParams = paramsFromFilters(
      filtersCopy,
      page,
      rowsPerPage,
      this.userProfilename,
      this.defaultParams,
      sortBy,
      sortOrder
    )
    const {
      requestPayload: { include_filters = {} } = {},
      requestParams = {},
    } = filtersFromParams
    Object.keys(include_filters).forEach((key) => {
      requestParams.append(key, include_filters[key])
    })
    getVendorOrdersNew(requestParams, doesFiltersUpdated)
  }

  createSortHandler =
    (property, sortType = '') =>
    (event) => {
      this.onSortSelectedChange(event, property, sortType)
    }

  onSelected = (selectedData = [], selected = {}) => {
    const { pegasusId: selectedPegId = '' } = selected
    const selectedPegIdIndex = findIndex(selectedData, function (obj = {}) {
      return obj.pegasusId === selectedPegId
    })
    return selectedPegIdIndex !== -1
  }

  handleSubmitOrder = (event) => {
    const { selectedData = [], showAlert, setShipmentData } = this.props
    const message =
      'Please make sure that all the items are being sent to the same location'
    const sampleShipToList = selectedData.map((obj) => obj.shipTo) || []
    if (uniq(sampleShipToList).length > 1) {
      event.preventDefault()
      showAlert(message)
    } else {
      setShipmentData(selectedData)
    }
  }

  disableButton = () => {
    const { selectedData = [] } = this.props
    return selectedData.length === 0
  }

  recentCommentsClick = (e) => {
    this.onSortSelectedChange('desc', 'latestComment')
  }

  additionalFiltersContent = () => {
    const { filters: stateFilters = {}, orderBy = '', order = '' } = this.state
    const { pageName = '' } = this.props
    const filtersCopy = Object.assign({}, stateFilters)
    const urlParams = new URLSearchParams(window.location.search)
    const vendorCommentsFlag = urlParams.get('vendorcomments')
    return (
      <Grid container alignItems="baseline">
        <Grid item direction="row">
          <QuickFilters
            filters={filtersCopy}
            refreshTableWithNewData={this.refreshTableWithNewData}
            setQuickFiltersUpdated={this.setQuickFiltersUpdated}
            pageName={pageName}
            quickFilterName={'Vendor Sample Status'}
          />
        </Grid>
        {vendorCommentsFlag === 'true' ? (
          <Grid item>
            <Tooltip title={'Recently Commented'}>
              <Button
                color="secondary"
                variant={orderBy === 'comments' ? 'contained' : 'outlined'}
                onClick={this.recentCommentsClick}
                startIcon={
                  orderBy === 'comments' && order === 'asc' ? (
                    <ArrowUpwardIcon />
                  ) : order === 'desc' ? (
                    <ArrowDownwardIcon />
                  ) : (
                    <SwapVertIcon />
                  )
                }
              >
                {'Recently Commented'}
              </Button>
            </Tooltip>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    )
  }

  render() {
    const {
      alertIsVisible,
      alertMessage,
      checkBoxEnabled,
      classes = {},
      closeAlert,
      closePopup,
      columnData,
      clickableColumn,
      data,
      downloadSamples,
      numSelected,
      onCheckBoxChange,
      onCheckBoxHeaderChange,
      onClick,
      popupIsVisible,
      selectedData,
      showPopup,
      page,
      rowsPerPage,
      totalRowsCount,
      setPage,
      setRowsPerPage,
      handleChipClick = () => {},
      filterNFList = {},
      pageName = '',
      popupItem = {},
      auth = {},
      vendorOrderRequestPending = false,
    } = this.props
    const dataFieldKeys = columnData.map((column) => column.id)
    const {
      selectableFilters = [],
      filters: stateFilters = {},
      isQuickFilterUpdated = false,
    } = this.state

    return (
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.headerContent}>
              <Typography variant="h4" gutterBottom>
                Requested Samples
              </Typography>
              <Button
                component={Link}
                to="/vendorShipSamples"
                color="primary"
                disabled={this.disableButton()}
                onClick={this.handleSubmitOrder}
                className={classes.shipSamples}
                type="button"
                variant="contained"
              >
                Process Shipment
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.sectionMargin}>
            <AddFilters
              selectableFilters={selectableFilters}
              setFiltersUpdated={this.setFiltersUpdated}
              pageName={pageName}
              isQuickFilterUpdated={isQuickFilterUpdated}
              quickFilters={stateFilters}
              filterCompType={'include_filters'}
              mergedFilters={stateFilters}
            />
          </Grid>
          <Grid item xs={12} className={classes.sectionMargin}>
            {getNotFoundBlock(filterNFList, stateFilters, handleChipClick)}
          </Grid>
          <Grid item xs={12}>
            {vendorOrderRequestPending ? (
              <CircularPageLoader open={vendorOrderRequestPending} />
            ) : (
              <FlexibleTable
                cascadeFieldKeys={[]}
                checkBoxEnabled={checkBoxEnabled}
                columnData={columnData}
                clickableColumn={clickableColumn}
                customRowCount={'Samples'}
                data={data}
                dataFieldKeys={dataFieldKeys}
                editEnabled={false}
                enableSearch
                enableDownload={true}
                downloadFunction={() => downloadSamples()}
                numSelected={numSelected}
                onCheckBoxChange={onCheckBoxChange}
                onCheckBoxHeaderChange={onCheckBoxHeaderChange}
                onClick={onClick}
                onSortSelectedChange={this.onSortSelectedChange}
                onSelected={this.onSelected}
                selected={selectedData}
                showPopup={showPopup}
                paginationAtApiLevel={true}
                page={page}
                rowsPerPage={rowsPerPage}
                totalRowsCount={totalRowsCount}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                additionalActions={true}
                additionalFiltersContent={this.additionalFiltersContent}
                uniqueIdentifierInData={'pegasusId'}
              />
            )}
          </Grid>
        </Grid>
        <TabbedPopUp
          dataTabLabels={dataTabLabelsShippedVendor}
          isVisible={popupIsVisible}
          data={makeTabTables(
            popupItem,
            classes,
            dataTabLabelsShippedVendor,
            {},
            [],
            '',
            '',
            '',
            auth
          )}
          onClose={closePopup}
          title={getTitle(popupItem)}
        />
        <DialogBox
          isAlertVisible={alertIsVisible}
          message={alertMessage}
          onClose={closeAlert}
          response={false}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    alertIsVisible: state.orderSummary.alertIsVisible,
    alertMessage: state.orderSummary.alertMessage,
    checkBoxEnabled: state.orderSummary.checkBoxEnabled,
    columnData: state.orderSummary.columnData,
    clickableColumn: state.orderSummary.clickableColumn,
    data: state.orderSummary.data,
    numSelected: state.orderSummary.numSelected,
    onCheckBoxChange: state.orderSummary.onCheckBoxChange,
    onCheckBoxHeaderChange: state.orderSummary.onCheckBoxHeaderChange,
    onClick: state.orderSummary.onClick,
    orderNumber: state.orderSummary.orderNumber,
    popupIsVisible: state.orderSummary.popupIsVisible,
    popupItem: state.orderSummary.item,
    selectedData: state.orderSummary.selectedData,
    showAlert: state.orderSummary.showAlert,
    showPopup: state.orderSummary.showPopup,
    page: state.orderSummary.page,
    rowsPerPage: state.orderSummary.rowsPerPage,
    orderBy: state.orderSummary.orderBy,
    order: state.orderSummary.order,
    totalRowsCount: state.orderSummary.totalRowsCount,
    filterNFList: state.orderSummary.filterNFList,
    filters: selectPageFilters(state.orderSummary.pageName)(state),
    pageName: state.orderSummary.pageName,
    vendorOrderRequestPending: state.orderSummary.vendorOrderRequestPending,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    closeAlert: () => dispatch(closeAlert()),
    closePopup: () => dispatch(closePopup()),
    downloadSamples: () => dispatch(downloadSamples()),
    getVendorOrders: (params, doesFiltersUpdated) =>
      dispatch(getVendorOrders(params, doesFiltersUpdated)),
    getVendorOrdersNew: (params, doesFiltersUpdated) =>
      dispatch(getVendorOrdersNew(params, doesFiltersUpdated)),
    locationChange: (selectedData) => dispatch(locationChange(selectedData)),
    onCheckBoxChange: (selectedData) => dispatch(setCheckBox(selectedData)),
    onCheckBoxHeaderChange: (selectedData) =>
      dispatch(setCheckBoxHeaderChange(selectedData)),
    showAlert: (message) => dispatch(showAlert(message)),
    showNotification: (isShown, message) =>
      dispatch(showNotification(isShown, message)),
    showPopup: (item) => dispatch(showPopup(item)),
    setShipmentData: (items) => dispatch(setShipmentData(items)),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    handleChipClick: (chip) => {
      copy(chip.target.innerText)
      dispatch(showNotification(true, 'Value Copied to Clipboard', 'info'))
    },
    setFilters: (filters, pageName) => dispatch(setFilters(filters, pageName)),
    setSortedData: (sortedData) => dispatch(setSortedData(sortedData)),
  }
}

OrderSummaryPage.propTypes = {
  checkBoxEnabled: propTypes.bool,
  columnData: propTypes.array,
  clickableColumn: propTypes.array,
  data: propTypes.array,
  numSelected: propTypes.number,
  onCheckBoxChange: propTypes.func,
  onCheckBoxHeaderChange: propTypes.func,
  onClick: propTypes.func,
  page: propTypes.number,
  rowsPerPage: propTypes.number,
  orderBy: propTypes.string,
  order: propTypes.string,
  orderNumber: propTypes.number,
  vendorId: propTypes.number,
  filterNFList: propTypes.object,
  filters: propTypes.object,
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(OrderSummaryPage)
