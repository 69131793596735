import React, { useEffect, useState, startTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, filter, findIndex, last } from 'lodash'
import withHOCs from 'util/withHocs'
import Grid from '@material-ui/core/Grid'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import { getSizes } from '../../store/autoOrderSamples/actionCreator'
import RenderConfirmModal from './RenderConfirmModal'
import {
  RenderEditFieldsAutoOrder,
  locationList,
} from './EditAutoOrderConstants'
import {
  getClassByDepartment,
  getSubClassByDeptClass,
} from '../../services/itemService'
import {
  departmentClassResponseToSelect,
  deptClassSubClassesResponseToSelect,
} from '../../mappers/inventoryMapper'
import { editSubmit } from '../../store/autoOrderFilters/actionCreator'
import { CustomPaper } from '../../util/CommonUtils'
import { Chip } from '@mui/material'
import NumericFormatCustom from 'pages/SearchInventoryPage/NumericFormatCustom'
const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    minWidth: '1200px',
    width: 'auto',
  },
  chipFilterTextField: {
    width: '100%',
  },
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: theme.spacing(1),
  },
  rootDiv: {
    width: '400px',
    display: 'flex',
    flexFlow: 'column wrap',
    marginTop: 20,
    marginBottom: 20,
  },
  appBarToolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    // zIndex: 3200,
  },
  appBar: {
    zIndex: '3200 !important',
  },
  errorIcon: {
    color: '#B85300',
  },
  successIcon: {
    color: 'green',
  },
  hr: {
    background: 'grey',
    color: 'grey',
    height: '1px',
    width: '100vw',
  },
  errorChip: {
    margin: theme.spacing(1 / 2),
    color: '#B85300 !important',
    border: '1px solid #B85300 !important',
    fontWeight: 700,
  },
  CancelButton: {
    marginRight: 10,
  },
})
const EditAutoOrderSamplesFlyout = (props) => {
  const dispatch = useDispatch()
  const {
    classes = {},
    closePopup = () => {},
    allDepartmentsOptions = [],
    data = [],
    renderSelectionFields = [],
    currentTabValue = 'aog',
  } = props
  const { sizeData } = useSelector(
    ({ autoOrderSamples: { sizeData = '' } }) => {
      return { sizeData }
    }
  )
  const [selectedLocation, setselectedLocation] = useState('')
  const [selectedDepartment, setSelectedDepartment] = useState('')
  const [renderConfirm, setRenderConfirm] = useState(false)
  const [selectedClass, setSelectedClass] = useState([])
  const [selectedSubClass, setSelectedSubClass] = useState([])
  const [quantity, setQuantity] = useState([])
  const [weeksFromLaunch, setWeeksFromLaunch] = useState('')
  const [classOptions, setClassOptions] = useState([])
  const [subClassOptions, setSubClassOptions] = useState([])
  const [allSubClassOptions, setAllSubClassOptions] = useState([])
  const [requestBody, setRequestBody] = useState([])
  const onCloseClick = (event) => {
    closePopup(false)
  }
  useEffect(() => {
    dispatch(getSizes())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (!isEmpty(selectedDepartment) && selectedDepartment.id !== '') {
      getClassOptions().then((selectData) => {
        selectData.unshift({
          label: 'ALL',
          value: 'ALL',
        })
        setClassOptions(selectData)
      })
    } else {
      startTransition(() => {
        setClassOptions([])
        setSubClassOptions([])
        setAllSubClassOptions([])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartment])

  useEffect(() => {
    if (selectedClass?.length > 0) {
      getSubClassOptions().then((selectData) => {
        setAllSubClassOptions(selectData)
        const curSelectedData = selectData ?? []
        const remainingSubClassOptions = filter(
          allSubClassOptions,
          function (filterValue) {
            return selectedClass
              ?.map((obj) => obj.class_id)
              ?.includes(filterValue.class_id)
          }
        )
        const allSelectedData = Array.from(
          new Set([...remainingSubClassOptions, ...curSelectedData])
        )?.sort((a, b) => a.class_id - b.class_id)
        const finalSelectedData = Array.from(new Set([...allSelectedData]))
        finalSelectedData.unshift({
          label: 'ALL',
          value: 'ALL',
          displayName: 'ALL',
          group_by: '',
          group_by_id: '',
          id: '',
        })
        setSubClassOptions(finalSelectedData)
        setAllSubClassOptions(allSelectedData)
      })
    } else {
      setSubClassOptions([])
      setAllSubClassOptions([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClass])

  useEffect(() => {
    const defaultArray = filter(data, function (selectedObject) {
      return (
        selectedObject.departmentId === selectedDepartment?.id &&
        selectedObject.classId === selectedClass[0]?.value
      )
    })
    const defaultValue =
      selectedClass.length === 1 ? defaultArray[0]?.size.split(',') || [] : []
    setQuantity(defaultValue)
    const defaultWeeksFromLaunch =
      selectedClass.length === 1 ? defaultArray[0]?.weeksFromLaunch || '' : ''
    setWeeksFromLaunch(defaultWeeksFromLaunch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartment, selectedClass, selectedSubClass])
  const getClassOptions = () => {
    return getClassByDepartment(selectedDepartment.id).then((data) => {
      return departmentClassResponseToSelect(data)
    })
  }
  const getSubClassOptions = () => {
    return getSubClassByDeptClass({
      departmentId: selectedDepartment?.id,
      classId: last(selectedClass || [])?.value,
    }).then((data) => {
      return deptClassSubClassesResponseToSelect(data)
    })
  }

  const renderAppbar = (content = '') => {
    return (
      <AppBar
        position="sticky"
        color="transparent"
        elevation={1}
        sx={{
          bottom: 0,
          fontWeight: 500,
          backgroundColor: '#ffffff',
          color: '#333333',
        }}
      >
        <Toolbar className={classes.appBarToolBar}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }} component="div">
            {content}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseClick}
            sx={{ color: '#333333' }}
          >
            <CancelIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  }
  const handleChange = (fieldName = '', newValue = '') => {
    switch (fieldName) {
      case 'size':
        return getQuantity(newValue)
      case 'Location':
        return setselectedLocation(newValue)
      case 'department':
        startTransition(() => {
          setSelectedDepartment(newValue)
          setSelectedClass([])
          setSelectedSubClass([])
          setClassOptions([])
          setSubClassOptions([])
          setAllSubClassOptions([])
        })
        break
      case 'class':
        startTransition(() => {
          setSelectedClass(newValue)
          const curSelectedSubClass = selectedSubClass.filter((subClass) => {
            return newValue
              .map((obj) => obj.class_id)
              ?.includes(subClass.class_id)
          })
          setSelectedSubClass(curSelectedSubClass)
        })
        break
      case 'subclass':
        return setSelectedSubClass(newValue)
      case 'weeksFromLaunch':
        return setWeeksFromLaunch(newValue.target.value)
      default:
        return ''
    }
  }
  const getQuantity = (quantityValue = []) => {
    let quantityArray = []
    quantityValue.map((quantityValueInstance) => {
      quantityArray.push(quantityValueInstance?.name || quantityValueInstance)
      return {}
    })
    setQuantity(quantityArray)
  }
  const getFieldOptions = (renderValue = '') => {
    switch (renderValue) {
      case 'Location':
        return locationList
      case 'department':
        return allDepartmentsOptions
      case 'class':
        return classOptions
      case 'subclass':
        return subClassOptions
      case 'size':
        return sizeData
      default:
        return ''
    }
  }
  const getFieldValue = (renderValue = '') => {
    switch (renderValue) {
      case 'Location':
        return selectedLocation
      case 'department':
        return selectedDepartment
      case 'class':
        return selectedClass
      case 'subclass':
        return selectedSubClass
      case 'size':
        return quantity
      case 'weeksFromLaunch':
        return weeksFromLaunch
      default:
        return ''
    }
  }
  const renderAutoComplete = (renderEditFieldObject) => {
    const { renderValue = '', label = '' } = renderEditFieldObject
    const fieldValue = getFieldValue(renderValue)
    const fieldOptions = getFieldOptions(renderValue)
    const filterOptions = createFilterOptions({
      matchFrom: 'any',
      stringify: (option) => option['label'],
    })
    return (
      <Grid item xs={12} style={{ margin: '15px 25px' }}>
        <Autocomplete
          id="aisle"
          style={{ width: 'auto' }}
          multiple={
            ['subclass', 'class'].indexOf(renderValue) !== -1 ? true : false
          }
          onChange={(event, newValue) => {
            handleChange(renderValue, newValue)
          }}
          PaperComponent={CustomPaper}
          getOptionLabel={
            ['subclass', 'department'].indexOf(renderValue) === -1
              ? (option) => option.label
              : (option) => option.displayName
          }
          renderTags={(value, getTagProps) =>
            ['subclass', 'class'].indexOf(renderValue) !== -1 &&
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={
                  ['subclass', 'department'].indexOf(renderValue) === -1
                    ? option.label
                    : option.displayName
                }
                {...getTagProps({ index })}
              />
            ))
          }
          options={fieldOptions}
          groupBy={(option = {}) => option?.group_by || ''}
          filterOptions={filterOptions}
          autoComplete
          includeInputInList
          value={fieldValue}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
      </Grid>
    )
  }
  const renderInputField = (renderEditFieldObject) => {
    const { renderValue = '', label = '' } = renderEditFieldObject
    const fieldValue = weeksFromLaunch
    return renderValue === 'size' ? (
      <Grid item xs={12} style={{ margin: '15px 25px' }}>
        <Autocomplete
          multiple
          id="aisle"
          defaultValue={{}}
          options={sizeData}
          value={quantity}
          autoComplete
          includeInputInList
          getOptionLabel={(option) => option?.name || 'option'}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          onChange={(event, values) => {
            handleChange(renderValue, values)
          }}
          renderInput={(params) => <TextField {...params} label="Size" />}
        />
      </Grid>
    ) : (
      <Grid item xs={12} style={{ margin: '15px 25px' }}>
        <TextField
          label={label}
          value={fieldValue !== '' ? Number(fieldValue) : ''}
          InputProps={{
            inputComponent: NumericFormatCustomNew,
          }}
          inputProps={{
            thousandSeparator: false,
            decimalScale: 2,
            isAllowed: (values) => {
              const { formattedValue, floatValue } = values
              return (
                formattedValue === '' || (floatValue <= 99 && floatValue > 0)
              )
            },
          }}
          onChange={(e, newValue) => handleChange(renderValue, e)}
        />
      </Grid>
    )
  }

  const onSubmitEditClick = () => {
    let returnArray = []
    let requestBody = {}
    const leastHierarychyOption = Object.entries(selectedSubClass || {}).length
      ? 'subclass'
      : 'class'
    const leastHierarychyValues =
      leastHierarychyOption === 'subclass' ? selectedSubClass : selectedClass
    const leastHierarychyOptions =
      leastHierarychyOption === 'subclass' ? subClassOptions : classOptions
    const allOptionIndex = findIndex(leastHierarychyValues, function (o) {
      return o.value === 'ALL'
    })

    const { id: department_id = '' } = selectedDepartment
    const { label: location_label = '' } = selectedLocation

    const renderDataOptions =
      allOptionIndex === -1
        ? leastHierarychyValues
        : filter(leastHierarychyOptions, function (filterValue) {
            return filterValue.value !== 'ALL'
          })
    renderDataOptions.map((selectedClassInstance) => {
      requestBody = {}
      const {
        class_id: selected_class = '',
        subclass_id: selected_subclass = '',
      } = selectedClassInstance
      if (department_id !== '' && department_id !== null) {
        requestBody['department_id'] = department_id
      }
      if (selected_class !== '' && selected_class !== null) {
        requestBody['class_id'] = selected_class
      }
      if (selected_subclass !== '' && selected_subclass !== null) {
        requestBody['sub_class_id'] = selected_subclass
      }
      if (!isEmpty(quantity)) {
        requestBody['size'] = quantity
      }
      if (!isEmpty(location_label)) {
        requestBody['ship_to_location'] = location_label
      }
      if (Number(weeksFromLaunch) !== 0) {
        requestBody['weeks_from_launch'] = weeksFromLaunch
      }
      returnArray.push(requestBody)
      return {}
    })
    setRequestBody([...returnArray])
    selectedClass.length > 1 || allOptionIndex !== -1
      ? setRenderConfirm(true)
      : handleConfirm(returnArray, false)
  }
  const onCloseModal = () => {
    setRenderConfirm(false)
  }
  const handleConfirm = (requestBodyLocal = [], fromModal = true) => {
    const { refreshAutoOrderGrid = () => {} } = props
    const requestBodyData = fromModal ? requestBody : requestBodyLocal
    dispatch(editSubmit(requestBodyData, refreshAutoOrderGrid))
  }
  const renderEditFields = (renderFields = []) => {
    const renderNecessaryFieldsArray =
      currentTabValue !== 'aog'
        ? renderFields?.filter((obj) => obj?.id !== 'Weeks_From_Launch')
        : renderFields
    let returnComponent = renderNecessaryFieldsArray.map(
      (renderEditFieldObject) => {
        const { renderType = '' } = renderEditFieldObject
        switch (renderType) {
          case 'AutoComplete':
            return renderAutoComplete(renderEditFieldObject)
          case 'Text':
            return renderInputField(renderEditFieldObject)
          default:
            return ''
        }
      }
    )
    return returnComponent
  }
  const disabledSubmit =
    !isEmpty(selectedDepartment) &&
    !isEmpty(selectedClass) &&
    (!isEmpty(quantity) ||
      !isEmpty(selectedLocation) ||
      !isEmpty(weeksFromLaunch))
  return (
    <div>
      {renderConfirm && (
        <RenderConfirmModal
          open={renderConfirm}
          message={`The changes will update all the ${
            renderConfirm ? selectedClass.length : ''
          } selected Classes and overwrite any existing data`}
          onConfirm={() => handleConfirm()}
          onClose={() => onCloseModal()}
        />
      )}
      {renderAppbar(' Edit Auto Order Settings')}
      <Grid container className={classes.rootDiv}>
        <Typography
          variant="h7"
          sx={{ fontWeight: 'bold', marginLeft: '6%' }}
          component="div"
        >
          Select Dept-Class to edit for
        </Typography>
        {renderEditFields(renderSelectionFields)}
        <hr className={classes.hr} />
        <Typography
          variant="h7"
          sx={{ fontWeight: 'bold', marginLeft: '6%' }}
          component="div"
        >
          Enter new value for Ship To Location and/or Size
        </Typography>
        {renderEditFields(RenderEditFieldsAutoOrder)}
        <hr className={classes.hr} />

        <Grid style={{ marginTop: 30, marginLeft: '05%' }}>
          <Button
            variant="outlined"
            style={{ marginLeft: 10 }}
            onClick={onCloseClick}
          >
            Cancel
          </Button>
          <Button
            disabled={!disabledSubmit}
            variant="contained"
            style={{ marginLeft: 10 }}
            onClick={onSubmitEditClick}
          >
            update
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
const NumericFormatCustomNew = React.forwardRef((props, ref) => (
  <NumericFormatCustom {...props} ref={ref} />
))

export default withHOCs({ auth: true, nav: true, styles })(
  EditAutoOrderSamplesFlyout
)
