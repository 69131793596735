import * as types from './actionType'
import { getDisplayImage } from '../../util/CommonUtils'
import { findIndex } from 'lodash'

const finalColumnData = (() => {
  const urlParams = new URLSearchParams(window.location.search)
  const vendorCommentsFlag = urlParams.get('vendorcomments')
  const columnData = [
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: false,
      label: 'Pegasus ID',
      textAlign: 'right',
    },
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      textAlign: 'right',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
      textAlign: 'right',
    },
    {
      id: 'size',
      numeric: false,
      disablePadding: false,
      label: 'Size',
      textAlign: 'right',
    },
    {
      id: 'departmentName',
      numeric: true,
      disablePadding: false,
      label: 'Department',
      textAlign: 'right',
    },
    {
      id: 'vendorName',
      numeric: true,
      disablePadding: false,
      label: 'Vendor',
      textAlign: 'right',
    },
    {
      id: 'manufacturerStyle',
      numeric: true,
      disablePadding: false,
      label: 'Manufacturer Style',
      textAlign: 'right',
    },
    {
      id: 'createDate',
      numeric: true,
      disablePadding: false,
      label: 'Order Date',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'dueDate',
      numeric: true,
      disablePadding: false,
      label: 'Due Date',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'destination',
      numeric: true,
      disablePadding: false,
      label: 'Ship To',
      textAlign: 'right',
    },
    {
      id: 'vendorNotes',
      numeric: true,
      disablePadding: false,
      label: 'Notes',
      textAlign: 'right',
    },
  ]
  return vendorCommentsFlag === 'true'
    ? [
        ...columnData,
        {
          id: 'latestComment',
          numeric: false,
          disablePadding: false,
          label: 'Latest Comment',
        },
      ]
    : columnData
})()
export const initialState = {
  checkBoxEnabled: true,
  clickableColumn: [
    'size',
    'departmentName',
    'description',
    'manufacturerStyle',
    'vendorName',
    'createDate',
    'dueDate',
    'destination',
  ],
  columnData: finalColumnData,
  data: [],
  item: [],
  numSelected: 0,
  onCheckBoxChange: () => {},
  onCheckBoxHeaderChange: () => {},
  onClick: () => {},
  orderBy: '',
  order: 'asc',
  popupIsVisible: false,
  response: false,
  selectedData: [],
  vendorOrderRequestFailure: false,
  vendorOrderRequestPending: false,
  page: 1,
  rowsPerPage: 10,
  totalRowsCount: 0,
  filterNFList: {},
  pageName: 'orderSummary',
}

export default function orderSummary(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOCATION_CHANGE: {
      return Object.assign({}, state, {
        selectedData: [],
      })
    }
    case types.ORDER_SUMMARY_SUBMIT_ORDER: {
      const updatedData = state.data.filter(
        (sample) =>
          !action.payload.response.pegasusIds.includes(
            parseInt(sample.pegasusId.slice(2, sample.pegasusId.length))
          )
      )
      return Object.assign({}, state, {
        ...state,
        selectedData: [],
        data: updatedData,
      })
    }
    case types.ORDER_SUMMARY_SET_DATA: {
      return Object.assign({}, state, {
        data: action.payload.data,
      })
    }
    case types.ORDER_SUMMARY_SET_FILTER_NOTFOUND_LIST: {
      return Object.assign({}, state, {
        ...state,
        filterNFList: action.payload.filterNFList,
      })
    }
    case types.SHOW_ORDER_SUMMARY_PAGE_POPUP: {
      const newState = {
        ...state,
        popupIsVisible: true,
        item: action.payload.item,
      }
      return newState
    }
    case types.CLOSE_ORDER_SUMMARY_PAGE_POPUP: {
      const newState = {
        ...state,
        popupIsVisible: false,
      }
      return newState
    }
    case types.SHOW_ORDER_SUMMARY_PAGE_ALERT: {
      const newState = {
        ...state,
        alertIsVisible: true,
        alertMessage: action.payload.message,
      }
      return newState
    }
    case types.CLOSE_ORDER_SUMMARY_PAGE_ALERT: {
      const newState = {
        ...state,
        alertIsVisible: false,
      }
      return newState
    }
    case types.ORDER_SUMMARY_SET_CHECKBOX: {
      const { selectedData = {} } = state
      const {
        payload: { selected = {} },
      } = action
      const { pegasusId: selectedPegId = '' } = selected
      const selectedIndex = findIndex(selectedData, function (obj = {}) {
        return obj.pegasusId === selectedPegId
      })

      let newSelected = []
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(
          state.selectedData,
          action.payload.selected
        )
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(state.selectedData.slice(1))
      } else if (selectedIndex === state.selectedData.length - 1) {
        newSelected = newSelected.concat(state.selectedData.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          state.selectedData.slice(0, selectedIndex),
          state.selectedData.slice(selectedIndex + 1)
        )
      }

      return Object.assign({}, state, {
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }
    case types.ORDER_SUMMARY_SET_CHECKBOX_HEADER: {
      const { selectedData = [] } = state
      const {
        payload: { selected: selectedIds = {} },
      } = action
      const selectedDiffCount = selectedData.filter(
        (i) =>
          findIndex(selectedIds, function (obj) {
            return obj.pegasusId === i.pegasusId
          }) !== -1
      ).length

      var newDataObj = [].concat.apply(selectedData, selectedIds)

      let newSelected = []
      if (selectedDiffCount === 0) {
        newSelected = newDataObj
      } else {
        newSelected = selectedData.filter(
          (i) =>
            findIndex(selectedIds, function (obj) {
              return obj.pegasusId === i.pegasusId
            }) === -1
        )
      }

      return Object.assign({}, state, {
        ...state,
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }

    case types.ORDER_SUMMARY_VENDOR_ORDER_REQUEST_SUCCESS: {
      // For back navigation to the page, preserve any selected objects.
      // This can be removed once demo data and app navigation are implemented
      const payloadData = action.payload.data.map((item) => ({
        ...item,
        imagePopupUrl: item.imageUrl,
        imageUrl:
          item.imageUrl !== undefined &&
          item.imageUrl !== null &&
          item.imageUrl !== ''
            ? getDisplayImage(item.imageUrl)
            : 'Image Not Found',
        destination: String(item.shipTo).split(',')[0],
      }))
      return Object.assign({}, state, {
        data: payloadData,
      })
    }

    case types.ORDER_SUMMARY_VENDOR_ORDER_REQUEST_PENDING: {
      return Object.assign({}, state, {
        vendorOrderRequestPending: action.payload.pending,
      })
    }
    case types.ORDER_SUMMARY_VENDOR_ORDER_REQUEST_FAILURE: {
      return Object.assign({}, state, {
        vendorOrderRequestFailure: true,
      })
    }
    case types.SET_ORDER_SUMMARY_PAGE_PAGE: {
      return Object.assign({}, state, {
        page: action.payload.page,
      })
    }
    case types.SET_ORDER_SUMMARY_PAGE_ROWS_PER_PAGE: {
      return Object.assign({}, state, {
        rowsPerPage: action.payload.rowsPerPage,
      })
    }
    case types.SET_ORDER_SUMMARY_PAGE_TOTAL_ROWS_COUNT: {
      return Object.assign({}, state, {
        totalRowsCount: action.payload.totalRowsCount,
      })
    }
    case types.SET_ORDER_SUMMARY_PAGE_SORTED_DATA: {
      const {
        payload: { data: sortedData = [] },
      } = action
      return Object.assign({}, state, {
        ...state,
        data: sortedData,
      })
    }
    default:
      return state
  }
}
