import { PureComponent } from 'react'
import * as propTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import TableRow from '@material-ui/core/TableRow/TableRow'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableHead from '@material-ui/core/TableHead/TableHead'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import TableSortLabel from '@material-ui/core/TableSortLabel/TableSortLabel'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { findIndex } from 'lodash'
import { checkIsEnabledRow, removeReactElements } from 'util/CommonUtils'

class FlexibleTableHead extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      order: 'asc',
      orderBy: '',
    }
  }

  handleRequestSort = (event, property, orderType = '') => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }
    const { onSortSelectedChange } = this.props

    this.setState({
      order: order,
      orderBy: orderBy,
    })
    onSortSelectedChange(order, orderBy, orderType)
  }

  createSortHandler =
    (property, sortType = '') =>
    (event) => {
      this.handleRequestSort(event, property, sortType)
    }

  render() {
    const {
      editEnabled,
      deleteEnabled,
      editDataColumn,
      rowCount,
      onCheckBoxHeaderChange,
      disableTableSorting,
      checkBoxEnabled,
      clickableRowButton,
      columnData,
      data,
      valueToCheckAgainst,
      classes,
      enableScrollButtons,
      uniqueIdentifierInData = 'pegasusId',
      pageName = '',
      moreOptions = false,
    } = this.props

    const isErrorRowsExists = data.some((dataObj) => !!dataObj.isAnErrorRow)
    const isAdditionalIconRowsExists = data.some((dataObj) => !!dataObj.isAnAdditionalInfoRow)
    const checkAllCount = pageName === 'myOrders' ? data.length : rowCount

    const uniqueKey = `${
      uniqueIdentifierInData
        ? data[uniqueIdentifierInData]
        : data[columnData[0].id]
    }`

    let children = columnData.map((i = {}) => {
      const { type: sortType = '' } = i

      return (
        <TableCell
          key={i.id}
          style={i.style}
          sortDirection={this.state.orderBy === i.id ? this.state.order : false}
          className={classes.header}
        >
          {disableTableSorting ? (
            i.label
          ) : (
            <Tooltip
              title={'Sort'}
              placement={i.numeric ? 'bottom-end' : 'bottom-start'}
              enterDelay={300}
            >
              <TableSortLabel
                active={this.state.orderBy === i.id}
                direction={this.state.order}
                onClick={this.createSortHandler(i.id, sortType)}
              >
                {i.label}
              </TableSortLabel>
            </Tooltip>
          )}
        </TableCell>
      )
    }, this)
    let additionalIconCell = ''

    if (isErrorRowsExists || isAdditionalIconRowsExists) {
      additionalIconCell = (
        <TableCell key={`additional-${uniqueKey}`} className={classes.header}>
          {''}
        </TableCell>
      )
    }

    let leftScrollIconCell = ''
    if (enableScrollButtons) {
      leftScrollIconCell = (
        <TableCell
          key={`leftScroll-${uniqueKey}`}
          className={classes.header}
          style={{ backgroundColor: 'lightgray' }}
        >
          <IconButton
            id="leftScroll"
            onClick={(event) => {
              event.preventDefault()
              this.props.scrollLeftFun()
            }}
            style={{ color: 'black' }}
          >
            <ChevronLeftIcon sx={{ maxWidth: '34px' }} />
          </IconButton>
        </TableCell>
      )
    }

    let rightScrollIconCell = ''
    if (enableScrollButtons) {
      rightScrollIconCell = (
        <TableCell
          key={`rightScroll-${uniqueKey}`}
          className={classes.header}
          style={{ backgroundColor: 'lightgray' }}
        >
          <IconButton
            id="rightScroll"
            onClick={(event) => {
              event.preventDefault()
              this.props.scrollRightFun()
            }}
            style={{ color: 'black' }}
          >
            <ChevronRightIcon sx={{ maxWidth: '24px' }} />
          </IconButton>
        </TableCell>
      )
    }

    let checkboxCell
    if (checkBoxEnabled) {
      const isSeleted = (item) =>
        findIndex(this.props.selected, function (obj) {
          return (
            obj[uniqueIdentifierInData] === item[uniqueIdentifierInData] ||
            obj === item[uniqueIdentifierInData]
          )
        }) !== -1
      checkboxCell = (
        <TableCell
          key={`checkbox-${uniqueKey}`}
          padding="checkbox"
          className={classes.header}
        >
          <Checkbox
            id="tableHeaderCheckbox"
            color="primary"
            checked={
              checkAllCount ===
              (valueToCheckAgainst?.keyToCheckAgainst
                ? data.filter(
                    (item) =>
                      !checkIsEnabledRow(valueToCheckAgainst, item) ||
                      isSeleted(item)
                  ).length
                : data.filter((item) => isSeleted(item)).length)
            }
            onChange={(event) => {
              onCheckBoxHeaderChange(removeReactElements(data))
            }}
          />
        </TableCell>
      )
    }
    let finalStr = ''
    let TableHeadChildren = []
    if (checkBoxEnabled) {
      TableHeadChildren = [checkboxCell, ...children]
    } else {
      TableHeadChildren = [...children]
    }

    if (editEnabled || editDataColumn || deleteEnabled) {
      const editCell = (
        <TableCell key={'edit'} padding="checkbox" className={classes.header} />
      )
      TableHeadChildren = [...TableHeadChildren, editCell]
    }

    if (clickableRowButton) {
      const clickableRowCell = (
        <TableCell key={'open'} padding="checkbox" className={classes.header} />
      )
      TableHeadChildren = [...TableHeadChildren, clickableRowCell]
    }
    if (moreOptions && moreOptions.length > 0) {
      const moreRowCell = (
        <TableCell key={'more'} className={classes.header}></TableCell>
      )
      TableHeadChildren = [...TableHeadChildren, moreRowCell]
    }

    finalStr = (
      <TableHead
        children={
          <TableRow key={`row_${uniqueKey}`}>
            {[
              leftScrollIconCell,
              additionalIconCell,
              ...TableHeadChildren,
              rightScrollIconCell,
            ]}
          </TableRow>
        }
      />
    )
    return finalStr
  }
}

FlexibleTableHead.defaultProps = {
  columnData: [],
  data: [],
  editEnabled: false,
  deleteEnabled: false,
  editDataColumn: false,
  rowCount: 0,
  checkBoxEnabled: false,
  onCheckBoxHeaderChange: () => {},
  onSortSelectedChange: () => {},
  valueToCheckAgainst: [],
}

FlexibleTableHead.propTypes = {
  columnData: propTypes.array,
  disableTableSorting: propTypes.bool,
  data: propTypes.array,
  editEnabled: propTypes.bool,
  deleteEnabled: propTypes.bool,
  editDataColumn: propTypes.bool,
  rowCount: propTypes.number,
  checkBoxEnabled: propTypes.bool,
  onCheckBoxHeaderChange: propTypes.func,
  onSortSelectedChange: propTypes.func,
  valueToCheckAgainst: propTypes.array,
  moreOptions: propTypes.array,
}

export default FlexibleTableHead
