import { SCENIC, PROPS, COMMENTS } from './Tabs'

export const SCENIC_TAB_LABEL = SCENIC
export const PROPS_TAB_LABEL = PROPS
export const COMMENTS_TAB_LABEL = COMMENTS
export const ITME_TAB_LABEL = 'ITEM'
export const DETAILS_TAB_LABEL = 'SAMPLE'
export const MARKDOWN_TAB_LABEL = 'MARKDOWN'
export const HISTORY_TAB_LABEL = 'HISTORY'
export const defaultTabsList = [
  ITME_TAB_LABEL,
  DETAILS_TAB_LABEL,
  MARKDOWN_TAB_LABEL,
  HISTORY_TAB_LABEL,
]
const urlParams = new URLSearchParams(window.location.search)
const vendorCommentsFlag = urlParams.get('vendorcomments')

export const dataTabLabelsShipped =
  vendorCommentsFlag === 'true'
    ? [...defaultTabsList, COMMENTS_TAB_LABEL]
    : [...defaultTabsList]
export const dataTabLabelsShippedWithProps = [
  PROPS_TAB_LABEL,
  ...defaultTabsList,
]

export const dataTabLabelsShippedWithScenic = [
  SCENIC_TAB_LABEL,
  HISTORY_TAB_LABEL,
]
export const dataTabLabelsShippedVendor =
  vendorCommentsFlag === 'true'
    ? [ITME_TAB_LABEL, DETAILS_TAB_LABEL, COMMENTS_TAB_LABEL]
    : [ITME_TAB_LABEL, DETAILS_TAB_LABEL]

export const sampleTabDataKeyLabels = [
  { key: 'sampleImageDPURLView', label: 'Image' },
  { key: 'pegasusId', label: 'Pegasus ID' },
  { key: 'sampleStatus', label: 'Sample Status' },
  { key: 'location', label: 'Sample Current Location' },
  { key: 'projectName', label: 'Project' },
  { key: 'projectStartDate', label: 'Shoot Date' },
  { key: 'projects', label: 'Associated Projects' },
  { key: 'collections', label: 'Collections' },
  { key: 'createDate', label: 'Sample Ordered Date' },
  { key: 'orderNum', label: 'Order #' },
  { key: 'userId', label: 'Ordered By' },
  { key: 'receivedDate', label: 'Sample Received Date' },
  { key: 'studioNotes', label: 'Studio Notes', isEditable: true },
  { key: 'warehouseNotes', label: 'Warehouse Notes', isEditable: true },
  { key: 'trackingNum', label: 'Vendor Shipment Tracking #' },
  { key: 'carrier', label: 'Vendor Shipment Carrier' },
  { key: 'vendorEmail', label: 'Vendor Email Addresses' },
  { key: 'shipmentId', label: 'Vendor Shipment #' },
  { key: 'shipDate', label: 'Vendor Ship Date' },
  { key: 'shipToDestination', label: 'Vendor Shipment Destination' },
]

/** propsTabDataKeyLabels used for ADD PROPS*/
export const propsTabDataKeyLabels = [
  {
    key: 'propDescription',
    label: 'Description',
    mandatory: false,
    apiName: 'prop_description',
  },
  {
    key: 'propMaterial',
    label: 'Materials',
    isEditable: true,
    editType: 'dropDown',
    autoEdit: true,
    mandatory: false,
    apiName: 'material_id',
  },
  {
    key: 'propCategory',
    label: 'Category',
    isEditable: true,
    editType: 'dropDown',
    autoEdit: true,
    mandatory: true,
    apiName: 'category_id',
  },
  {
    key: 'propColorFamily',
    label: 'Color Family',
    isEditable: true,
    editType: 'dropDown',
    autoEdit: true,
    mandatory: false,
    apiName: 'color_id',
  },
  {
    key: 'propSubcategory',
    label: 'Sub Category',
    isEditable: true,
    editType: 'dropDown',
    autoEdit: true,
    mandatory: false,
    apiName: 'sub_category_id',
  },
  {
    key: 'propCost',
    label: 'Price($)',
    mandatory: false,
    apiName: 'prop_cost',
  },

  {
    key: 'propSource',
    label: 'Source',
    isEditable: true,
    editType: 'dropDown',
    autoEdit: true,
    mandatory: false,
    apiName: 'source_id',
  },
  { key: 'propSize', label: 'Size', apiName: 'prop_size' },
  {
    key: 'propBrand',
    label: 'Target Brand',
    isEditable: true,
    editType: 'dropDown',
    apiName: 'target_brand',
  },
  {
    key: 'studioNotes',
    label: 'Notes',
    apiName: 'prop_studio_notes',
    isEditable: true,
  },
  {
    key: 'propBundled',
    label: 'Bundled',
    isEditable: true,
    editType: 'toggle',
    apiName: 'is_bundle',
  },
]
/** scenicTabDataKeyLabels used for ADD SCENIC*/
export const scenicTabDataKeyLabels = [
  {
    key: 'scenicDescription',
    label: 'Scenic Asset Description',
    mandatory: true,
    apiName: 'description',
  },
  {
    key: 'scenicSource',
    label: 'Source',
    isEditable: true,
    editType: 'dropDown',
    autoEdit: true,
    mandatory: true,
    apiName: 'source_id',
  },
  {
    key: 'scenicCategory',
    label: 'Category',
    isEditable: true,
    editType: 'dropDown',
    autoEdit: true,
    mandatory: true,
    apiName: 'category_id',
  },
  {
    key: 'scenicBrand',
    label: 'Target Brand',
    isEditable: true,
    editType: 'dropDown',
    apiName: 'target_brand',
  },
  {
    key: 'scenicSubcategory',
    label: 'Sub Category',
    isEditable: true,
    editType: 'dropDown',
    autoEdit: true,
    mandatory: true,
    apiName: 'sub_category_id',
  },
  {
    key: 'scenicCost',
    label: 'Price($)',
    mandatory: true,
    apiName: 'scenic_cost',
  },
  {
    key: 'scenicColorFamily',
    label: 'Color Family',
    isEditable: true,
    editType: 'dropDown',
    autoEdit: true,
    mandatory: false,
    apiName: 'color_ids',
  },
  {
    key: 'scenicMaterial',
    label: 'Materials',
    isEditable: true,
    editType: 'dropDown',
    autoEdit: true,
    mandatory: false,
    apiName: 'material_id',
  },
  {
    key: 'scenicNotes',
    label: 'Scenic Asset Notes',
    apiName: 'asset_notes',
  },
  {
    key: 'scenicSize',
    label: 'Size',
    apiName: 'scenic_size',
    mandatory: false,
  },

  {
    key: 'scenicBundled',
    label: 'Bundled',
    isEditable: true,
    editType: 'toggle',
    apiName: 'is_bundle',
  },
]

export const editPropsTabDataKeyLabels = [
  ...propsTabDataKeyLabels,
  {
    key: 'pegasus_id',
    label: 'pegasus_id',
    isEditable: true,
    editType: 'dropDown',
    autoEdit: true,
    mandatory: false,
    apiName: 'pegasus_id',
  },
]
export const editScenicTabDataKeyLabels = [
  ...scenicTabDataKeyLabels,
  {
    key: 'pegasus_id',
    label: 'pegasus_id',
    isEditable: true,
    editType: 'dropDown',
    autoEdit: true,
    mandatory: false,
    apiName: 'pegasus_id',
  },
]
/** propsTabDataKeyLabelsView used for SAMPLE DETAIL CARD ON SEARCH INVENTORY*/
export const propsTabDataKeyLabelsView = [
  { key: 'sampleImageDPURL', label: 'Image' },
  { key: 'pegasusId', label: 'Pegasus ID' },
  {
    key: 'propCategoryName',
    label: 'Category',
  },
  {
    key: 'propSubcategoryName',
    label: 'Sub Category',
  },
  { key: 'propDescription', label: 'Description' },
  {
    key: 'propColorFamilyName',
    label: 'Color Family',
  },
  {
    key: 'propMaterialName',
    label: 'Materials',
  },
  { key: 'propCost', label: 'Price($)' },
  {
    key: 'propSourceName',
    label: 'Source',
  },
  { key: 'targetBrand', label: 'Target Brand' },
  { key: 'propBundledView', label: 'Bundled' },
  { key: 'propSizeView', label: 'Size' },
  { key: 'propBundleQuantity', label: 'Bundle Items #' },
  // { key: 'notes', label: 'Notes', isEditable: true },
  { key: 'studioNotes', label: 'Notes', isEditable: true },
  { key: 'propRetailer', label: 'Retailer' },
  { key: 'propSpecialist', label: 'Props Specialist' },
  { key: 'shipmentCount', label: 'Shipment Count' },
]

/** scenicTabDataKeyLabelsView used for SAMPLE DETAIL CARD*/
export const scenicTabDataKeyLabelsView = [
  { key: 'sampleImageDPURL', label: 'Image' },
  { key: 'pegasusId', label: 'Pegasus ID' },
  {
    key: 'scenicCategoryName',
    label: 'Category',
  },
  {
    key: 'scenicSubcategoryName',
    label: 'Sub Category',
  },
  { key: 'scenicDescription', label: 'Description' },
  {
    key: 'scenicColorFamilyName',
    label: 'Color Family',
  },
  {
    key: 'scenicRoomNames',
    label: 'Scenic Room Names',
  },
  {
    key: 'scenicMaterialName',
    label: 'Materials',
  },
  {
    key: 'scenicSourceName',
    label: 'Source',
  },
  { key: 'scenicCost', label: 'Price' },
  { key: 'targetBrand', label: 'Target Brand' },
  { key: 'scenicBundledView', label: 'Bundled' },
  { key: 'scenicSizeView', label: 'Size' },
  // { key: 'scenicWeight', label: 'Weight' },
  // { key: 'scenicBundleQuantity', label: 'Bundle Items #' },
  { key: 'scenicNotes', label: 'Scenic Asset Notes' },
  // { key: 'shipmentCount', label: 'Shipment Count' },
]

export const itemTabDataKeyLabels = [
  { key: 'imageUrl', label: 'Image' },
  { key: 'tcin', label: 'TCIN' },
  { key: 'parentTcins', label: 'Parent TCIN' },
  { key: 'dpci', label: 'DPCI' },
  { key: 'description', label: 'Description' },
  { key: 'color', label: 'Color' },
  { key: 'size', label: 'Size' },
  { key: 'newLaunchDate', label: 'Launch Date' },
  { key: 'vendorName', label: 'Vendor Name' },
  { key: 'vendorId', label: 'Vendor ID' },
  { key: 'departmentName', label: 'Department Name' },
  { key: 'departmentId', label: 'Department ID' },
  { key: 'className', label: 'Class Name' },
  { key: 'classId', label: 'Class ID' },
  { key: 'subClassName', label: 'Sub Class' },
  { key: 'subClassId', label: 'Sub Class ID' },
  { key: 'lifecycleState', label: 'Lifecycle Status' },
  { key: 'manufacturerStyle', label: 'Manufacturer Style' },
  { key: 'relationshipType', label: 'Relationship Type' },
  { key: 'samplePreviousLaunchDate', label: 'Previous Launch Date' },
  { key: 'barcodeNumber', label: 'Barcode Number' },
  { key: 'countryOfOrigin', label: 'Country of Origin' },
  { key: 'manufacturerBrand', label: 'Manufacturer Brand' },
  { key: 'location', label: 'Current Location' },
  { key: 'dueDate', label: 'Due Date' },
]

export const markdownTabDataKeyLabels = [
  { key: 'intendedSellingChannels', label: 'Intended Selling Channels' },
  { key: 'digital_markdown_date', label: 'Digital Clearance Start Date' },
  { key: 'store_markdown_date', label: 'Store Clearance Start Date' },
  { key: 'receivedDate', label: 'Sample Received Date' },
  { key: 'disposition_date', label: 'Disposition Date' },
  { key: 'dispositionNotes', label: 'Disposition Notes', isEditable: true },
  {
    key: 'disposition_override_date',
    label: 'Disposition Override Date',
    isEditable: true,
    editType: 'datePicker',
  },
  { key: 'lastShipDate', label: 'Last Ship Date' },
]
