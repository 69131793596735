import React, { useEffect, useTransition } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  isEmpty,
  first,
  mapValues,
  mapKeys,
  get,
  isUndefined,
  isNull,
  groupBy,
} from 'lodash'
/**MUI COMPONENTS */
import Grid from '@mui/material/Grid'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
/**ICONS */
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos'
import PrintIcon from '@mui/icons-material/Print'
/**APP COMPONENTS */
import DialogBox from '../../components/DialogBox/DialogBox'
import AddPropHeader from './AddPropHeader'
import PropsRenderTabs from './PropsRenderTabs'
/**ACTIONS */
import {
  getData,
  addAProp,
  clearSearchData,
  setAddPropData,
  editProps,
  editScenics,
  addScenic,
} from '../../store/searchInventory/actionCreator'
import { getData as itemGetData } from '../../store/search/actionCreator'
import { showNotification } from '../../store/notification/actionCreator'
import {
  callFetchDataSuccess,
  addPropClearData,
  uploadTCINImage,
  resetAddPropData,
  uploadDuplicateImage,
  setAddPropState,
} from '../../store/addProp/actionCreator'
import {
  getBarcodesProps,
  getBarcodesScenic,
} from '../../store/shipmentBarcode/actionCreator'
/**CONSTANTS */
import { paramsFromFilters, regexExpressions } from '../AddFilters/constants'
import {
  defaultTabsList as OTHER_TAB_LABEL,
  editPropsTabDataKeyLabels,
  propsTabDataKeyLabels,
  scenicTabDataKeyLabels,
  editScenicTabDataKeyLabels,
} from '../../enums/tableTabs'
import { makeTabTables } from '../../util/Table'
import { getSampleIDPrefix, isHavingPrefix } from 'util/CommonUtils'
import withHOCs from 'util/withHocs'
import { PROPS, SAMPLE, SCENIC } from '../../enums/Tabs'
import { Box, SwipeableDrawer } from '@mui/material'
import AddPropImage from './AddPropImage'
import CircularPageLoader from '../../components/Loader/CircularPageLoader'

const styles = (theme) => ({
  RadioButton: {},
  root: {
    width: '100%',
    padding: `0px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`,
  },
  editPageHeader: {
    padding: `0px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`,
    paddingTop: '30px !important',
    fontWeight: 'bold',
  },
  editPageFields: {
    fontWeight: 'bold !important',
  },
  rootHeader: {
    width: '100%',
    height: '50px',
    position: 'relative',
  },
  defaultButton: {
    maxWidth: '250px !important',
    width: 'auto !important',
    margin: '0px 8px !important',
  },
  textField: {
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 'normal',
    fontSize: 14,
    borderRadius: 4,
    width: 250,
  },
  typographyGreenHead: {
    marginTop: '10px !important',
    backgroundColor: '#fff',
  },
  typographyGreen: {
    color: 'green',
    fontSize: 18,
    textAlign: 'center',
  },
  renderStepCls: { marginTop: '50px !important' },
  buttonRight: { marginRight: '10px !important' },
  settingsPrimaryText: {
    fontSize: '14px',
    color: '#666',
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
})

// TODO - NEEDS TO MOVE UTILS
const propSteps = [
  'Duplicate/Convert to Prop',
  'Enter Prop Details',
  'Add Images',
]
const scenicSteps = ['Duplicate Scenic', 'Enter Scenic Details', 'Add Images']
const selKeyMapKey = {
  'Pegasus ID': 'pegasusId',
  'Prop ID': 'propId',
  TCIN: 'tcin',
}

const AddProp = (props) => {
  const { classes = {} } = props
  const { selectedTab = PROPS } = props
  const lanId = get(props, 'auth.session.userInfo.lanId')
  const PROPS_TAB_LABEL = PROPS
  const SCENIC_TAB_LABEL = SCENIC
  const [dataTableTabs, setDataTableTabs] = React.useState(
    selectedTab === SCENIC
      ? [SCENIC_TAB_LABEL]
      : [PROPS_TAB_LABEL, ...OTHER_TAB_LABEL]
  )

  const [isPending, startTransition] = useTransition()
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set())
  const [createPropBy, setCreatePropBy] = React.useState(
    selectedTab === SCENIC ? 'DUPLICATE_SCENIC' : 'DUPLICATE_PROP'
  )
  const [createPropByKey, setCreatePropByKey] = React.useState(
    selectedTab !== SCENIC ? 'Prop ID' : 'Scenic ID'
  )
  const [createPropByKeyValue, setCreatePropByKeyValue] = React.useState('')
  const [createPropByValue, setCreatePropByValue] = React.useState('')
  const [isValidKeyValue, setIsValidKeyValue] = React.useState(true)
  const [searchDataNew, setSearchDataNew] = React.useState({})
  const [disableAddProp, setDisableAddProp] = React.useState(true)
  const [finalReqPayloadObj, setFinalReqPayloadObj] = React.useState({})
  const [addPropsFormData, setAddPropsFormData] = React.useState({})
  const [showAlertOnAdd, setShowAlertOnAdd] = React.useState(false)
  const [alertMessageOnAdd, setAlertMessageOnAdd] = React.useState('')
  const [addResponse, setAddResponse] = React.useState(false)
  const [showPropsImage, setShowPropsImage] = React.useState(false)
  const [showPropsImageDataId, setShowPropsImageDataId] = React.useState({})
  const [editPropSubmit, setEditPropSubmit] = React.useState(false)
  const [editScenicSubmit, setEditScenicSubmit] = React.useState(false)
  let { type = '', id = '' } = useParams()
  const DUPLICATE_PROP = 'duplicateProp'
  const CONVERT_TO_PROPS = 'convertToProp'
  const EDIT_PROP = 'editProp'
  const EDIT_SCENIC = 'editScenic'
  const DUPLICATE_SCENIC = 'duplicateScenic'
  const steps = selectedTab !== SCENIC ? propSteps : scenicSteps
  /**
   * Filling the search ID and moving to second step of prop's creation
   * Only use when URL params are present
   */
  useEffect(() => {
    if (!id || !type) {
      return
    }

    switch (type) {
      case DUPLICATE_PROP:
      case EDIT_PROP:
        startTransition(() => {
          setCreatePropBy('DUPLICATE_PROP')
          setCreatePropByKey('Prop ID')
          checkIsValidValue('Filemaker ID', id)
          setCreatePropByValue(id)
          setCreatePropByKeyValue(id)
        })
        break
      case CONVERT_TO_PROPS:
        startTransition(() => {
          setCreatePropBy('CONVERT_SAMPLE_TO_PROP')
          setCreatePropByKey('Pegasus ID')
          checkIsValidValue('Pegasus ID', id)
          setCreatePropByValue(id)
          setCreatePropByKeyValue(id)
        })
        break
      case DUPLICATE_SCENIC:
      case EDIT_SCENIC:
        startTransition(() => {
          setCreatePropBy('DUPLICATE_SCENIC')
          setCreatePropByKey('Scenic ID')
          checkIsValidValue('Filemaker ID', id)
          setCreatePropByValue(id)
          setCreatePropByKeyValue(id)
        })
        break
      default:
        break
    }
  }, [id, type])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { searchId = '' } = useParams()
  const {
    item: searchData = {},
    addPropData = {},
    filterNFListProp,
    invalidData,
    uploadFiles: storeUploadFiles = [],
    fetchDataRequestPending,
    propState,
    searchTabValue,
    addAPropAPIStatus,
  } = useSelector(
    ({
      searchInventory: {
        addPropData: addPropDataCopy = [],
        filterNFListProp = {},
        fetchDataRequestPending = false,
        addAPropAPIStatus = false,
      },
      addProp: {
        numSelected = Number(0),
        selectedData = [],
        data = [],
        uploadFiles = [],
        propState = '',
      },
      addFilters: { searchTabValue = '' },
      search: { invalidData = [] } = {},
    }) => {
      return {
        item: first(data) || {},
        numSelected,
        selectedData,
        addPropData: first(addPropDataCopy) || {},
        filterNFListProp,
        invalidData,
        uploadFiles,
        fetchDataRequestPending,
        propState,
        searchTabValue,
        addAPropAPIStatus,
      }
    },
    shallowEqual
  )

  const useHasChanged = (val) => {
    const prevVal = usePrevious(val)
    return (
      (!isEmpty(prevVal) && prevVal !== val) ||
      (!isEmpty(val) && prevVal !== val)
    )
  }

  const usePrevious = (value) => {
    const ref = React.useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevShowPropsImage = usePrevious(showPropsImage)
  const isUploadFilesChanged = useHasChanged(storeUploadFiles)

  useEffect(() => {
    const { sampleId = '' } = addPropData
    const storeUploadFilesMok = [...storeUploadFiles]
    const currentPropImages = storeUploadFilesMok?.filter(
      (obj) => obj.pegasusId === sampleId
    )
    const uploadedFilesByStatus = groupBy(currentPropImages || [], 'status')

    if (
      currentPropImages?.length &&
      isUploadFilesChanged &&
      Object.keys(uploadedFilesByStatus).length &&
      Object.keys(uploadedFilesByStatus).indexOf('inprogress') === -1
    ) {
      const pegasusId = String(sampleId || '')
      selectedTab === SCENIC
        ? setCreatePropByKey('Scenic ID')
        : setCreatePropByKey('Prop ID')
      checkIsValidValue('Filemaker ID', pegasusId)
      setCreatePropByValue(pegasusId)
      setCreatePropByKeyValue(String(pegasusId || ''))
      selectedTab === SCENIC
        ? setDataTableTabs([SCENIC_TAB_LABEL])
        : setDataTableTabs([PROPS_TAB_LABEL, ...OTHER_TAB_LABEL])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeUploadFiles])

  useEffect(() => {
    if (!isEmpty(searchId)) {
      let createBy =
        selectedTab !== SCENIC ? 'DUPLICATE_PROP' : 'DUPLICATE_SCENIC'
      let filterId = selectedTab !== PROPS ? 'Prop ID' : 'Scenic ID'
      setCreatePropBy(createBy)
      setCreatePropByKey(filterId)
      checkIsValidValue('Filemaker ID', searchId)
      setCreatePropByValue(searchId)
    }
    return function cleanup() {
      resetAddProp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isEmpty(searchId) && isValidKeyValue && !isEmpty(createPropByValue)) {
      setCreatePropByKeyValue(searchId)
      setActiveStep(1)
    }
  }, [createPropByValue, isValidKeyValue, searchId])

  useEffect(() => {
    if (isPending) {
      return
    }
    if (Object.entries(searchData).length > 0) {
      const { sample_status = '' } = searchData
      if (!isEmpty(sample_status) && sample_status !== 'SAMPLE_RECEIVED') {
        dispatch(
          showNotification(
            true,
            `Invalid sample status-${sample_status}.Only SAMPLE_RECEIVED samples are allowed to convert to PROPS`
          )
        )
        setCreatePropByKeyValue('')
      } else {
        setSearchDataNew(searchData)
        propState !== 'IMAGEFLYOUTCLOSE' && propState !== 'UPLOADIMAGESUCCESS'
          ? reviewButtonOnClick('forward')
          : reviewButtonOnClick('2')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData])

  useEffect(() => {
    if (
      (createPropByKeyValue?.length > 0 &&
        !Object.entries(searchData)?.length &&
        activeStep === Number(0)) ||
      invalidData?.length
    ) {
      let errorMessage = ''
      if (createPropByKey === 'TCIN' && invalidData?.length > 0) {
        errorMessage = `${createPropByKeyValue} invalid TCIN/DPCI/UPC`
      }
      if (createPropByKey !== 'TCIN' && !Object.entries(searchData)?.length) {
        errorMessage = `${createPropByKeyValue} Pegasus ID /Filemaker ID not found`
      }
      errorMessage && dispatch(showNotification(true, errorMessage))
      setCreatePropByKeyValue('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterNFListProp, invalidData])

  const successCallBack = () => {
    const createPropByKeyName =
      selKeyMapKey[createPropByKey] || 'pegasusId' || ''
    dispatch(callFetchDataSuccess(createPropByKeyName))
  }

  const callSearchAPI = (props = {}) => {
    const { callBack = null, pegasusId = '', sample_type = '' } = props
    const sampleId = pegasusId ? getSampleIDPrefix(pegasusId, PROPS) : ''
    let filters = { include_filters: {} }

    const searchValue = !isEmpty(sampleId) ? sampleId : createPropByKeyValue
    const isHavingPegasusPrefix = isHavingPrefix(searchValue)
    const createPropByKeyNew =
      isHavingPegasusPrefix ||
      (!isHavingPegasusPrefix &&
        !isNaN(createPropByKeyValue) &&
        createPropByKeyValue?.length >= 8) ||
      sample_type === 'Prop ID'
        ? 'Pegasus ID'
        : createPropByKey
    filters['include_filters'][createPropByKeyNew] = {}
    filters['include_filters'][createPropByKeyNew].chipArray = [searchValue]
    filters['include_filters'][createPropByKeyNew].unrecognizedValues = []
    filters['include_filters'][createPropByKeyNew].textFieldValue = searchValue
    filters['include_filters'][createPropByKeyNew].islatest = true
    dispatch(
      getData(
        paramsFromFilters(filters, 1, 100, '', {}, '', '', ''),
        !isNull(callBack) ? callBack : successCallBack,
        () => {},
        (selectedTab === PROPS && createPropByKey === 'Prop ID') ||
          sample_type === 'Prop ID'
          ? PROPS
          : createPropByKey === 'Scenic ID' || sample_type === 'Scenic ID'
            ? SCENIC
            : SAMPLE
      )
    )
  }

  useEffect(() => {
    const { sampleId = '' } = addPropData
    switch (propState) {
      case 'EXISTINGDATASEARCH':
      case 'IMAGEFLYOUTCLOSE':
        callSearchAPI()
        break
      case 'PROPCREATEDPARTIALLY':
      case 'UPLOADTCINIMAGESUCCESS':
        selectedTab !== SCENIC
          ? setDataTableTabs([PROPS_TAB_LABEL, ...OTHER_TAB_LABEL])
          : setDataTableTabs([SCENIC_TAB_LABEL])
        callSearchAPI({
          callBack: () => {
            dispatch(callFetchDataSuccess())
          },
          pegasusId: sampleId,
          sample_type: selectedTab !== SCENIC ? 'Prop ID' : 'Scenic ID',
        })
        break
      case 'SCENICCREATEDPARTIALLY':
        setDataTableTabs([SCENIC_TAB_LABEL])
        callSearchAPI({
          callBack: () => {
            dispatch(callFetchDataSuccess())
          },
          pegasusId: sampleId,
          sample_type: 'Scenic ID',
        })
        break
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propState])

  /**
   * Called based on the option selected to create a prop in page one
   * createPropByKeyValue - Input value - sample/prop/item id
   * createPropByKey - Selected option - Duplicate prop/Sample to prop/TCIN
   * callFrom - From which search api called and skips one level forward
   */

  useEffect(() => {
    let activeStepCopy = Number(activeStep)
    if (createPropByKeyValue && activeStepCopy === Number(0)) {
      if (createPropByKey === 'TCIN') {
        dispatch(itemGetData([createPropByKeyValue], successCallBack))
      } else {
        dispatch(setAddPropState('EXISTINGDATASEARCH'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPropByKeyValue, dispatch])

  /**
   * Triggers on add images flyout close to get latest images data from search api
   */
  useEffect(() => {
    if (!isUndefined(prevShowPropsImage)) {
      if (!showPropsImage && showPropsImage !== prevShowPropsImage) {
        dispatch(setAddPropState('IMAGEFLYOUTCLOSE'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPropsImage, dispatch])

  const isStepSkipped = (step) => {
    return skipped.has(step)
  }

  const addImageCallBack = ({ error = '', ...rest }) => {
    if (!isEmpty(error)) {
      dispatch(showNotification(true, error))
    }
  }

  /**
   * @callback: Triggers on Add Prop api call success/failure
   */
  const addAPropCallBack = ({ callType = '', message = '', sampleId = '' }) => {
    if (callType === 'failure') {
      dispatch(showNotification(true, message))
    } else {
      const { props = [], scenicAssets = [] } = finalReqPayloadObj || {}
      const payloadObj = first(props) || first(scenicAssets) || {}
      const {
        propTcinImage = false,
        imagePopupUrl = '',
        propDuplicateImage = false,
        sampleImagePopupUrl = [],
        tcin = '',
      } = payloadObj
      if (propDuplicateImage) {
        dispatch(
          uploadDuplicateImage({
            imageUrl: sampleImagePopupUrl,
            tcin,
            pegasusId: sampleId,
            callBack: addImageCallBack,
            sampleType: 'PROP',
          })
        )
      } else if (propTcinImage) {
        dispatch(
          uploadTCINImage({
            imageUrl: imagePopupUrl,
            tcin,
            pegasusId: sampleId,
            callBack: addImageCallBack,
            sampleType: 'PROP',
          })
        )
      } else {
        selectedTab === SCENIC
          ? dispatch(setAddPropState('SCENICCREATEDPARTIALLY'))
          : dispatch(setAddPropState('PROPCREATEDPARTIALLY'))
      }
    }
  }

  /**
   * Triggers ADD PROP api call with valid form data
   */
  useEffect(() => {
    if (Object.entries(finalReqPayloadObj).length > 0) {
      selectedTab === SCENIC
        ? editScenicSubmit
          ? dispatch(editScenics(finalReqPayloadObj, addAPropCallBack))
          : dispatch(addScenic(finalReqPayloadObj, addAPropCallBack))
        : !editPropSubmit &&
          dispatch(addAProp(finalReqPayloadObj, addAPropCallBack))
    }
    return function cleanup() {
      setShowAlertOnAdd(false)
      setAlertMessageOnAdd('')
      setAddResponse(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, finalReqPayloadObj])

  useEffect(() => {
    if (addAPropAPIStatus === 'pending') {
      setDisableAddProp(true)
    } else if (addAPropAPIStatus === 'success') {
      setDisableAddProp(false)
    }
  }, [addAPropAPIStatus])

  useEffect(() => {
    Object.entries(finalReqPayloadObj).length > 0 &&
      editPropSubmit &&
      dispatch(editProps(finalReqPayloadObj, addAPropCallBack))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPropSubmit])

  const submitAddPropForm = () => {
    const currentFilledColCount = Object.keys(addPropsFormData || {}).length
    if (currentFilledColCount < 5) {
      const message = `${'You have entered minimal data to create a Prop. Do you still want to proceed to create a Prop?'}`
      setShowAlertOnAdd(true)
      setAlertMessageOnAdd(message)
      setAddResponse(true)
    } else {
      submitAddPropFormConfirm()
    }
  }

  const submitEditPropForm = () => {
    setEditPropSubmit(true)
    submitAddPropFormConfirm()
  }
  const submitEditScenicForm = () => {
    setEditScenicSubmit(true)
    submitAddPropFormConfirm()
  }
  const closeAlert = (e) => {
    setShowAlertOnAdd(false)
    setAlertMessageOnAdd('')
    setAddResponse(false)
  }

  const submitAddPropFormConfirm = () => {
    const newFormFieldValues = mapValues(addPropsFormData, function (o, index) {
      let newValue = o?.value || o
      return newValue
    })

    const reqPayloadObj = mapKeys(newFormFieldValues, function (value, key) {
      const refObj =
        selectedTab === SCENIC
          ? type === EDIT_SCENIC
            ? editScenicTabDataKeyLabels.find((obj) => obj.key === key) || {}
            : scenicTabDataKeyLabels.find((obj) => obj.key === key) || {}
          : type === EDIT_PROP
            ? editPropsTabDataKeyLabels.find((obj) => obj.key === key)
            : propsTabDataKeyLabels.find((obj) => obj.key === key) || {}
      return refObj?.apiName || key
    })

    const finalReqPayloadObjScenic = {
      scenicAssets: [
        Object.assign({}, reqPayloadObj, {
          item_quantity: 1,
          item_details: null,
          user_id: lanId,
          createPropBy: createPropBy,
        }),
      ],
    }
    const finalReqPayloadObjProp = {
      props: [
        Object.assign({}, reqPayloadObj, {
          item_quantity: 1,
          item_details: null,
          user_id: lanId,
          createPropBy: createPropBy,
        }),
      ],
    }
    selectedTab === SCENIC
      ? setFinalReqPayloadObj(finalReqPayloadObjScenic)
      : setFinalReqPayloadObj(finalReqPayloadObjProp)
  }

  const reviewButtonOnClick = (stepType = 'forward') => {
    let activeStepCopy = Number(activeStep)
    if (stepType === 'backward' && activeStepCopy === Number(0)) {
      navigate(-1)
    } else if (stepType === 'skipped') {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values())
        newSkipped.add(activeStep)
        return newSkipped
      })
    } else {
      if (
        stepType === 'forward' &&
        activeStepCopy < steps.length &&
        activeStepCopy >= 0
      ) {
        activeStepCopy = Number(activeStepCopy) + Number(1)
      }
      if (stepType === 'backward' && activeStepCopy > Number(0)) {
        selectedTab !== SCENIC
          ? setDataTableTabs([PROPS_TAB_LABEL, ...OTHER_TAB_LABEL])
          : setDataTableTabs([...SCENIC_TAB_LABEL])
        activeStepCopy = Number(activeStepCopy) - Number(1)
      }
      if (stepType === 'backward' && activeStepCopy === Number(0)) {
        resetAddProp()
      }
      let newSkipped = skipped
      if (isStepSkipped(activeStepCopy)) {
        newSkipped = new Set(newSkipped.values())
        newSkipped.delete(activeStepCopy)
      }
      setActiveStep(activeStepCopy)
      setSkipped(newSkipped)
    }
  }

  const captureSelectedOption = ({
    createPropBy = 'DUPLICATE_PROP',
    propByKey,
  }) => {
    setCreatePropBy(createPropBy)
    setCreatePropByValue('')
    setIsValidKeyValue(false)
    setCreatePropByKey(propByKey)
    setCreatePropByKeyValue('')
  }

  const renderSecondStep = (createPropBy = '') => {
    return (
      <Grid container>
        <Grid container item xs={12} alignItems={'center'} spacing={2}>
          <Grid item xs={10}>
            {renderSectionByOption(createPropBy)}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const checkIsValidValue = (keyName = '', keyValue = '') => {
    const isValidStr = keyValue
      ? regexExpressions[keyName].test(keyValue?.trim())
      : false
    setIsValidKeyValue(isValidStr)
  }

  const checkIsValidValueTCIN = (keyValue = '') => {
    const isValidStr = keyValue
      ? regexExpressions['TCIN'].test(keyValue?.trim()) ||
        regexExpressions['DPCI'].test(keyValue?.trim()) ||
        regexExpressions['Barcode Number'].test(keyValue?.trim())
      : false
    setIsValidKeyValue(isValidStr)
  }

  const catchReturn = (event) => {
    if (event.key === 'Enter' && createPropByValue) {
      event.preventDefault()
      searchAndAdd()
    }
  }

  const renderSectionByOption = (createPropBy = 'DUPLICATE_PROP') => {
    switch (createPropBy) {
      case 'DUPLICATE_PROP': {
        return (
          <TextField
            id="outlined-basic"
            label="Prop ID To Duplicate"
            variant="outlined"
            className={classes.textField}
            type={'search'}
            inputProps={{
              maxLength: 100,
            }}
            value={createPropByValue}
            error={createPropByValue && !isValidKeyValue}
            onChange={(event) => {
              checkIsValidValue('Filemaker ID', event.target.value)
              setCreatePropByValue(event.target.value)
            }}
            onKeyPress={catchReturn}
          />
        )
      }
      case 'CONVERT_SAMPLE_TO_PROP': {
        return (
          <TextField
            id="outlined-basic"
            label="Pegasus ID To Convert"
            variant="outlined"
            type={'search'}
            value={createPropByValue}
            className={classes.textField}
            error={createPropByValue && !isValidKeyValue}
            onChange={(event) => {
              checkIsValidValue('Pegasus ID', event.target.value)
              setCreatePropByValue(event.target.value)
            }}
            onKeyPress={catchReturn}
          />
        )
      }
      case 'CONVERT_TCIN_TO_PROP': {
        return (
          <TextField
            id="outlined-basic"
            label="TCIN/DPCI/UPC To Add As Prop"
            variant="outlined"
            value={createPropByValue}
            className={classes.textField}
            type={'search'}
            error={createPropByValue && !isValidKeyValue}
            onChange={(event) => {
              checkIsValidValueTCIN(event.target.value)
              setCreatePropByValue(event.target.value)
            }}
            onKeyPress={catchReturn}
          />
        )
      }
      case 'DUPLICATE_SCENIC': {
        return (
          <TextField
            id="outlined-basic"
            label="Scenic ID To Duplicate"
            variant="outlined"
            className={classes.textField}
            type={'search'}
            inputProps={{
              maxLength: 100,
            }}
            value={createPropByValue}
            error={createPropByValue && !isValidKeyValue}
            onChange={(event) => {
              checkIsValidValue('Filemaker ID', event.target.value)
              setCreatePropByValue(event.target.value)
            }}
            onKeyPress={catchReturn}
          />
        )
      }
      default:
        return true
    }
  }

  const renderFirstStep = () => {
    return (
      <FormControl
        component="fieldset"
        className={classes.settingsPrimaryText}
        sx={{ width: '100%' }}
      >
        <fieldset>
          <legend>Choose An Option To Create Prop</legend>

          <AddPropHeader
            captureSelectedOption={captureSelectedOption}
            existCreatePropBy={createPropBy}
            selectedTab={selectedTab}
          />
          {renderSecondStep(createPropBy)}
        </fieldset>
      </FormControl>
    )
  }

  const toggleShowPropsImage = (itemObj = {}) => {
    setShowPropsImage(!showPropsImage)
    setShowPropsImageDataId(itemObj)
  }

  const makeTableStateCB = ({
    disableSubmit = true,
    formFieldValues = {},
    addImagesProps = {},
  }) => {
    if (Object.entries(addImagesProps).length) {
      const { itemObj = {} } = addImagesProps
      toggleShowPropsImage(itemObj)
    } else {
      setDisableAddProp(disableSubmit)
      setAddPropsFormData(formFieldValues)
    }
  }

  const resetAddProp = () => {
    selectedTab !== SCENIC
      ? setDataTableTabs([PROPS_TAB_LABEL, ...OTHER_TAB_LABEL])
      : setDataTableTabs([SCENIC_TAB_LABEL])
    setActiveStep(0)
    setSkipped(new Set())

    if (selectedTab !== SCENIC) {
      setCreatePropByKey('Prop ID')
      setCreatePropBy('DUPLICATE_PROP')
    } else {
      setCreatePropByKey('Scenic ID')
      setCreatePropBy('DUPLICATE_SCENIC')
    }
    setCreatePropByKeyValue('')
    setCreatePropByValue('')
    setIsValidKeyValue(true)
    setSearchDataNew({})
    setDisableAddProp(true)
    setFinalReqPayloadObj({})
    setAddPropsFormData({})
    setShowAlertOnAdd(false)
    setAlertMessageOnAdd('')
    setAddResponse(false)
    dispatch(resetAddPropData())
  }
  const onDoneComplete = async (backTo = '') => {
    await dispatch(clearSearchData())
    await dispatch(addPropClearData())
    await dispatch(setAddPropData([]))
    await resetAddProp()
    if (backTo !== 'addmore') {
      selectedTab !== SCENIC
        ? await navigate('/searchInventory/PROPS')
        : await navigate('/searchInventory/SCENIC')
    }
  }

  const addNewPropOnClick = async () => {
    await onDoneComplete('addmore')
    reviewButtonOnClick('skipped')
    selectedTab !== SCENIC
      ? setDataTableTabs([PROPS_TAB_LABEL])
      : setDataTableTabs([SCENIC_TAB_LABEL])
    selectedTab !== SCENIC
      ? setCreatePropBy('NEW_PROP')
      : setCreatePropBy('NEW_SCENIC')
  }

  const searchAndAdd = () => {
    setCreatePropByKeyValue(createPropByValue)
  }
  const buttonName =
    selectedTab !== SCENIC
      ? type === EDIT_PROP
        ? 'Update Prop'
        : ' CREATE PROP'
      : type === EDIT_SCENIC
        ? 'Update Scenic'
        : 'Add SCENIC'

  const renderPageControls = () => {
    switch (activeStep) {
      case Number(0): {
        return (
          <Button
            id="searchandaddprop"
            disabled={
              (createPropBy !== 'NEW_SCENIC' &&
                isEmpty(createPropByValue) &&
                createPropBy !== 'NEW_PROP' &&
                isEmpty(createPropByValue)) ||
              (createPropByValue && !isValidKeyValue)
            }
            onClick={
              createPropBy !== 'NEW_PROP'
                ? createPropBy !== 'NEW_SCENIC'
                  ? searchAndAdd
                  : addNewPropOnClick
                : addNewPropOnClick
            }
            variant={'contained'}
            className={classes.buttonRight}
          >
            Next
          </Button>
        )
      }
      case Number(1): {
        return (
          <Button
            id="addProps"
            onClick={() =>
              type === EDIT_PROP
                ? submitEditPropForm()
                : type === EDIT_SCENIC
                  ? submitEditScenicForm()
                  : submitAddPropForm()
            }
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            disabled={disableAddProp}
          >
            {buttonName}
          </Button>
        )
      }
      case Number(2):
      case Number(3): {
        return (
          <Button
            color="primary"
            id="backtosearch"
            onClick={() => {
              onDoneComplete()
            }}
            variant="outlined"
            className={classes.defaultButton}
          >
            <ArrowBackIcon className={classes.buttonRight} />
            {'Done, Back To Search'}
          </Button>
        )
      }
      default:
        break
    }
  }
  const renderStepperPages = () => {
    const tableData = searchDataNew ?? {}
    const headerTitle =
      selectedTab !== SCENIC ? ' Enter Prop Details' : ' Enter Scenic Details'
    const dataType = selectedTab !== SCENIC ? 'ADDPROP' : 'ADDSCENIC'
    switch (activeStep) {
      case Number(0): {
        return renderFirstStep()
      }
      case Number(1): {
        return (
          <div>
            {
              <>
                <Grid
                  container
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  style={{ height: 45 }}
                >
                  <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom>
                      {headerTitle}
                    </Typography>
                  </Grid>
                </Grid>
                <PropsRenderTabs
                  key={searchDataNew ? 'dataExists' : 'noData'}
                  data={makeTabTables(
                    tableData,
                    classes,
                    dataTableTabs,
                    makeTableStateCB,
                    [],
                    dataType,
                    type || createPropBy,
                    selectedTab
                  )}
                  dataTableTabs={dataTableTabs}
                />
              </>
            }
          </div>
        )
      }
      case Number(2): {
        return (
          <Grid container>
            <Grid item xs={12}>
              <PropsRenderTabs
                key={addPropData ? 'dataExists' : 'noData'}
                data={makeTabTables(
                  tableData,
                  classes,
                  dataTableTabs,
                  makeTableStateCB,
                  [],
                  selectedTab !== SCENIC ? 'ADDPROPVIEW' : 'ADDSCENICVIEW'
                )}
                dataTableTabs={dataTableTabs}
              />
            </Grid>
          </Grid>
        )
      }
      default:
        break
    }
  }
  const renderEditMorePageControls = () => {
    const renderSearchPage = searchTabValue === '' ? PROPS : searchTabValue

    let cmp = ''
    if (activeStep <= Number(1)) {
      cmp = (
        <Button
          color={activeStep === steps.length - 1 ? 'primary' : 'inherit'}
          onClick={() => {
            if (activeStep === steps.length - 1) {
              onDoneComplete('addmore')
            } else {
              navigate(`/searchInventory/${renderSearchPage}`)
              dispatch(clearSearchData())
              dispatch(addPropClearData())
              setSearchDataNew({})
            }
          }}
          variant={activeStep === steps.length - 1 ? 'contained' : 'outlined'}
          sx={{ mr: 3 }}
        >
          Back
        </Button>
      )
    } else {
      cmp = ''
    }
    return cmp
  }

  const renderMorePageControls = () => {
    let morePropsMessage =
      selectedTab !== SCENIC ? 'Add More Props' : 'Add More Scenic'
    return (
      <>
        <Button
          color={activeStep === steps.length - 1 ? 'primary' : 'inherit'}
          onClick={() => {
            if (activeStep === steps.length - 1) {
              onDoneComplete('addmore')
            } else {
              reviewButtonOnClick('backward')
              dispatch(clearSearchData())
              dispatch(addPropClearData())
              setSearchDataNew({})
            }
          }}
          variant={activeStep === steps.length - 1 ? 'contained' : 'outlined'}
          sx={{ mr: 3 }}
        >
          {activeStep === steps.length - 1 && (
            <AddToPhotosIcon className={classes.buttonRight} />
          )}
          {activeStep === steps.length - 1 ? morePropsMessage : 'Back'}
        </Button>
      </>
    )
  }
  const handleGenerateBarcodeProps = (format = '') => {
    const { pegasusId = '' } = addPropData
    const sampleList = [
      {
        pegasusId: pegasusId,
        shipToLocToPrint: '',
      },
    ]
    selectedTab === SCENIC
      ? dispatch(
          getBarcodesScenic(sampleList, (barcodes) => {
            const file = new Blob([barcodes], { type: 'application/pdf' })
            const fileURL = URL.createObjectURL(file)
            window.open(fileURL).print()
          })
        )
      : dispatch(
          getBarcodesProps(
            sampleList,
            (barcodes) => {
              const file = new Blob([barcodes], { type: 'application/pdf' })
              const fileURL = URL.createObjectURL(file)
              window.open(fileURL).print()
            },
            'MULTILINE',
            format
          )
        )
  }
  const renderPrintlabel = () => {
    let returnCmp = ''
    returnCmp =
      selectedTab === SCENIC ? (
        <>
          {activeStep === steps.length - 1 && (
            <Button
              color="primary"
              onClick={() => {
                handleGenerateBarcodeProps()
              }}
              variant={'contained'}
              sx={{ mr: 3 }}
              startIcon={<PrintIcon />}
            >
              Print Label
            </Button>
          )}
        </>
      ) : (
        <>
          {activeStep === steps.length - 1 && (
            <>
              <Button
                color="primary"
                onClick={() => {
                  handleGenerateBarcodeProps('Big')
                }}
                variant={'contained'}
                sx={{ mr: 3 }}
                startIcon={<PrintIcon />}
              >
                PRINT LABEL (2 5/16” x 4”)
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  handleGenerateBarcodeProps('small')
                }}
                variant={'contained'}
                sx={{ mr: 3 }}
                startIcon={<PrintIcon />}
              >
                PRINT LABEL (1/2” x 1 7/8”)
              </Button>
            </>
          )}
        </>
      )
    return returnCmp
  }

  const renderPropsImages = () => {
    return (
      <Box sx={{ width: 'auto', minWidth: 600 }} role="presentation">
        <AddPropImage
          toggleDrawer={() => {
            toggleShowPropsImage()
          }}
          item={showPropsImageDataId}
          classes={classes}
          lanId={lanId}
          sampleType={selectedTab}
        />
      </Box>
    )
  }
  const renderEditControl = () => {
    return (
      <Grid container justifyContent={'space-between'}>
        <Grid item className={classes.editPageHeader}>
          <Typography className={classes.editPageFields}>
            Pegasus Id : {searchDataNew.pegasusId}
          </Typography>
        </Grid>
        <Grid item className={classes.editPageHeader}>
          <Typography className={classes.editPageFields}>
            Created By : {searchDataNew.propSpecialist}
          </Typography>
        </Grid>
        <Grid item className={classes.editPageHeader}>
          <Typography className={classes.editPageFields}>
            Filemaker Id : {searchDataNew.propId}
          </Typography>
        </Grid>
      </Grid>
    )
  }
  if (activeStep < Number(1) && fetchDataRequestPending) {
    return <CircularPageLoader open />
  }

  const headerMessage =
    selectedTab !== SCENIC
      ? type === EDIT_PROP
        ? 'Edit Prop'
        : 'Add Prop'
      : 'Add Scenic'

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.rootHeader}>
        <Typography variant="h5" gutterBottom>
          {headerMessage}
        </Typography>
      </Grid>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          {type !== EDIT_PROP ? (
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              sx={{ width: 700 }}
            >
              {steps.map((label) => {
                const stepProps = {}
                const labelProps = {}
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          ) : (
            renderEditControl()
          )}
        </Grid>
        <Grid item>
          {renderPrintlabel()}
          {type !== EDIT_PROP
            ? renderMorePageControls()
            : renderEditMorePageControls()}
          {renderPageControls()}
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.renderStepCls}>
        {renderStepperPages()}
      </Grid>
      {showAlertOnAdd && (
        <DialogBox
          isAlertVisible={showAlertOnAdd}
          message={alertMessageOnAdd}
          onClose={closeAlert}
          response={addResponse}
          onAgree={submitAddPropFormConfirm}
          title="Add Props?"
          primaryButtonText="YES, ADD PROP"
          secondaryButtonText="NEVERMIND"
          fullWidth
        />
      )}
      {showPropsImage && (
        <SwipeableDrawer
          anchor="right"
          id="vieworuploadpropimage"
          open={showPropsImage}
          style={{
            position: 'fixed',
            zIndex: 3300,
            inset: '0px',
          }}
        >
          <Box
            sx={{
              width: 750,
            }}
            role="presentation"
          >
            {renderPropsImages()}
          </Box>
        </SwipeableDrawer>
      )}
    </Grid>
  )
}

export default withHOCs({ auth: true, nav: true, styles })(AddProp)
