import React from 'react'
import withHOCs from 'util/withHocs'
import { useDispatch } from 'react-redux'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import {
  MyLocation,
  ViewList,
  History,
  DeviceHub,
  ShoppingCart,
  Settings,
  AccountBox,
  LocalShipping,
  Collections,
  Search,
  HelpOutline,
  LocationOn,
} from '@material-ui/icons'
import ArchiveIcon from '@mui/icons-material/Archive'
import SideNav, {
  Footer,
  Header,
  Icon,
  Link,
  Text,
} from '../../praxis-dependencies/SideNav'
import UserBar from '../../praxis-dependencies/UserBar'
import DownloadIcon from '../../images/box-open-solid.svg?url'
import apiConfig from '../../apiConfig'
import { SAMPLES, PROPS, SCENIC } from 'enums/Tabs'
import { getDomainName } from '../../util/CommonUtils'
import { closeSideNav } from '../../store/layout/actionCreator'

const styles = (theme) => ({
  contentContainer: {
    padding: theme.spacing(1), // Default <Grid container> spacing is 16. Use 8px padding for bug in material-ui Grid. See https://github.com/callemall/material-ui/issues/7466#issuecomment-316356427
    marginTop: 50,
    marginLeft: 50,
  },
})

const LayoutSideNav = (props) => {
  const dispatch = useDispatch()
  const { classes, auth, sideNavIsOpen, searchTabValue = '' } = props

  const {
    isAuthorized = () => {},
    isAuthenticated = () => {},
    login = () => {},
    logout = () => {},
    session = {},
  } = auth
  const renderSearchPage =
    searchTabValue === ''
      ? isAuthorized(apiConfig.roles.scenic) &&
        !isAuthorized(apiConfig.roles.admin)
        ? SCENIC
        : !isAuthorized(apiConfig.roles.defaultViewProps)
          ? SAMPLES
          : PROPS
      : searchTabValue
  const searchLink = `/searchInventory/${renderSearchPage}`

  const handleCloseSideNav = () => {
    if (sideNavIsOpen) {
      dispatch(closeSideNav())
    }
  }

  const getDisplayUserName = () => {
    const { userInfo = {} } = session || {}
    const { fullName: userFullName = '', company: userCompany = '' } =
      userInfo || {}
    const domain = getDomainName(window.location.hostname)

    let returnStr = userFullName
    if (
      isAuthorized(apiConfig.roles.vendor) &&
      (process.env.REACT_APP_ENV.indexOf('pol_') !== -1 ||
        domain === 'partnersonline.com')
    ) {
      returnStr = (
        <div className={classes.displayName}>
          <div>{userFullName}</div>
          <div className={classes.companyName}>{userCompany}</div>
        </div>
      )
    }
    return returnStr
  }

  return (
    <SideNav isOpen={sideNavIsOpen} onClose={handleCloseSideNav}>
      <Header>
        <UserBar
          displayName={getDisplayUserName()}
          handleSignIn={login}
          handleSignOut={logout}
          isAuthorized={isAuthenticated()}
        />
      </Header>
      {isAuthorized(apiConfig.roles.search) ? (
        <Link to="/" exact id="orderSamples">
          <Icon>
            <ShoppingCart />
          </Icon>
          <Text>Order Samples</Text>
        </Link>
      ) : null}
      {isAuthorized(apiConfig.roles.inventory) && (
        <Link to={searchLink} exact>
          <Icon id={'searchInventoryIcon'}>
            <Search />
          </Icon>
          <Text>Search</Text>
        </Link>
      )}
      {isAuthorized(apiConfig.roles.setting) ? (
        <Link to="/settings" exact>
          <Icon>
            <Settings />
          </Icon>
          <Text>Settings</Text>
        </Link>
      ) : null}
      {isAuthorized(apiConfig.roles.scenic) ? (
        <Link to="/scenicRooms" exact>
          <Icon>
            <ViewList />
          </Icon>
          <Text>Scenic Rooms</Text>
        </Link>
      ) : null}
      {isAuthorized(apiConfig.roles.projects) ? (
        <Link to="/projects" exact>
          <Icon>
            <DeviceHub />
          </Icon>
          <Text>Projects</Text>
        </Link>
      ) : null}
      {isAuthorized(apiConfig.roles.projects) ? (
        <Link to="/collections" exact>
          <Icon>
            <Collections />
          </Icon>
          <Text>Collections</Text>
        </Link>
      ) : null}
      {isAuthorized(apiConfig.roles.mySamples) ? (
        <Link to="/myOrders" exact>
          <Icon>
            <History />
          </Icon>
          <Text>My Order History</Text>
        </Link>
      ) : null}
      {isAuthorized(apiConfig.roles.vendor) ? (
        <Link to="/vendorLogin" exact>
          <Icon>
            <AccountBox />
          </Icon>
          <Text>Vendor Login</Text>
        </Link>
      ) : null}
      {isAuthorized(apiConfig.roles.vendor) ? (
        <Link to="/orderSummary" exact>
          <Icon>
            <ViewList />
          </Icon>
          <Text>Requested Samples</Text>
        </Link>
      ) : null}
      {isAuthorized(apiConfig.roles.vendor) ? (
        <Link to="/shipmentSummary" exact>
          <Icon>
            <History />
          </Icon>
          <Text>Sample History</Text>
        </Link>
      ) : null}
      {isAuthorized(apiConfig.roles.checkIn) ? (
        <Link to="/checkIn" exact>
          <Icon>
            <img
              src={DownloadIcon}
              style={{ height: '24px', width: '24px' }}
              alt=""
            />
          </Icon>
          <Text>Check-In Samples</Text>
        </Link>
      ) : null}
      {isAuthorized(apiConfig.roles.checkIn) ? (
        <Link to="/moveSamples" exact>
          <Icon>
            <MyLocation />
          </Icon>
          <Text>Move Samples</Text>
        </Link>
      ) : null}
      {isAuthorized(apiConfig.roles.shipSamples) ? (
        <Link to="/shipSamples" exact>
          <Icon>
            <LocalShipping />
          </Icon>
          <Text>Ship Samples</Text>
        </Link>
      ) : null}
      {isAuthorized(apiConfig.roles.manageLocations) ? (
        <Link to="/manageLocations" exact>
          <Icon>
            <LocationOn />
          </Icon>
          <Text>Manage Locations</Text>
        </Link>
      ) : null}

      {isAuthorized(apiConfig.roles.inventory) ? (
        <Link to="/SearchArchivedSamples" exact>
          <Icon id={'archivedSamplesIcon'}>
            <ArchiveIcon />
          </Icon>
          <Text>Archived Samples</Text>
        </Link>
      ) : null}
      {(!isAuthorized(apiConfig.roles.vendor) || isAuthorized(apiConfig.roles.admin)) ? (
        <Link to="/batchScanner" exact>
          <Icon>
            <DocumentScannerIcon />
          </Icon>
          <Text>Batch Scanning</Text>
        </Link>
      ) : null}
      {isAuthorized(apiConfig.roles.helpvendor) ||
      isAuthorized(apiConfig.roles.helpUsers) ? (
        <Link to="/help" exact>
          <Icon>
            <HelpOutline />
          </Icon>
          <Text>Help</Text>
        </Link>
      ) : null}
      <Footer>
        <ul className={classes.sideNavFooterList}>
          <li>©2019 Target Brands, Inc.</li>
        </ul>
      </Footer>
    </SideNav>
  )
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {},
})(LayoutSideNav)
