import * as types from './actionType'

export const initialState = (window.Cypress && window.initialState) || {
columnData: [
    {
        id: 'commentDate',
        numeric: false,
        disablePadding: true,
        label: 'Date',
        type: 'date',
    },
    {
        id: 'commentBy',
        numeric: false,
        disablePadding: true,
        label: 'From',
    },
    {
        id: 'commentText',
        numeric: false,
        disablePadding: false,
        label: 'Comment',
        align: 'center',
    },
  ],
  commentsData: [],
}

export default function comments(state = initialState, action = {}) {
    switch (action.type) {
      case types.SAVE_VIEW_COMMENTS: {
        return {
          ...state,
          commentsData: [...action.payload]
        };
      }
      case types.SAVE_VIEW_COMMENTS_FAILED:
        return {
          ...state,
          commentsData: [],
        };
      default:
        return state;
    }
  }
