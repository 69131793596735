import axios from 'axios'
import apiConfig from '../apiConfig'

const {
  managements: { url: managementsUrl = '' },
  apiKey = '',
} = apiConfig

export function getAllComments(pegasusId = '', page = 0, rowsPerPage = 10) {
  return axios.get(`${managementsUrl}comments/${pegasusId}`, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      key: apiKey,
      page: page,
      size: rowsPerPage,
    },
  })
}

export function updateComment(commentId = '', updateRequest = {}) {
  return axios.put(`${managementsUrl}comments/${commentId}`, updateRequest, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function deleteComment(commentId = '') {
  return axios.delete(`${managementsUrl}comments/${commentId}`, {
    headers: {
      'Content-Type': `application/json`,
    },
    params: {
      key: apiKey,
    },
    responseType: 'application/json',
  })
}

export function addComment(params = {}) {
  return axios.post(`${managementsUrl}comments`, params, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}
