import { PureComponent, memo } from 'react'
import * as propTypes from 'prop-types'
import { isEmpty, findIndex, isEqual, isArray } from 'lodash'
import TableBody from '@material-ui/core/TableBody/TableBody'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import PopoverWithAnchor from '../CommonPopover/PopoverWithAnchor'
import MenuItem from '@mui/material/MenuItem'
import Logo from '../../images/arrow-split-horizontal.svg'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { TableCelltheme } from '../../config/themeConfig'
import {
  setInBrowserStorage,
  removeReactElements,
  checkIsEnabledRow,
} from 'util/CommonUtils'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DeleteIcon from '@mui/icons-material/Delete'
import CircularProgress from '@material-ui/core/CircularProgress'
import LaunchIcon from '@material-ui/icons/Launch'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { widthOfColumns } from './Constants'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Grid } from '@mui/material'

/*
    Only data and knowledge of the data k,v mapping is needed. All filtering and sorting
    updated should be triggered by a dispatch to the redux store. Here, data should flow
    down from an already filtered and sorted data source that also provides information
    on what slice of the filtered and sorted data should be taken
    For non-textual elements, map the corresponding data field into an appropriate react element
    or component. IE data.map(d => <img src=d[src] /> ) before passing the info into this component
*/

class FlexibleTableBody extends PureComponent {
  constructor(props) {
    super(props)
    const { data = [] } = this.props
    this.state = {
      editOpen: false,
      editRow: -1,
      deleteOpen: false,
      deleteRow: -1,
      updateInfo: {},
      stateData: [...data],
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { data: propsData = [], isUpdateDone } = this.props
    const { data: prevPropsData = [], isUpdateDone: prevIsUpdateDone } =
      prevProps
    if (
      !isEqual(propsData, prevPropsData) ||
      (!isUpdateDone && !isEqual(isUpdateDone, prevIsUpdateDone))
    ) {
      this.setState({
        stateData: [...propsData],
      })
    }
  }

  handleChangeUpdateField(key, item, event) {
    const { updateInfo = {} } = this.state
    const stateUpdateInfo = Object.assign({}, updateInfo)
    const itemCopy = Object.assign({}, item)
    const updatedValue = event.target.value || ''
    this.setState({
      updateInfo: Object.assign({}, itemCopy, stateUpdateInfo, {
        [key]: updatedValue,
      }),
    })
  }

  isSelected(item) {
    return this.props.selected.indexOf(item) !== -1
  }

  openEdit(rowNum) {
    this.setState({
      editOpen: true,
      editRow: rowNum,
      deleteOpen: false,
      deleteRow: -1,
    })
  }

  openDelete(rowNum) {
    this.setState({
      deleteOpen: true,
      deleteRow: rowNum,
      editOpen: false,
      editRow: -1,
      updateInfo: {},
    })
  }

  openDeleteAction(actionType = '', rowData = {}) {
    const { deleteData = () => {} } = this.props
    if (actionType === 'ok') {
      deleteData(rowData)
    }
    this.setState({
      deleteOpen: false,
      deleteRow: -1,
    })
  }
  isButtonDisabled(selectedItemIndex = {}) {
    const { authId = '' } = this.props
    if (selectedItemIndex['commentCreatedById'] === authId) return false
    else return true
  }

  saveInfo(updateFlag = false) {
    const { updateData } = this.props
    const { updateInfo = {} } = this.state
    if (!updateFlag) {
      updateData(updateInfo)
    }
    this.setState({
      editOpen: false,
      editRow: -1,
      updateInfo: {},
    })
  }
  getNotes(notes = '') {
    let maxLength = 10
    let result =
      typeof notes === 'string' && notes !== ''
        ? notes.substring(0, maxLength) + '...'
        : notes
    return result
  }

  render() {
    const {
      dataFieldKeys,
      cascadeFieldKeys,
      cascadeFieldLogo = <MoreVertIcon />,
      checkBoxEnabled,
      clickableColumn,
      clickableRowButton,
      clickableRowButtonColor,
      disableItems,
      editEnabled,
      deleteEnabled,
      actionControlsType,
      editItems = [],
      onSelected,
      onCheckBoxChange,
      onCascadeChange,
      rowsPerPage,
      page,
      paginationAtApiLevel,
      valueToCheckAgainst,
      showPopup = () => {},
      uniqueIdentifierInData,
      disableTablePagination,
      tableRowHover = true,
      cellTheme = {},
      classes = {},
      columnData = [],
      isLoading = false,
      editDataColumn = false,
      editDataPrimaryButtonText = 'Update',
      selected: propsSelected = [],
      enableScrollButtons,
      launchInNewTabColumns = [],
      launchInNewTabClick = null,
      onIconClick = () => {},
      cascadeIcon = true,
      moreOptions,
      errorRowTooltipMessage = '',
      openOptions = false,
      openButtonName = '',
      additionalIconInfoContent = {},
    } = this.props
    const {
      editOpen: stateEditOpen,
      editRow: stateEditRow,
      deleteOpen: stateDeleteOpen,
      deleteRow: stateDeleteRow,
      stateData = [],
      updateInfo: stateUpdateInfo = {},
    } = this.state
    const stateUpdateInfoCopy = Object.assign({}, stateUpdateInfo)

    const dataCopy = [...stateData]

    const paginatedData = disableTablePagination
      ? dataCopy
      : paginationAtApiLevel
        ? dataCopy
        : dataCopy.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    const isErrorRowsExists = dataCopy.some((dataObj) => !!dataObj.isAnErrorRow)
    const isAdditionalIconRowsExists = dataCopy.some(
      (dataObj) => !!dataObj.isAnAdditionalInfoRow
    )
    return (
      <MuiThemeProvider theme={cellTheme || TableCelltheme}>
        <TableBody id="tableBody">
          {isLoading ? (
            <TableRow style={{ height: 500 }}>
              <TableCell align={'center'} colSpan={columnData.length + 1}>
                <div className={classes.progressBarDiv}>
                  <CircularProgress className={classes.progressBar} />
                </div>
              </TableCell>
            </TableRow>
          ) : (
            paginatedData.map((item, index) => {
              const itemCopy = Object.assign({}, item)
              const uniqueKey = `${
                uniqueIdentifierInData
                  ? itemCopy[uniqueIdentifierInData]
                  : itemCopy[dataFieldKeys[0].id]
              }_${index}`

              const selectedItemIndex = findIndex(
                dataCopy,
                function (obj = {}) {
                  return (
                    obj[uniqueIdentifierInData] ===
                    itemCopy[uniqueIdentifierInData]
                  )
                }
              )
              let isSelected = onSelected(propsSelected, itemCopy)
              const tableCells = dataFieldKeys.map((key = {}, keyIndex) => {
                const { id = '', textRestriction = false } = key || {}
                const isClickableColumn = clickableColumn.includes(id)
                return (
                  <TableCell
                    key={`${index}_${keyIndex}`}
                    style={{ ...(isClickableColumn && { cursor: 'pointer' }) }}
                    onClick={() =>
                      (clickableColumn.includes(id) ||
                        clickableColumn.length === 0) &&
                      !clickableRowButton
                        ? showPopup(removeReactElements(itemCopy))
                        : null
                    }
                  >
                    {stateEditOpen &&
                    stateEditRow === selectedItemIndex &&
                    ((editEnabled && editItems.indexOf(id) !== -1) ||
                      (!!editDataColumn &&
                        itemCopy['editColumn'].indexOf(id) !== -1)) ? (
                      ''
                    ) : launchInNewTabColumns.indexOf(id) !== -1 &&
                      itemCopy[id] ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {itemCopy[id]}
                        <Tooltip title="Open in new tab">
                          <IconButton
                            id="launchIconNew"
                            aria-label="Open in new tab"
                            size="small"
                            onClick={(event) => {
                              event.preventDefault()
                              if (launchInNewTabClick && itemCopy[id]) {
                                launchInNewTabClick(itemCopy[id])
                              } else {
                                setInBrowserStorage(
                                  'newTab',
                                  JSON.stringify({
                                    searchTerm: id.toUpperCase(),
                                    searchValue: itemCopy[id],
                                  })
                                )
                                window.open(
                                  window.location.origin +
                                    '' +
                                    window.location.pathname,
                                  '_blank'
                                )
                              }
                              return false
                            }}
                          >
                            <LaunchIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : !cascadeIcon ? (
                      <div
                        style={{
                          width: !cascadeIcon && (widthOfColumns[id] || 80),
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {textRestriction ? (
                          isArray(itemCopy[id]) && itemCopy[id].length > 1 ? (
                            <span
                              style={{
                                wordWrap: 'break-word',
                              }}
                            >
                              {`${itemCopy[id][0].substring(0, 15) + '...'} `}{' '}
                              <br></br>
                              {`+ ${itemCopy[id].length - 1}`}
                            </span>
                          ) : isArray(itemCopy[id]) &&
                            itemCopy[id].length === 1 ? (
                            <span
                              style={{
                                wordWrap: 'break-word',
                              }}
                            >
                              {`${itemCopy[id][0].substring(0, 15) + '...'} `}{' '}
                            </span>
                          ) : (
                            <span
                              style={{
                                wordWrap: 'break-word',
                              }}
                            >
                              {this.getNotes(itemCopy[id])}
                            </span>
                          )
                        ) : (
                          <span
                            style={{
                              wordWrap: 'break-word',
                              width: '300px',
                            }}
                          >
                            <span
                              style={{
                                wordWrap: 'break-word',
                                width: 85,
                              }}
                            >
                              {itemCopy[id]}
                            </span>
                          </span>
                        )}
                        {cascadeFieldKeys.indexOf(id) !== -1 && (
                          <Tooltip title="Edit Details">
                            <IconButton
                              alt="Logo"
                              onClick={(e) =>
                                onIconClick(e, item, id, itemCopy)
                              }
                              style={{
                                width: '35px',
                                color: 'black',
                                right: 0,
                                position: 'absolute',
                              }}
                            >
                              {cascadeFieldLogo}
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          wordWrap: 'break-word',
                          width: widthOfColumns[id] || 'auto',
                          flexDirection: 'column',
                        }}
                      >
                        {itemCopy[id]}
                      </div>
                    )}
                    {cascadeFieldKeys.indexOf(id) !== -1 ? (
                      cascadeIcon ? (
                        <IconButton
                          onClick={() =>
                            onCascadeChange({
                              value:
                                itemCopy[id].props.value ||
                                itemCopy[id].props['data-value'] ||
                                itemCopy[id].props.defaultValue,
                              column: id,
                            })
                          }
                        >
                          <Logo />
                        </IconButton>
                      ) : null
                    ) : null}
                    {stateEditOpen &&
                    stateEditRow === selectedItemIndex &&
                    ((editEnabled && editItems.indexOf(id) !== -1) ||
                      (!!editDataColumn &&
                        itemCopy['editColumn'].indexOf(id) !== -1)) ? (
                      <TextField
                        id={`${index}_${keyIndex}`}
                        margin="none"
                        value={
                          stateUpdateInfoCopy[uniqueIdentifierInData] ===
                          item[uniqueIdentifierInData]
                            ? stateUpdateInfoCopy[id]
                            : item[id]
                        }
                        onChange={(event) => {
                          this.handleChangeUpdateField(id, itemCopy, event)
                        }}
                      />
                    ) : null}
                  </TableCell>
                )
              })
              let clickableRowButtonCell
              if (clickableRowButton) {
                clickableRowButtonCell = (
                  <TableCell key={`clickableRowButton-${uniqueKey}`}>
                    <Button
                      style={
                        clickableRowButtonColor
                          ? { color: clickableRowButtonColor }
                          : null
                      }
                      id={clickableRowButton}
                      variant="text"
                      color="primary"
                      onClick={() => showPopup(removeReactElements(itemCopy))}
                    >
                      {clickableRowButton}
                    </Button>
                  </TableCell>
                )
              }

              let checkboxCell

              if (checkBoxEnabled) {
                const isDisabledRow =
                  disableItems &&
                  !checkIsEnabledRow(valueToCheckAgainst, itemCopy)
                checkboxCell = (
                  <TableCell
                    key={`checkbox-${uniqueKey}`}
                    padding="checkbox"
                    className={classes.checkBoxElem}
                  >
                    <Checkbox
                      color="primary"
                      disabled={isDisabledRow}
                      checked={isSelected}
                      onChange={() =>
                        onCheckBoxChange(removeReactElements(itemCopy))
                      }
                    />
                  </TableCell>
                )
              }
              let additionalIconCell = ''
              const { isAnErrorRow = false, isAnAdditionalInfoRow = false } =
                itemCopy
              if (isErrorRowsExists) {
                additionalIconCell = (
                  <TableCell
                    key={`additional-${uniqueKey}`}
                    className={classes.errorBlock}
                  >
                    {isAnErrorRow ? (
                      <Tooltip title={errorRowTooltipMessage}>
                        <ErrorOutlineIcon className={classes.errorIcon} />
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </TableCell>
                )
              } else if (isAdditionalIconRowsExists) {
                additionalIconCell = (
                  <TableCell
                    key={`additional-${uniqueKey}`}
                    className={classes.errorBlock}
                  >
                    {isAnAdditionalInfoRow ? (
                      <Tooltip title={errorRowTooltipMessage}>
                        {additionalIconInfoContent}
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </TableCell>
                )
              }
              let leftScrollIconCell = ''
              if (enableScrollButtons) {
                leftScrollIconCell = (
                  <TableCell key={`leftScroll-${uniqueKey}`}>{''}</TableCell>
                )
              }

              let rightScrollIconCell = ''
              if (enableScrollButtons) {
                rightScrollIconCell = (
                  <TableCell key={`rightScroll-${uniqueKey}`}>{''}</TableCell>
                )
              }
              let openOptionCell = ''
              if (openOptions) {
                openOptionCell = (
                  <TableCell key={`open-${uniqueKey}`}>
                    <Button
                      color="primary"
                      onClick={() => {
                        showPopup(removeReactElements(itemCopy))
                      }}
                    >
                      {openButtonName}
                    </Button>
                  </TableCell>
                )
              }
              let moreOptionCell = ''
              if (moreOptions && moreOptions.length > 0) {
                const PopoverTriggerComponent = (additionalProps) => (
                  <IconButton {...additionalProps}>
                    <MoreHorizIcon />
                  </IconButton>
                )
                moreOptionCell = (
                  <TableCell key={`moreOption-${uniqueKey}`}>
                    <PopoverWithAnchor
                      PopoverTriggerComponent={PopoverTriggerComponent}
                      uniqueId={`moreOption-${uniqueKey}`}
                    >
                      <>
                        <MenuItem
                          value="Details"
                          onClick={() =>
                            showPopup(removeReactElements(itemCopy))
                          }
                        >
                          More Details
                        </MenuItem>
                        {moreOptions.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            onClick={() =>
                              option?.action(removeReactElements(itemCopy))
                            }
                            disabled={option?.disabled(
                              removeReactElements(itemCopy)
                            )}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </>
                    </PopoverWithAnchor>
                  </TableCell>
                )
              }

              let editCell
              if (editEnabled) {
                editCell = (
                  <>
                    {stateEditOpen && stateEditRow === selectedItemIndex ? (
                      <div style={{ display: 'flex' }}>
                        {actionControlsType === 'icon' ? (
                          <Tooltip title="Save">
                            <IconButton
                              alt="Save"
                              onClick={() => {
                                this.saveInfo()
                              }}
                              style={{
                                width: '35px',
                                color: '#A35C25',
                              }}
                            >
                              <SaveIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Button
                            color="primary"
                            onClick={() => {
                              this.saveInfo()
                            }}
                            size="small"
                          >
                            Save
                          </Button>
                        )}
                        {actionControlsType === 'icon' ? (
                          <Tooltip title="Cancel Change">
                            <IconButton
                              alt="Cancel"
                              onClick={() => {
                                this.saveInfo(true)
                              }}
                              style={{
                                width: '35px',
                                color: '#A35C25',
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Button
                            color="primary"
                            onClick={() => {
                              this.saveInfo(true)
                            }}
                            size="small"
                          >
                            Cancel
                          </Button>
                        )}
                      </div>
                    ) : actionControlsType === 'icon' ? (
                      <Tooltip title="Update">
                        <IconButton
                          alt="Update"
                          onClick={() => this.openEdit(selectedItemIndex)}
                          style={{
                            width: '35px',
                            color: this.isButtonDisabled(itemCopy)
                              ? 'gray'
                              : 'black',
                          }}
                          disabled={this.isButtonDisabled(itemCopy)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Button
                        color="primary"
                        onClick={() => this.openEdit(selectedItemIndex)}
                        size="small"
                      >
                        Update
                      </Button>
                    )}
                  </>
                )
              }
              if (editDataColumn) {
                editCell =
                  editDataColumn && !isEmpty(itemCopy['editColumn']) ? (
                    <>
                      {stateEditOpen && stateEditRow === selectedItemIndex ? (
                        <>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.saveInfo()
                            }}
                            size="small"
                          >
                            Save
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.saveInfo(true)
                            }}
                            size="small"
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Button
                          color="primary"
                          onClick={() => this.openEdit(selectedItemIndex)}
                          size="small"
                        >
                          {editDataPrimaryButtonText}
                        </Button>
                      )}
                    </>
                  ) : (
                    <></>
                  )
              }

              let deleteCell
              if (deleteEnabled) {
                deleteCell = (
                  <>
                    {stateDeleteOpen && stateDeleteRow === selectedItemIndex ? (
                      <div style={{ display: 'flex' }}>
                        {actionControlsType === 'icon' ? (
                          <Tooltip title="Yes, Keep">
                            <IconButton
                              alt="No"
                              onClick={() => {
                                this.openDeleteAction('cancel')
                              }}
                              style={{
                                width: '35px',
                                color: '#A35C25',
                              }}
                            >
                              <DoNotDisturbIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Button
                            color="primary"
                            onClick={() => {
                              this.openDeleteAction('cancel')
                            }}
                            size="small"
                          >
                            No
                          </Button>
                        )}
                        {actionControlsType === 'icon' ? (
                          <Tooltip title="Yes Delete">
                            <IconButton
                              alt="Yes Delete"
                              onClick={() => {
                                this.openDeleteAction('ok', itemCopy)
                              }}
                              style={{
                                width: '35px',
                                color: '#A35C25',
                              }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Button
                            color="primary"
                            onClick={() => {
                              this.openDeleteAction('ok', itemCopy)
                            }}
                            size="small"
                          >
                            Yes, Keep
                          </Button>
                        )}
                      </div>
                    ) : actionControlsType === 'icon' ? (
                      <Tooltip title="Delete">
                        <IconButton
                          alt="Delete"
                          onClick={() => this.openDelete(selectedItemIndex)}
                          style={{
                            width: '35px',
                            color: this.isButtonDisabled(itemCopy)
                              ? 'gray'
                              : 'black',
                          }}
                          disabled={this.isButtonDisabled(itemCopy)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Button
                        color="primary"
                        onClick={() => this.openDelete(selectedItemIndex)}
                        size="small"
                      >
                        Delete
                      </Button>
                    )}
                  </>
                )
              }

              let controlsCell
              if (editEnabled || editDataColumn || deleteEnabled) {
                controlsCell = (
                  <TableCell key={`row-${uniqueKey}`}>
                    {/* {
                      [editCell, deleteCell]
                    } */}
                    <Grid
                      container
                      alignItems="center"
                      style={{ flexFlow: 'row' }}
                    >
                      <Grid item>{editCell}</Grid>
                      <Grid item>{deleteCell}</Grid>
                    </Grid>
                  </TableCell>
                )
              }

              return (
                <TableRow
                  hover={tableRowHover}
                  role="checkbox"
                  tabIndex={-1}
                  key={`row-${uniqueKey}`}
                  className={classes.tableRow}
                  selected={isSelected}
                >
                  {[
                    leftScrollIconCell,
                    additionalIconCell,
                    checkboxCell,
                    ...tableCells,
                    controlsCell,
                    clickableRowButtonCell,
                    rightScrollIconCell,
                    moreOptionCell,
                    openOptionCell,
                  ]}
                </TableRow>
              )
            })
          )}
        </TableBody>
      </MuiThemeProvider>
    )
  }
}

FlexibleTableBody.defaultProps = {
  data: [],
  dataFieldKeys: [],
  checkBoxEnabled: false,
  clickableColumn: [],
  disableItems: false,
  editEnabled: false,
  deleteEnabled: false,
  editItems: [],
  onSelected: () => {},
  onCheckBoxChange: () => {},
  rowsPerPage: 0,
  page: 0,
  numSelected: 0,
  rowCount: 0,
  valueToCheckAgainst: [],
  updateData: () => {},
  tableTitle: '',
  cellTheme: {},
  disableTablePagination: false,
  isLoading: false,
}

FlexibleTableBody.propTypes = {
  cascadeFieldKeys: propTypes.array,
  checkBoxEnabled: propTypes.bool,
  clickableColumn: propTypes.array,
  data: propTypes.array,
  dataFieldKeys: propTypes.array,
  disableItems: propTypes.bool,
  editEnabled: propTypes.bool,
  deleteEnabled: propTypes.bool,
  actionControlsType: propTypes.string,
  editItems: propTypes.array,
  onSelected: propTypes.func,
  onCheckBoxChange: propTypes.func,
  onCascadeChange: propTypes.func,
  rowsPerPage: propTypes.number,
  page: propTypes.number,
  numSelected: propTypes.number,
  rowCount: propTypes.number,
  valueToCheckAgainst: propTypes.array,
  showPopup: propTypes.func,
  selected: propTypes.array,
  updateData: propTypes.func,
  tableTitle: propTypes.string,
  uniqueIdentifierInData: propTypes.string,
  disableTablePagination: propTypes.bool,
  tableRowHover: propTypes.bool,
  cellTheme: propTypes.object,
  columnData: propTypes.array,
  isLoading: propTypes.bool,
  launchInNewTabColumns: propTypes.array,
  moreOptions: propTypes.array,
  cascadeFieldLogo: propTypes.array,
  enableColumnEditKeys: propTypes.array,
  authId: propTypes.string,
}

const areEqual = (prevProps, nextProps) => {
  if (
    prevProps.enableScrollButtons === nextProps.enableScrollButtons &&
    prevProps?.selected?.length === nextProps?.selected?.length &&
    prevProps?.checkBoxEnabled === nextProps?.checkBoxEnabled &&
    prevProps?.numSelected === nextProps?.numSelected &&
    prevProps?.isLoading === nextProps?.isLoading &&
    prevProps?.page === nextProps?.page &&
    prevProps?.rowsPerPage === nextProps?.rowsPerPage &&
    prevProps?.editEnabled === nextProps?.editEnabled &&
    prevProps?.deleteEnabled === nextProps?.deleteEnabled &&
    prevProps?.data?.length === nextProps?.data?.length &&
    isEqual(prevProps?.data, nextProps?.data) &&
    prevProps?.dataFieldKeys?.length === nextProps?.dataFieldKeys?.length &&
    prevProps?.columnData?.length === nextProps?.columnData?.length
  ) {
    return true
  }
  return false
}

export default memo(FlexibleTableBody, areEqual)
