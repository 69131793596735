/*

👋 “Hey” from the Praxis team!

'apiConfig' is one pattern that can be used to set environment-based values at build time.
This happens by creating an environment variable REACT_APP_ENV, and then matching corresponding
keys to each environment in the 'envConfigs' object below. To learn more about how to set
REACT_APP_ENV, view the README generated with this app.


For an alternative pattern - using TAP configuration to set environment-based values at cluster initialization - see:
@praxis/component-runtime-env - https://praxis.prod.target.com/components/runtime-env

*/

import merge from 'lodash/merge'
import { config as analyticsConfig } from '@praxis/component-analytics'
import { itemCommonUtilis } from 'item-component'

const DEV_API_KEY = '87fdd2141a6a32bf5c8b5d47deaad4d797b04c42'
const STG_API_KEY = '6e38ef7eba10d3484e64050255321bf497d4620b'
const PRD_API_KEY = '9f7a5012217fae8a8864dbff8ed501f440ec671f'
const hostname = window.location.hostname || ''

// configurations shared between most or all environments can be stored in commonConfig
const commonConfig = {
  auth: {
    authorizationUrl:
      'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
    logoutUrl:
      'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    logoutRedirect: `${window.location.origin}/`,
    loginRedirect: `${window.location.origin}/`,
    storageType: 'localStorage',
    tokenType: 'Bearer',
    scope: ['openid profile'],
    responseType: 'token id_token',
    extraUserInfoFields: ['company'],
  },
  apiForRetry: ['project_channel'], // list of services for retry
  analytics: {
    appName: '< A unique app name for Firefly Internal Insights >',
  },
}

const envConfigs = {
  // dev is the default development environment set by .env.development and the default pipeline in .drone.yml
  qa: {
    apiKey: DEV_API_KEY,
    auth: {
      clientId: 'pegasusui_npe_im',
    },
    analytics: {
      apiKey:
        '< Create a non-prod (STAGE) API key by registering your app at http://go/developer/apps >',
    },
    roles: {
      // Passing an empty array will reject all users. If no value is provided, it will only check if the user is authenticated.
      setting: null,
      search: null,
      mySamples: null,
      checkIn: null,
      projects: null,
      vendor: null,
      shipSamples: null,
      inventory: null,
      manageLocations: null,
      viewProps: ['APP-PEG-ADMIN-STG', 'APP-PEG-SM-STG', 'APP-PEG-PROPS-STG'],
      addProps: ['APP-PEG-ADMIN-STG', 'APP-PEG-PROPS-STG'],
      requestedSamples: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-MS-STG',
      ],
      defaultViewProps: ['APP-PEG-PROPS-STG'],
      productProducers: ['APP-PEG-SM-STG', 'APP-PEG-ADMIN-STG'],
      smsPartners: ['APP-PEG-MS-STG', 'APP-PEG-ADMIN-STG'],
      helpvendor: [
        'APP-PEG-MERCH-VENDOR',
        'APP-PEG-MKT-VENDOR',
        'APP-PEG-VENDOR ',
      ],
      helpUsers: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-IDS-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
      ],
      autoOrder: ['APP-PEG-ADMIN-STG', 'APP-PEG-IDS-STG'],
      autoOrderEdit: ['APP-PEG-ADMIN-STG', 'APP-PEG-SM-STG'],
      props: ['APP-PEG-ADMIN-STG', 'APP-PEG-PROPS-STG'],
      projectsDefault: ['APP-PEG-SM-STG'],
      scenic: ['APP-PEG-ADMIN-STG', 'APP-PEG-SCENIC-STG'],
      admin: ['APP-PEG-ADMIN-STG'],
    },
    items: {
      apiKey: DEV_API_KEY,
      graphql_url:
        'https://pegasusitemapiv1.dev.target.com/sample_warehouse_items/v1/items/',
    },
    vendors: {
      url: 'https://businesspartnerapi.dev.target.com/sample_warehouse_vendors/v1/',
    },
    managements: {
      url: 'https://pegasusorderapiv1.dev.target.com/sample_warehouse_managements/v1/',
      // url: 'http://localhost:8082/sample_warehouse_managements/v1/',
    },
    uploads: {
      url: 'https://pegasusimageservice.dev.target.com/sample_warehouse_managements/v1/sample_images/v1',
    },
    props: {
      url: 'https://pegasusorderapiv1.dev.target.com/sample_warehouse_managements/v1/',
      apiKey: DEV_API_KEY,
    },
    archives: {
      url: 'https://pegasusarchiveservice.dev.target.com/sample_warehouse_managements/v1/sample_warehouse_archives/v1/',
    },
    user: {
      url: 'https://pegasususerapiv1.dev.target.com/sample_warehouse_users/v1/',
    },
    history: {
      url: 'https://pegasushistory-dev.dev.target.com/sample_warehouse_histories/v1/',
    },
    barcode: {
      apiKey: DEV_API_KEY,
      url: 'https://pegasusbarcodeapiv1.dev.target.com/sample_warehouse_barcodes/v1/',
    },
    combinedBarcode: {
      apiKey: DEV_API_KEY,
      url: 'https://pegasusbarcodeapiv1.dev.target.com/sample_warehouse_barcodes/v1/combined/barcodes',
    },
    externalReports: {
      apiKey: STG_API_KEY,
      url: 'https://stage-api.target.com/external_studio_reports/v1/',
    },
    batches: {
      apiKey: DEV_API_KEY,
      url: 'https://pegasusorderapiv1.dev.target.com/sample_warehouse_managements/v1/batches/',
    },
  },
  peg_ih_pol: {
    apiKey: DEV_API_KEY,
    auth: {
      authorizationUrl:
        'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.iam.perf.partnersonline.com/login/responses/logoff.html',
      clientId: 'pegasusui_npe_im',
      loginRedirect: `${window.location.origin}${itemCommonUtilis.findAppPath()}/`,
    },
    analytics: {
      apiKey:
        '< Create a non-prod (STAGE) API key by registering your app at http://go/developer/apps >',
    },
    roles: {
      setting: ['INVALID'],
      search: ['INVALID'],
      mySamples: ['INVALID'],
      checkIn: ['APP-PEG-MKT-VENDOR'],
      projects: ['APP-PEG-MKT-VENDOR'],
      vendor: ['APP-PEG-MERCH-VENDOR'],
      manageLocations: ['INVALID'],
      viewProps: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-IDS-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-PROPS-STG',
      ],
      addProps: ['APP-PEG-ADMIN-STG', 'APP-PEG-PROPS-STG'],
      admin: ['APP-PEG-ADMIN-STG'],
      requestedSamples: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-MS-STG',
      ],
      productProducers: ['APP-PEG-SM-STG', 'APP-PEG-ADMIN-STG'],
      smsPartners: ['APP-PEG-MS-STG', 'APP-PEG-ADMIN-STG'],
      helpvendor: [
        'APP-PEG-MERCH-VENDOR',
        'APP-PEG-MKT-VENDOR',
        'APP-PEG-VENDOR ',
      ],
      helpUsers: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-IDS-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
      ],
      scenic: ['APP-PEG-ADMIN-STG', 'APP-PEG-SCENIC-STG'],
      inventory: ['APP-PEG-MKT-VENDOR'],
      shipSamples: ['APP-PEG-MKT-VENDOR'],
      autoOrder: ['APP-PEG-ADMIN-STG', 'APP-PEG-IDS-STG'],
      autoOrderEdit: ['APP-PEG-ADMIN-STG', 'APP-PEG-SM-STG'],
      defaultViewProps: ['APP-PEG-PROPS-STG'],
      props: ['APP-PEG-ADMIN-STG', 'APP-PEG-PROPS-STG'],
      projectsDefault: ['APP-PEG-SM-STG'],
    },
    items: {
      apiKey: DEV_API_KEY,
      graphql_url:
        'https://pegasusitemapiv1.dev.target.com/sample_warehouse_items/v1/items/',
    },
    vendors: {
      url: 'https://stgapi-internal.target.com/sample_warehouse_vendors/v1/',
    },
    managements: {
      url: 'https://pegasusorderapiv1.dev.target.com/sample_warehouse_managements/v1/',
    },
    uploads: {
      url: 'https://pegasusimageservice.dev.target.com/sample_images/v1',
    },
    props: {
      apiKey: DEV_API_KEY,
      url: 'https://pegasusorderapiv1.dev.target.com/sample_warehouse_managements/v1/',
    },
    archives: {
      url: 'https://pegasusarchiveservice.dev.target.com/sample_warehouse_managements/v1/sample_warehouse_archives/v1/',
    },
    user: {
      url: 'https://pegasususerapiv1.dev.target.com/sample_warehouse_users/v1/',
    },
    history: {
      url: 'https://pegasushistory-dev.dev.target.com/sample_warehouse_histories/v1/',
    },
    barcode: {
      apiKey: DEV_API_KEY,
      url: 'https://pegasusbarcodeapiv1.dev.target.com/sample_warehouse_barcodes/v1/',
    },
    externalReports: {
      apiKey: STG_API_KEY,
      url: 'https://stage-api.target.com/external_studio_reports/v1/',
    },
    batches: {
      apiKey: DEV_API_KEY,
      url: 'https://pegasusbarcodeapiv1.dev.target.com/sample_warehouse_managements/v1/batches/',
    },
    combinedBarcode: {
      apiKey: DEV_API_KEY,
      url: 'https://pegasusbarcodeapiv1.dev.target.com/sample_warehouse_barcodes/v1/combined/barcodes',
    },
  },
  pol_dev: {
    apiKey: DEV_API_KEY,
    auth: {
      authorizationUrl:
        'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.iam.perf.partnersonline.com/login/responses/logoff.html',
      clientId: 'pegasusui_npe_im',
    },
    analytics: {
      apiKey:
        '< Create a non-prod (STAGE) API key by registering your app at http://go/developer/apps >',
    },
    roles: {
      setting: ['INVALID'],
      search: ['INVALID'],
      mySamples: ['INVALID'],
      checkIn: ['APP-PEG-MKT-VENDOR'],
      projects: ['APP-PEG-MKT-VENDOR'],
      vendor: ['APP-PEG-MERCH-VENDOR'],
      manageLocations: ['INVALID'],
      viewProps: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-IDS-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-PROPS-STG',
      ],
      addProps: ['APP-PEG-ADMIN-STG', 'APP-PEG-PROPS-STG'],
      admin: ['APP-PEG-ADMIN-STG'],
      requestedSamples: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-MS-STG',
      ],
      productProducers: ['APP-PEG-SM-STG', 'APP-PEG-ADMIN-STG'],
      smsPartners: ['APP-PEG-MS-STG', 'APP-PEG-ADMIN-STG'],
      helpvendor: [
        'APP-PEG-MERCH-VENDOR',
        'APP-PEG-MKT-VENDOR',
        'APP-PEG-VENDOR ',
      ],
      helpUsers: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-IDS-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
      ],
      scenic: ['APP-PEG-ADMIN-STG', 'APP-PEG-SCENIC-STG'],
      inventory: ['APP-PEG-MKT-VENDOR'],
      shipSamples: ['APP-PEG-MKT-VENDOR'],
      autoOrder: ['APP-PEG-ADMIN-STG', 'APP-PEG-IDS-STG'],
      autoOrderEdit: ['APP-PEG-ADMIN-STG', 'APP-PEG-SM-STG'],
      defaultViewProps: ['APP-PEG-PROPS-STG'],
      props: ['APP-PEG-ADMIN-STG', 'APP-PEG-PROPS-STG'],
      projectsDefault: ['APP-PEG-SM-STG'],
    },
    items: {
      apiKey: DEV_API_KEY,
      graphql_url:
        'https://pegasusitemapiv1.dev.target.com/sample_warehouse_items/v1/items/',
    },
    vendors: {
      url: 'https://stgapi-internal.target.com/sample_warehouse_vendors/v1/',
    },
    managements: {
      url: 'https://pegasusorderapiv1.dev.target.com/sample_warehouse_managements/v1/',
    },
    uploads: {
      url: 'https://pegasusimageservice.dev.target.com/sample_images/v1',
    },
    props: {
      apiKey: DEV_API_KEY,
      url: 'https://pegasusorderapiv1.dev.target.com/sample_warehouse_managements/v1/',
    },
    archives: {
      url: 'https://pegasusarchiveservice.dev.target.com/sample_warehouse_managements/v1/sample_warehouse_archives/v1/',
    },
    user: {
      url: 'https://pegasususerapiv1.dev.target.com/sample_warehouse_users/v1/',
    },
    history: {
      url: 'https://pegasushistory-dev.dev.target.com/sample_warehouse_histories/v1/',
    },
    barcode: {
      apiKey: DEV_API_KEY,
      url: 'https://pegasusbarcodeapiv1.dev.target.com/sample_warehouse_barcodes/v1/',
    },
    externalReports: {
      apiKey: STG_API_KEY,
      url: 'https://stage-api.target.com/external_studio_reports/v1/',
    },
    batches: {
      apiKey: DEV_API_KEY,
      url: 'https://pegasusbarcodeapiv1.dev.target.com/sample_warehouse_managements/v1/batches/',
    },
    combinedBarcode: {
      apiKey: DEV_API_KEY,
      url: 'https://pegasusbarcodeapiv1.dev.target.com/sample_warehouse_barcodes/v1/combined/barcodes',
    },
  },
  stg: {
    apiKey: STG_API_KEY,
    auth: {
      clientId: 'pegasusui_npe_im',
    },
    analytics: {
      apiKey:
        '< Create a non-prod (STAGE) API key by registering your app at http://go/developer/apps >',
    },
    roles: {
      viewProps: ['APP-PEG-ADMIN-STG', 'APP-PEG-SM-STG', 'APP-PEG-PROPS-STG'],
      addProps: ['APP-PEG-ADMIN-STG', 'APP-PEG-PROPS-STG'],
      setting: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-IDS-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-SCENIC-STG',
        'APP-PEG-PROPS-STG',
      ],
      search: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-IDS-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
      ],
      admin: ['APP-PEG-ADMIN-STG'],
      mySamples: ['APP-PEG-ADMIN-STG', 'APP-PEG-IDS-STG', 'APP-PEG-MS-STG'],
      checkIn: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-SCENIC-STG',
        'APP-PEG-PROPS-STG',
      ],
      projects: ['APP-PEG-ADMIN-STG', 'APP-PEG-MS-STG'],
      vendor: ['APP-PEG-VENDOR', 'APP-PEG-ADMIN-STG'],
      manageLocations: ['APP-PEG-ADMIN-STG', 'APP-PEG-SM-STG'],
      requestedSamples: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-MS-STG',
      ],
      productProducers: ['APP-PEG-SM-STG', 'APP-PEG-ADMIN-STG'],
      smsPartners: ['APP-PEG-MS-STG', 'APP-PEG-ADMIN-STG'],
      helpvendor: [
        'APP-PEG-MERCH-VENDOR',
        'APP-PEG-MKT-VENDOR',
        'APP-PEG-VENDOR ',
      ],
      helpUsers: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-IDS-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-SCENIC-STG',
        'APP-PEG-PROPS-STG',
      ],
      shipSamples: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-IDS-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-SCENIC-STG',
        'APP-PEG-PROPS-STG',
      ],
      scenic: ['APP-PEG-ADMIN-STG', 'APP-PEG-SCENIC-STG'],
      inventory: null,
      autoOrder: ['APP-PEG-ADMIN-STG', 'APP-PEG-IDS-STG'],
      autoOrderEdit: ['APP-PEG-ADMIN-STG', 'APP-PEG-SM-STG'],
      props: ['APP-PEG-ADMIN-STG', 'APP-PEG-PROPS-STG'],
      defaultViewProps: ['APP-PEG-PROPS-STG'],
      projectsDefault: ['APP-PEG-SM-STG'],
    },
    items: {
      apiKey: STG_API_KEY,
      graphql_url:
        'https://stgapi-internal.target.com/sample_warehouse_items/v1/items/',
    },
    vendors: {
      url: 'https://stgapi-internal.target.com/sample_warehouse_vendors/v1/',
    },
    managements: {
      url: 'https://stgapi-internal.target.com/sample_warehouse_managements/v1/',
    },
    uploads: {
      url: 'https://stgapi-internal.target.com/sample_warehouse_managements/v1/sample_images/v1',
    },
    props: {
      url: 'https://stgapi-internal.target.com/sample_warehouse_managements/v1/',
    },
    archives: {
      url: 'https://stgapi-internal.target.com/sample_warehouse_managements/v1/sample_warehouse_archives/v1/',
    },
    user: {
      url: 'https://stgapi-internal.target.com/sample_warehouse_users/v1/',
    },
    history: {
      url: 'https://stage-api.target.com/sample_warehouse_histories/v1/',
    },
    barcode: {
      apiKey: STG_API_KEY,
      url: 'https://stgapi-internal.target.com/sample_warehouse_barcodes/v1/',
    },
    batches: {
      apiKey: STG_API_KEY,
      url: 'https://stgapi-internal.target.com/sample_warehouse_managements/v1/batches/',
    },
    externalReports: {
      apiKey: STG_API_KEY,
      url: 'https://stage-api.target.com/external_studio_reports/v1/',
    },
    combinedBarcode: {
      apiKey: DEV_API_KEY,
      url: 'https://stgapi-internal.target.com/sample_warehouse_barcodes/v1/combined/barcodes',
    },
  },
  peg_ih_pol_stg: {
    apiKey: STG_API_KEY,
    auth: {
      clientId: 'pegasusui_npe_im',
      authorizationUrl:
        'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.iam.perf.partnersonline.com/login/responses/logoff.html',
      loginRedirect: `${window.location.origin}${itemCommonUtilis.findAppPath()}/`,
    },
    analytics: {
      apiKey:
        '< Create a non-prod (STAGE) API key by registering your app at http://go/developer/apps >',
    },
    roles: {
      viewProps: ['APP-PEG-ADMIN-STG', 'APP-PEG-SM-STG', 'APP-PEG-PROPS-STG'],
      addProps: ['APP-PEG-ADMIN-STG', 'APP-PEG-PROPS-STG'],
      setting: ['INVALID'],
      search: ['INVALID'],
      mySamples: ['INVALID'],
      checkIn: ['APP-PEG-MKT-VENDOR'],
      projects: ['APP-PEG-MKT-VENDOR'],
      vendor: ['APP-PEG-MERCH-VENDOR'],
      manageLocations: ['INVALID'],
      admin: ['APP-PEG-ADMIN-STG'],
      requestedSamples: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-MS-STG',
      ],
      scenic: ['APP-PEG-ADMIN-STG', 'APP-PEG-SCENIC-STG'],
      productProducers: ['APP-PEG-SM-STG', 'APP-PEG-ADMIN-STG'],
      smsPartners: ['APP-PEG-MS-STG', 'APP-PEG-ADMIN-STG'],
      helpvendor: [
        'APP-PEG-MERCH-VENDOR',
        'APP-PEG-MKT-VENDOR',
        'APP-PEG-VENDOR ',
      ],
      helpUsers: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-IDS-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
      ],
      inventory: ['APP-PEG-MKT-VENDOR'],
      shipSamples: ['APP-PEG-MKT-VENDOR'],
      autoOrder: ['APP-PEG-ADMIN-STG', 'APP-PEG-IDS-STG'],
      autoOrderEdit: ['APP-PEG-ADMIN-STG', 'APP-PEG-SM-STG'],
      props: ['APP-PEG-ADMIN-STG', 'APP-PEG-PROPS-STG'],
      defaultViewProps: ['APP-PEG-PROPS-STG'],
      projectsDefault: ['APP-PEG-SM-STG'],
    },
    items: {
      apiKey: STG_API_KEY,
      graphql_url:
        'https://stage-api.target.com/sample_warehouse_items/v1/items/',
    },
    vendors: {
      url: 'https://stage-api.target.com/sample_warehouse_vendors/v1/',
    },
    managements: {
      url: 'https://stage-api.target.com/sample_warehouse_managements/v1/',
    },
    uploads: {
      url: 'https://stage-api.target.com/sample_warehouse_managements/v1/sample_images/v1',
    },
    props: {
      url: 'https://stage-api.target.com/sample_warehouse_managements/v1/',
    },
    archives: {
      url: 'https://stgapi-api.target.com/sample_warehouse_managements/v1/sample_warehouse_archives/v1/',
    },
    user: {
      url: 'https://stage-api.target.com/sample_warehouse_users/v1/',
    },
    history: {
      url: 'https://stage-api.target.com/sample_warehouse_histories/v1/',
    },
    barcode: {
      apiKey: STG_API_KEY,
      url: 'https://stage-api.target.com/sample_warehouse_barcodes/v1/',
    },
    batches: {
      apiKey: STG_API_KEY,
      url: 'https://stage-api.target.com/sample_warehouse_managements/v1/batches/',
    },
    externalReports: {
      apiKey: STG_API_KEY,
      url: 'https://stage-api.target.com/external_studio_reports/v1/',
    },
    combinedBarcode: {
      apiKey: DEV_API_KEY,
      url: 'https://stage-api.target.com/sample_warehouse_barcodes/v1/combined/barcodes',
    },
  },
  pol_stg: {
    apiKey: STG_API_KEY,
    auth: {
      clientId: 'pegasusui_npe_im',
      authorizationUrl:
        'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.iam.perf.partnersonline.com/login/responses/logoff.html',
    },
    analytics: {
      apiKey:
        '< Create a non-prod (STAGE) API key by registering your app at http://go/developer/apps >',
    },
    roles: {
      viewProps: ['APP-PEG-ADMIN-STG', 'APP-PEG-SM-STG', 'APP-PEG-PROPS-STG'],
      addProps: ['APP-PEG-ADMIN-STG', 'APP-PEG-PROPS-STG'],
      setting: ['INVALID'],
      search: ['INVALID'],
      mySamples: ['INVALID'],
      checkIn: ['APP-PEG-MKT-VENDOR'],
      projects: ['APP-PEG-MKT-VENDOR'],
      vendor: ['APP-PEG-MERCH-VENDOR'],
      manageLocations: ['INVALID'],
      admin: ['APP-PEG-ADMIN-STG'],
      requestedSamples: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-MS-STG',
      ],
      scenic: ['APP-PEG-ADMIN-STG', 'APP-PEG-SCENIC-STG'],
      productProducers: ['APP-PEG-SM-STG', 'APP-PEG-ADMIN-STG'],
      smsPartners: ['APP-PEG-MS-STG', 'APP-PEG-ADMIN-STG'],
      helpvendor: [
        'APP-PEG-MERCH-VENDOR',
        'APP-PEG-MKT-VENDOR',
        'APP-PEG-VENDOR ',
      ],
      helpUsers: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-IDS-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
      ],
      inventory: ['APP-PEG-MKT-VENDOR'],
      shipSamples: ['APP-PEG-MKT-VENDOR'],
      autoOrder: ['APP-PEG-ADMIN-STG', 'APP-PEG-IDS-STG'],
      autoOrderEdit: ['APP-PEG-ADMIN-STG', 'APP-PEG-SM-STG'],
      props: ['APP-PEG-ADMIN-STG', 'APP-PEG-PROPS-STG'],
      defaultViewProps: ['APP-PEG-PROPS-STG'],
      projectsDefault: ['APP-PEG-SM-STG'],
    },
    items: {
      apiKey: STG_API_KEY,
      graphql_url:
        'https://stage-api.target.com/sample_warehouse_items/v1/items/',
    },
    vendors: {
      url: 'https://stage-api.target.com/sample_warehouse_vendors/v1/',
    },
    managements: {
      url: 'https://stage-api.target.com/sample_warehouse_managements/v1/',
      // url: 'http://localhost:8082/sample_warehouse_managements/v1/',
    },
    uploads: {
      url: 'https://stage-api.target.com/sample_warehouse_managements/v1/sample_images/v1',
    },
    props: {
      url: 'https://stage-api.target.com/sample_warehouse_managements/v1/',
    },
    archives: {
      url: 'https://stgapi-api.target.com/sample_warehouse_managements/v1/sample_warehouse_archives/v1/',
    },
    user: {
      url: 'https://stage-api.target.com/sample_warehouse_users/v1/',
    },
    history: {
      url: 'https://stage-api.target.com/sample_warehouse_histories/v1/',
    },
    barcode: {
      apiKey: STG_API_KEY,
      url: 'https://stage-api.target.com/sample_warehouse_barcodes/v1/',
    },
    batches: {
      apiKey: STG_API_KEY,
      url: 'https://stage-api.target.com/sample_warehouse_managements/v1/batches/',
    },
    externalReports: {
      apiKey: STG_API_KEY,
      url: 'https://stage-api.target.com/external_studio_reports/v1/',
    },
    combinedBarcode: {
      apiKey: DEV_API_KEY,
      url: 'https://stage-api.target.com/sample_warehouse_barcodes/v1/combined/barcodes',
    },
  },
  peg_ih_pol_prod: {
    apiKey: PRD_API_KEY,
    auth: {
      authorizationUrl:
        'https://oauth.iam.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.iam.partnersonline.com/login/responses/logoff.html',
      loginRedirect: `${window.location.origin}${itemCommonUtilis.findAppPath()}/`,
      clientId: 'pegasusui_prod_im',
    },
    analytics: {
      apiKey:
        '< Create a non-prod (STAGE) API key by registering your app at http://go/developer/apps >',
      url: analyticsConfig.api.internal.prod,
    },
    roles: {
      viewProps: ['APP-PEG-ADMIN-PRD', 'APP-PEG-SM-PRD', 'APP-PEG-PROPS-PRD'],
      addProps: ['APP-PEG-ADMIN-PRD', 'APP-PEG-PROPS-PRD'],
      setting: ['INVALID'],
      search: ['INVALID'],
      mySamples: ['INVALID'],
      checkIn: ['APP-PEG-MKT-VENDOR'],
      projects: ['APP-PEG-MKT-VENDOR'],
      vendor: ['APP-PEG-MERCH-VENDOR'],
      admin: ['APP-PEG-ADMIN-PRD'],
      manageLocations: ['INVALID'],
      requestedSamples: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-SM-PRD',
        'APP-PEG-MS-PRD',
      ],
      scenic: ['APP-PEG-ADMIN-PRD', 'APP-PEG-SCENIC-PRD'],
      productProducers: ['APP-PEG-SM-PRD', 'APP-PEG-ADMIN-PRD'],
      smsPartners: ['APP-PEG-MS-PRD', 'APP-PEG-ADMIN-PRD'],
      // requestedSamples: [],
      // productProducers: [],
      // smsPartners: [],
      helpvendor: [
        'APP-PEG-MERCH-VENDOR',
        'APP-PEG-MKT-VENDOR',
        'APP-PEG-VENDOR-PRD',
      ],
      helpUsers: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-IDS-PRD',
        'APP-PEG-MS-PRD',
        'APP-PEG-SM-PRD',
      ],
      inventory: ['APP-PEG-MKT-VENDOR'],
      shipSamples: ['APP-PEG-MKT-VENDOR'],
      autoOrder: ['APP-PEG-ADMIN-PRD', 'APP-PEG-SM-PRD'],
      props: ['APP-PEG-ADMIN-PRD', 'APP-PEG-PROPS-PRD'],
      defaultViewProps: ['APP-PEG-PROPS-PRD'],
      projectsDefault: ['APP-PEG-SM-PRD'],
    },
    items: {
      apiKey: PRD_API_KEY,
      graphql_url: 'https://api.target.com/sample_warehouse_items/v1/items/',
    },
    vendors: {
      url: 'https://api.target.com/sample_warehouse_vendors/v1/',
    },
    managements: {
      url: 'https://api.target.com/sample_warehouse_managements/v1/',
    },
    uploads: {
      url: 'https://api.target.com/sample_warehouse_managements/v1/sample_images/v1',
    },
    props: {
      url: 'https://api.target.com/sample_warehouse_managements/v1/',
    },
    archives: {
      url: 'https://api.target.com/sample_warehouse_managements/v1/sample_warehouse_archives/v1/',
    },
    user: {
      url: 'https://api.target.com/sample_warehouse_users/v1/',
    },
    history: {
      url: 'https://api.target.com/sample_warehouse_histories/v1/',
    },
    barcode: {
      apiKey: PRD_API_KEY,
      url: 'https://api.target.com/sample_warehouse_barcodes/v1/',
    },
    batches: {
      apiKey: PRD_API_KEY,
      url: 'https://api.target.com/sample_warehouse_managements/v1/batches/',
    },
    externalReports: {
      apiKey: PRD_API_KEY,
      url: 'https://api.target.com/external_studio_reports/v1/',
    },
    combinedBarcode: {
      apiKey: DEV_API_KEY,
      url: 'https://api.target.com/sample_warehouse_barcodes/v1/combined/barcodes',
    },
  },
  pol_prod: {
    apiKey: PRD_API_KEY,
    auth: {
      authorizationUrl:
        'https://oauth.iam.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.iam.partnersonline.com/login/responses/logoff.html',
      clientId: 'pegasusui_prod_im',
    },
    analytics: {
      apiKey:
        '< Create a non-prod (STAGE) API key by registering your app at http://go/developer/apps >',
      url: analyticsConfig.api.internal.prod,
    },
    roles: {
      viewProps: ['APP-PEG-ADMIN-PRD', 'APP-PEG-SM-PRD', 'APP-PEG-PROPS-PRD'],
      addProps: ['APP-PEG-ADMIN-PRD', 'APP-PEG-PROPS-PRD'],
      setting: ['INVALID'],
      search: ['INVALID'],
      mySamples: ['INVALID'],
      checkIn: ['APP-PEG-MKT-VENDOR'],
      projects: ['APP-PEG-MKT-VENDOR'],
      vendor: ['APP-PEG-MERCH-VENDOR'],
      admin: ['APP-PEG-ADMIN-PRD'],
      manageLocations: ['INVALID'],
      requestedSamples: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-SM-PRD',
        'APP-PEG-MS-PRD',
      ],
      scenic: ['APP-PEG-ADMIN-PRD', 'APP-PEG-SCENIC-PRD'],
      productProducers: ['APP-PEG-SM-PRD', 'APP-PEG-ADMIN-PRD'],
      smsPartners: ['APP-PEG-MS-PRD', 'APP-PEG-ADMIN-PRD'],
      // requestedSamples: [],
      // productProducers: [],
      // smsPartners: [],
      helpvendor: [
        'APP-PEG-MERCH-VENDOR',
        'APP-PEG-MKT-VENDOR',
        'APP-PEG-VENDOR-PRD',
      ],
      helpUsers: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-IDS-PRD',
        'APP-PEG-MS-PRD',
        'APP-PEG-SM-PRD',
      ],
      inventory: ['APP-PEG-MKT-VENDOR'],
      shipSamples: ['APP-PEG-MKT-VENDOR'],
      autoOrder: ['APP-PEG-ADMIN-PRD', 'APP-PEG-SM-PRD'],
      props: ['APP-PEG-ADMIN-PRD', 'APP-PEG-PROPS-PRD'],
      defaultViewProps: ['APP-PEG-PROPS-PRD'],
      projectsDefault: ['APP-PEG-SM-PRD'],
    },
    items: {
      apiKey: PRD_API_KEY,
      graphql_url: 'https://api.target.com/sample_warehouse_items/v1/items/',
    },
    vendors: {
      url: 'https://api.target.com/sample_warehouse_vendors/v1/',
    },
    managements: {
      url: 'https://api.target.com/sample_warehouse_managements/v1/',
    },
    uploads: {
      url: 'https://api.target.com/sample_warehouse_managements/v1/sample_images/v1',
    },
    props: {
      url: 'https://api.target.com/sample_warehouse_managements/v1/',
    },
    archives: {
      url: 'https://api.target.com/sample_warehouse_managements/v1/sample_warehouse_archives/v1/',
    },
    user: {
      url: 'https://api.target.com/sample_warehouse_users/v1/',
    },
    history: {
      url: 'https://api.target.com/sample_warehouse_histories/v1/',
    },
    barcode: {
      apiKey: PRD_API_KEY,
      url: 'https://api.target.com/sample_warehouse_barcodes/v1/',
    },
    batches: {
      apiKey: PRD_API_KEY,
      url: 'https://api.target.com/sample_warehouse_managements/v1/batches/',
    },
    externalReports: {
      apiKey: PRD_API_KEY,
      url: 'https://api.target.com/external_studio_reports/v1/',
    },
    combinedBarcode: {
      apiKey: DEV_API_KEY,
      url: 'https://api.target.com/sample_warehouse_barcodes/v1/combined/barcodes',
    },
  },
  prod: {
    apiKey: PRD_API_KEY,
    auth: {
      // keys in envConfigs will overwrite keys from commonConfig
      authorizationUrl: 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      logoutUrl:
        'https://logonservices.iam.target.com/login/responses/logoff.html',
      clientId: 'pegasusui_prod_im',
    },
    analytics: {
      apiKey:
        '< Copy the production API key from your app entry at http://go/developer/apps >',
      url: analyticsConfig.api.internal.prod,
    },
    roles: {
      viewProps: ['APP-PEG-ADMIN-PRD', 'APP-PEG-SM-PRD', 'APP-PEG-PROPS-PRD'],
      addProps: ['APP-PEG-ADMIN-PRD', 'APP-PEG-PROPS-PRD'],
      setting: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-IDS-PRD',
        'APP-PEG-MS-PRD',
        'APP-PEG-SM-PRD',
        'APP-PEG-SCENIC-PRD',
        'APP-PEG-PROPS-PRD',
      ],
      admin: ['APP-PEG-ADMIN-PRD'],
      scenic: ['APP-PEG-ADMIN-PRD', 'APP-PEG-SCENIC-PRD'],
      search: ['APP-PEG-ADMIN-PRD', 'APP-PEG-IDS-PRD', 'APP-PEG-MS-PRD'],
      mySamples: ['APP-PEG-ADMIN-PRD', 'APP-PEG-IDS-PRD', 'APP-PEG-MS-PRD'],
      checkIn: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-MS-PRD',
        'APP-PEG-SM-PRD',
        'APP-PEG-SCENIC-PRD',
        'APP-PEG-PROPS-PRD',
      ],
      projects: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-MS-PRD',
        'APP-PEG-SM-PRD',
        'APP-PEG-IDS-PRD',
        'APP-PEG-PROPS-PRD',
      ],
      vendor: ['APP-PEG-ADMIN-PRD', 'APP-PEG-VENDOR-PRD'],
      manageLocations: ['APP-PEG-ADMIN-PRD', 'APP-PEG-SM-PRD'],
      requestedSamples: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-SM-PRD',
        'APP-PEG-MS-PRD',
      ],
      productProducers: ['APP-PEG-SM-PRD', 'APP-PEG-ADMIN-PRD'],
      smsPartners: ['APP-PEG-MS-PRD', 'APP-PEG-ADMIN-PRD'],
      // requestedSamples: [],
      // productProducers: [],
      // smsPartners: [],
      helpvendor: [
        'APP-PEG-MERCH-VENDOR',
        'APP-PEG-MKT-VENDOR',
        'APP-PEG-VENDOR-PRD',
      ],
      helpUsers: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-IDS-PRD',
        'APP-PEG-MS-PRD',
        'APP-PEG-SM-PRD',
        'APP-PEG-SCENIC-PRD',
        'APP-PEG-PROPS-PRD',
      ],
      shipSamples: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-MS-PRD',
        'APP-PEG-SM-PRD',
        'APP-PEG-PROPS-PRD',
        'APP-PEG-SCENIC-PRD',
      ],
      inventory: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-IDS-PRD',
        'APP-PEG-MS-PRD',
        'APP-PEG-SM-PRD',
        'APP-PEG-SCENIC-PRD',
        'APP-PEG-PROPS-PRD',
      ],
      autoOrder: ['APP-PEG-ADMIN-PRD', 'APP-PEG-IDS-PRD'],
      autoOrderEdit: ['APP-PEG-ADMIN-PRD', 'APP-PEG-SM-PRD'],
      props: ['APP-PEG-ADMIN-PRD', 'APP-PEG-PROPS-PRD'],
      defaultViewProps: ['APP-PEG-PROPS-PRD'],
      projectsDefault: ['APP-PEG-SM-PRD'],
    },
    items: {
      apiKey: PRD_API_KEY,
      graphql_url:
        'https://api-internal.target.com/sample_warehouse_items/v1/items/',
    },
    vendors: {
      url: 'https://businesspartnerapi.prod.target.com/sample_warehouse_vendors/v1/',
    },
    managements: {
      url: 'https://api-internal.target.com/sample_warehouse_managements/v1/',
    },
    uploads: {
      url: 'https://api.target.com/sample_warehouse_managements/v1/sample_images/v1',
    },
    props: {
      url: 'https://api.target.com/sample_warehouse_managements/v1/',
    },
    archives: {
      url: 'https://api-internal.target.com/sample_warehouse_managements/v1/sample_warehouse_archives/v1/',
    },
    user: {
      url: 'https://api-internal.target.com/sample_warehouse_users/v1/',
    },
    history: {
      url: 'https://api.target.com/sample_warehouse_histories/v1/',
    },
    barcode: {
      apiKey: PRD_API_KEY,
      url: 'https://api-internal.target.com/sample_warehouse_barcodes/v1/',
    },
    batches: {
      apiKey: PRD_API_KEY,
      url: 'https://api-internal.target.com/sample_warehouse_managements/v1/batches/',
    },
    externalReports: {
      apiKey: PRD_API_KEY,
      url: 'https://api.target.com/external_studio_reports/v1/',
    },
    combinedBarcode: {
      apiKey: DEV_API_KEY,
      url: 'https://api.target.com/sample_warehouse_barcodes/v1/combined/barcodes',
    },
  },
  partnersonline_stg: {
    auth: {
      authorizationUrl:
        'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.iam.perf.partnersonline.com/login/responses/logoff.html',
      clientId: 'pegasusui_npe_im',
    },
  },
  partnersonline_prd: {
    auth: {
      authorizationUrl:
        'https://oauth.iam.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.iam.partnersonline.com/login/responses/logoff.html',
      clientId: 'pegasusui_prod_im',
    },
  },
  peg_ih_stg: {
    apiKey: STG_API_KEY,
    auth: {
      authorizationUrl:
        'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
      logoutUrl:
        'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
      clientId: 'pegasusui_npe_im',
      loginRedirect: `${window.location.origin}${itemCommonUtilis.findAppPath()}/`,
    },
    analytics: {
      apiKey:
        '< Create a non-prod (STAGE) API key by registering your app at http://go/developer/apps >',
    },
    roles: {
      viewProps: ['APP-PEG-ADMIN-STG', 'APP-PEG-SM-STG', 'APP-PEG-PROPS-STG'],
      addProps: ['APP-PEG-ADMIN-STG', 'APP-PEG-PROPS-STG'],
      setting: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-IDS-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-SCENIC-STG',
        'APP-PEG-PROPS-STG',
      ],
      search: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-IDS-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
      ],
      admin: ['APP-PEG-ADMIN-STG'],
      mySamples: ['APP-PEG-ADMIN-STG', 'APP-PEG-IDS-STG', 'APP-PEG-MS-STG'],
      checkIn: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-SCENIC-STG',
        'APP-PEG-PROPS-STG',
      ],
      projects: ['APP-PEG-ADMIN-STG', 'APP-PEG-MS-STG'],
      vendor: ['APP-PEG-VENDOR', 'APP-PEG-ADMIN-STG'],
      manageLocations: ['APP-PEG-ADMIN-STG', 'APP-PEG-SM-STG'],
      requestedSamples: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-MS-STG',
      ],
      productProducers: ['APP-PEG-SM-STG', 'APP-PEG-ADMIN-STG'],
      smsPartners: ['APP-PEG-MS-STG', 'APP-PEG-ADMIN-STG'],
      helpvendor: [
        'APP-PEG-MERCH-VENDOR',
        'APP-PEG-MKT-VENDOR',
        'APP-PEG-VENDOR ',
      ],
      helpUsers: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-IDS-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-SCENIC-STG',
        'APP-PEG-PROPS-STG',
      ],
      shipSamples: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-IDS-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-SCENIC-STG',
        'APP-PEG-PROPS-STG',
      ],
      scenic: ['APP-PEG-ADMIN-STG', 'APP-PEG-SCENIC-STG'],
      inventory: [
        'APP-PEG-ADMIN-STG',
        'APP-PEG-IDS-STG',
        'APP-PEG-MS-STG',
        'APP-PEG-SM-STG',
        'APP-PEG-SCENIC-STG',
        'APP-PEG-PROPS-STG',
      ],
      autoOrder: ['APP-PEG-ADMIN-STG', 'APP-PEG-IDS-STG'],
      autoOrderEdit: ['APP-PEG-ADMIN-STG', 'APP-PEG-SM-STG'],
      props: ['APP-PEG-ADMIN-STG', 'APP-PEG-PROPS-STG'],
      defaultViewProps: ['APP-PEG-PROPS-STG'],
      projectsDefault: ['APP-PEG-SM-STG'],
    },
    items: {
      apiKey: STG_API_KEY,
      graphql_url:
        'https://stgapi-internal.target.com/sample_warehouse_items/v1/items/',
    },
    vendors: {
      url: 'https://stgapi-internal.target.com/sample_warehouse_vendors/v1/',
    },
    managements: {
      url: 'https://stgapi-internal.target.com/sample_warehouse_managements/v1/',
    },
    uploads: {
      url: 'https://stgapi-internal.target.com/sample_warehouse_managements/v1/sample_images/v1',
    },
    props: {
      url: 'https://stgapi-internal.target.com/sample_warehouse_managements/v1/',
    },
    archives: {
      url: 'https://stgapi-internal.target.com/sample_warehouse_managements/v1/sample_warehouse_archives/v1/',
    },
    user: {
      url: 'https://stgapi-internal.target.com/sample_warehouse_users/v1/',
    },
    history: {
      url: 'https://stage-api.target.com/sample_warehouse_histories/v1/',
    },
    barcode: {
      apiKey: STG_API_KEY,
      url: 'https://stgapi-internal.target.com/sample_warehouse_barcodes/v1/',
    },
    batches: {
      apiKey: STG_API_KEY,
      url: 'https://stgapi-internal.target.com/sample_warehouse_managements/v1/batches/',
    },
    externalReports: {
      apiKey: STG_API_KEY,
      url: 'https://stage-api.target.com/external_studio_reports/v1/',
    },
    combinedBarcode: {
      apiKey: DEV_API_KEY,
      url: 'https://stgapi-internal.target.com/sample_warehouse_barcodes/v1/combined/barcodes',
    },
  },
  peg_ih_prod: {
    apiKey: PRD_API_KEY,
    auth: {
      // keys in envConfigs will overwrite keys from commonConfig
      authorizationUrl: 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      logoutUrl:
        'https://logonservices.iam.target.com/login/responses/logoff.html',
      clientId: 'pegasusui_prod_im',
      loginRedirect: `${window.location.origin}${itemCommonUtilis.findAppPath()}/`,
    },
    analytics: {
      apiKey:
        '< Copy the production API key from your app entry at http://go/developer/apps >',
      url: analyticsConfig.api.internal.prod,
    },
    roles: {
      viewProps: ['APP-PEG-ADMIN-PRD', 'APP-PEG-SM-PRD', 'APP-PEG-PROPS-PRD'],
      addProps: ['APP-PEG-ADMIN-PRD', 'APP-PEG-PROPS-PRD'],
      setting: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-IDS-PRD',
        'APP-PEG-MS-PRD',
        'APP-PEG-SM-PRD',
        'APP-PEG-SCENIC-PRD',
        'APP-PEG-PROPS-PRD',
      ],
      admin: ['APP-PEG-ADMIN-PRD'],
      scenic: ['APP-PEG-ADMIN-PRD', 'APP-PEG-SCENIC-PRD'],
      search: ['APP-PEG-ADMIN-PRD', 'APP-PEG-IDS-PRD', 'APP-PEG-MS-PRD'],
      mySamples: ['APP-PEG-ADMIN-PRD', 'APP-PEG-IDS-PRD', 'APP-PEG-MS-PRD'],
      checkIn: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-MS-PRD',
        'APP-PEG-SM-PRD',
        'APP-PEG-SCENIC-PRD',
        'APP-PEG-PROPS-PRD',
      ],
      projects: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-MS-PRD',
        'APP-PEG-SM-PRD',
        'APP-PEG-IDS-PRD',
        'APP-PEG-PROPS-PRD',
      ],
      vendor: ['APP-PEG-ADMIN-PRD', 'APP-PEG-VENDOR-PRD'],
      manageLocations: ['APP-PEG-ADMIN-PRD', 'APP-PEG-SM-PRD'],
      requestedSamples: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-SM-PRD',
        'APP-PEG-MS-PRD',
      ],
      productProducers: ['APP-PEG-SM-PRD', 'APP-PEG-ADMIN-PRD'],
      smsPartners: ['APP-PEG-MS-PRD', 'APP-PEG-ADMIN-PRD'],
      // requestedSamples: [],
      // productProducers: [],
      // smsPartners: [],
      helpvendor: [
        'APP-PEG-MERCH-VENDOR',
        'APP-PEG-MKT-VENDOR',
        'APP-PEG-VENDOR-PRD',
      ],
      helpUsers: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-IDS-PRD',
        'APP-PEG-MS-PRD',
        'APP-PEG-SM-PRD',
        'APP-PEG-SCENIC-PRD',
        'APP-PEG-PROPS-PRD',
      ],
      shipSamples: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-MS-PRD',
        'APP-PEG-SM-PRD',
        'APP-PEG-PROPS-PRD',
        'APP-PEG-SCENIC-PRD',
      ],
      inventory: [
        'APP-PEG-ADMIN-PRD',
        'APP-PEG-IDS-PRD',
        'APP-PEG-MS-PRD',
        'APP-PEG-SM-PRD',
        'APP-PEG-SCENIC-PRD',
        'APP-PEG-PROPS-PRD',
      ],
      autoOrder: ['APP-PEG-ADMIN-PRD', 'APP-PEG-IDS-PRD'],
      autoOrderEdit: ['APP-PEG-ADMIN-PRD', 'APP-PEG-SM-PRD'],
      props: ['APP-PEG-ADMIN-PRD', 'APP-PEG-PROPS-PRD'],
      defaultViewProps: ['APP-PEG-PROPS-PRD'],
      projectsDefault: ['APP-PEG-SM-PRD'],
    },
    items: {
      apiKey: PRD_API_KEY,
      graphql_url:
        'https://api-internal.target.com/sample_warehouse_items/v1/items/',
    },
    vendors: {
      url: 'https://businesspartnerapi.prod.target.com/sample_warehouse_vendors/v1/',
    },
    managements: {
      url: 'https://api-internal.target.com/sample_warehouse_managements/v1/',
    },
    uploads: {
      url: 'https://api.target.com/sample_warehouse_managements/v1/sample_images/v1',
    },
    props: {
      url: 'https://api.target.com/sample_warehouse_managements/v1/',
    },
    archives: {
      url: 'https://api-internal.target.com/sample_warehouse_managements/v1/sample_warehouse_archives/v1/',
    },
    user: {
      url: 'https://api-internal.target.com/sample_warehouse_users/v1/',
    },
    history: {
      url: 'https://api.target.com/sample_warehouse_histories/v1/',
    },
    barcode: {
      apiKey: PRD_API_KEY,
      url: 'https://api-internal.target.com/sample_warehouse_barcodes/v1/',
    },
    batches: {
      apiKey: PRD_API_KEY,
      url: 'https://api-internal.target.com/sample_warehouse_managements/v1/batches/',
    },
    externalReports: {
      apiKey: PRD_API_KEY,
      url: 'https://api.target.com/external_studio_reports/v1/',
    },
    combinedBarcode: {
      apiKey: DEV_API_KEY,
      url: 'https://api.target.com/sample_warehouse_barcodes/v1/combined/barcodes',
    },
  },
}

const appEnv =
  process.env.REACT_APP_ENV === 'dev' ? 'qa' : process.env.REACT_APP_ENV
const config = envConfigs[appEnv]

// commonConfig and the config for the matching REACT_APP_ENV are combined.
// Values in the environment-specific config will overwrite commonConfig keys
// if they share the same name.
// load partners online auth into config
const hostnameConfigMap = {
  'xycpeg.perf.partnersonline.com': 'partnersonline_stg',
  'peg.partnersonline.com': 'partnersonline_prd',
  'itemhub.partnersonline.com': 'peg_ih_pol_prod',
  'itemhub.perf.partnersonline.com': 'peg_ih_pol_stg',
  'itemhub.dev.target.com': 'peg_ih_stg',
  'itemhub.target.com': 'peg_ih_prod',
}

const envConfigKey = hostnameConfigMap[hostname]
if (envConfigKey) {
  merge(config, envConfigs[envConfigKey])
}
const apiConfig = merge(commonConfig, config)

export default apiConfig

// The following code block is purely for demonstration purposes and can be removed.
if (process.env.NODE_ENV === 'development') {
  console.log(`${appEnv} ENV apiConfig:`, apiConfig)
}
