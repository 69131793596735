import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@mui/material/Typography'

import { dataTabLabelsShippedWithProps } from '../../enums/tableTabs'

const useStyles = makeStyles((theme) => ({
  scrollList: {
    padding: '0px 8px 8px 8px',
    overflowY: 'auto',
    // maxHeight: '467px',
    '&::-webkit-scrollbar': {
      width: 9,
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#8d8d8d',
      border: '4px solid transparent',
      backgroundClip: 'content-box',
    },
    // /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#8d8d8d',
      border: '1px solid #8d8d8d',
    },
  },
  tabsRoot: {
    backgroundColor: '#EFEFEF',
  },
}))

function TabContainer({ children, dir, classes }) {
  return (
    <Typography component="div" dir={dir} className={classes.scrollList}>
      {children}
    </Typography>
  )
}
const PROPS_TAB_LABEL = 'PROPS'

export default function PropsRenderTabs({
  data,
  dataTableTabs = [PROPS_TAB_LABEL, ...dataTabLabelsShippedWithProps],
}) {
  const [value, setValue] = React.useState(0)
  const [currentData, setCurrentData] = React.useState(new Map())
  const classes = useStyles()

  React.useEffect(() => {
    return function cleanup() {
      setValue(0)
      setCurrentData(new Map())
    }
  }, [])

  React.useEffect(() => {
    setCurrentData(data)
  }, [data])

  const handleChange = (event, value) => {
    setValue(value)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        classes={{
          root: classes.tabsRoot,
        }}
      >
        {dataTableTabs.map((label) => (
          <Tab key={label} label={label} classes={{ root: classes.tabRoot }} />
        ))}
      </Tabs>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
        className={classes.SwipeableViews}
      >
        {dataTableTabs.map((label) => (
          <TabContainer key={label} dir="" classes={classes}>
            {currentData?.get(label)}
          </TabContainer>
        ))}
      </SwipeableViews>
    </>
  )
}
