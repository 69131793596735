import FlexibleTable from 'components/EnhancedTable/FlexibleTable'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchAllVendorShipmentsWithFilters } from 'store/shipmentSummary/actionCreator'
import AllShipmentsFilters from './AllShipmentsFilters'
import { Grid } from '@mui/material'
import { isEmpty } from 'lodash'

export default function AllShipments(props) {
  const {
    columnData = [],
    allVendorShipments = [],
    fetchAllShipmentsPending = false,
    filterOptions = {},
  } = props
  const dispatch = useDispatch()
  const [selectedFilters, setSelectedFilters] = useState({})

  useEffect(() => {
    dispatch(
      fetchAllVendorShipmentsWithFilters(
        {
          shipment_ids: [],
          pegasus_ids: [],
          tracking_numbers: [],
        },
        true
      )
    )
  }, [])

  useEffect(() => {
    dispatch(
      fetchAllVendorShipmentsWithFilters({
        shipment_ids: selectedFilters['shipmentId']
          ? selectedFilters['shipmentId'].map(
              (shipmentIds) => shipmentIds.value
            )
          : [],
        pegasus_ids: selectedFilters['pegasusId']
          ? [selectedFilters['pegasusId']]
          : [],
        tracking_numbers: selectedFilters['trackingNum']
          ? selectedFilters['trackingNum'].map(
              (trackingNum) => trackingNum.value
            )
          : [],
      })
    )
  }, [selectedFilters])
  return (
    <Grid>
      <AllShipmentsFilters
        filtersOptions={filterOptions}
        filterValues={selectedFilters}
        setSelectedFilters={(filters = {}) => setSelectedFilters(filters)}
      />
      <FlexibleTable
        cascadeFieldKeys={[]}
        columnData={columnData}
        data={allVendorShipments}
        enableSearch
        disableTablePagination
        isLoading={fetchAllShipmentsPending}
        enableMultiSearch={true}
      />
    </Grid>
  )
}
