import { startTransition, useState } from 'react'
import { isEmpty } from 'lodash'
import { Grid, TextField } from '@mui/material'
import CustomAutoComplete from 'pages/ShipSamplesPages/CustomAutoComplete'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import Clear from '@material-ui/icons/Clear'
const filterLabels = {
  shipmentId: 'Shipment Id',
  trackingNum: 'Tracking Number',
  pegasusId: 'Pegasus Ids',
}
export default function AllShipmentsFilters(props = {}) {
  const useStyles = makeStyles((theme) => ({
    clearAll: {
      '&:hover': {
        backgroundColor: 'unset !important',
      },
    },
    searchInputLabel: {
      color: '#666 !important',
      fontSize: '14px !important',
      fontWeight: 'initial',
    },
    clearAllIcon: {
      width: '18px',
      height: '18px',
      margin: '8px',
    },
    notchedOutlineNew: {
      borderColor: '#8d8d8d !important',
      borderWidth: '2px !important',
    },
  }))
  const {
    filtersOptions = {},
    filterValues = {},
    setSelectedFilters = () => {},
  } = props
  const [selectedPegasusIds, setSelectedPegasusIds] = useState('')

  const handleUpdate = (selectedValue = '', filterName = '') => {
    const updFilterValues = Object.assign({}, filterValues, {
      [filterName]: selectedValue,
    })

    const updFilterValuesObj = Object.entries(updFilterValues).reduce(
      (a, [k, v]) => (isEmpty(v) ? a : ((a[k] = v), a)),
      {}
    )
    startTransition(() => {
      setSelectedFilters(updFilterValuesObj)
    })
  }
  const classes = useStyles()
  const clearPegasusIds = () => {
    setSelectedPegasusIds('')
    handleUpdate('', 'pegasusId')
  }
  return (
    <Grid container spacing={2} justifyContent={'start'} alignItems={'center'}>
      <Grid
        item
        xs
        style={{
          flexWrap: 'nowrap',
          flex: 0.15,
          color: '#666',
          fontSize: '16px',
          fontWeight: 600,
          top: '2%',
        }}
      >
        <span>{'Filter By'}</span>
      </Grid>
      {Object.keys(filtersOptions)?.map((filterName = '') => {
        const optionsList = filtersOptions[filterName] ?? []
        let cmp = {}
        filterName !== 'pegasusId'
          ? (cmp = (
              <Grid item style={{ marginTop: '1%' }}>
                <CustomAutoComplete
                  handleUpdate={handleUpdate}
                  optionsList={optionsList}
                  selectedValue={filterValues[filterName] ?? []}
                  label={filterLabels[filterName] ?? ''}
                  id={filterLabels[filterName] ?? ''}
                  name={filterName}
                  disabled={!optionsList?.length}
                />
              </Grid>
            ))
          : (cmp = (
              <Grid item style={{ marginTop: '1%' }} direction={'row'}>
                <TextField
                  label={filterLabels[filterName] ?? ''}
                  multiline
                  placeholder={filterLabels[filterName] ?? ''}
                  value={selectedPegasusIds || ''}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutlineNew,
                    },
                  }}
                  InputLabelProps={{
                    className: classes.searchInputLabel,
                  }}
                  onChange={(event) =>
                    setSelectedPegasusIds(event.target.value)
                  }
                />
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: '10px', fontSize:'14px !important' }}
                  disabled={!selectedPegasusIds.length}
                  onClick={() => handleUpdate(selectedPegasusIds, filterName)}
                >
                  Apply Pegasus Id
                </Button>
                <Button
                  id="clearall"
                  color="primary"
                  size="small"
                  style={{ fontSize:'14px !important' }}
                  className={classes.clearAll}
                  disabled={!selectedPegasusIds.length}
                  onClick={() => clearPegasusIds()}
                >
                  <Clear className={classes.clearAllIcon} /> CLEAR ALL Pegasus
                  Ids
                </Button>
              </Grid>
            ))
        return cmp
      })}
    </Grid>
  )
}
